import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import Datenschutz from 'app/shared/oldPages/dataProtection/datenschutz';
import Impressum from 'app/shared/oldPages/dataProtection/imprint/impressum';
import ProgrammInfo from 'app/shared/oldPages/programminfo/programminfo';
import Debug from 'app/modules/generic/debug/debug';

const loading = <div>loading ...</div>;

const DunningLoader = Loadable({
  loader: () => import(/* webpackChunkName: "dunning" */ 'app/modules/dunning'),
  loading: () => loading,
});
const RedeliveryDunningLoader = Loadable({
  loader: () => import(/* webpackChunkName: "redeliveryDunning" */ 'app/modules/redeliveryDunning'),
  loading: () => loading,
});
const EnforcementLoader = Loadable({
  loader: () => import(/* webpackChunkName: "enforcement" */ 'app/modules/enforcement'),
  loading: () => loading,
});
const RedeliveryEnforcementLoader = Loadable({
  loader: () => import(/* webpackChunkName: "redeliveryEnforcement" */ 'app/modules/redeliveryEnforcement'),
  loading: () => loading,
});
const ObjectionLoader = Loadable({
  loader: () => import(/* webpackChunkName: "objection" */ 'app/modules/objection'),
  loading: () => loading,
});

const HelpLoader = Loadable({
  loader: () => import(/* webpackChunkName: "help" */ 'app/shared/oldPages/help/dunning/'),
  loading: () => loading,
});

const GenericHelpLoader = Loadable({
  loader: () => import(/* webpackChunkName: "redelivery dunning help" */ 'app/shared/oldPages/help/generic/'),
  loading: () => loading,
});
const AppRoutes = () => {
  return (
    <main className="view-routes">
      <ErrorBoundaryRoutes>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="mahnbescheid/*" element={<DunningLoader />} />
        <Route path="neuzustellung_mahnbescheid/*" element={<RedeliveryDunningLoader />} />
        <Route path="neuzustellung_vollstreckungsbescheid/*" element={<RedeliveryEnforcementLoader />} />
        <Route path="vollstreckungsbescheid/*" element={<EnforcementLoader />} />
        <Route path="widerspruch/*" element={<ObjectionLoader />} />
        <Route path="datenschutz" element={<Datenschutz />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="programminfo" element={<ProgrammInfo />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="hilfe/*" element={<HelpLoader />} />
        <Route path="hilfe/neuzustellung_mahnbescheid/*" element={<GenericHelpLoader />} />
        <Route path="hilfe/vollstreckungsbescheid/*" element={<GenericHelpLoader />} />
        <Route path="hilfe/neuzustellung_vollstreckungsbescheid/*" element={<GenericHelpLoader />} />
        <Route path="hilfe/widerspruch/*" element={<GenericHelpLoader />} />
        <Route path="debug" element={<Debug />} />
      </ErrorBoundaryRoutes>
    </main>
  );
};

export default AppRoutes;
