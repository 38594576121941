import '../generic-help.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from "app/shared/reducers/requeststore.interface";

const RechtsanwaltRechtsbeistand = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="generic-help rechtsanwalt-rechtsbeistand">
        <HelpNavigationMenu />
        <h2>Erfassen eines Prozessbevollmächtigten (Rechtsanwalt, Rechtsbeistand)</h2>
        {type !== RequestTypes.RedeliveryEnforcement && type !== RequestTypes.Objection && (
          <div>
            <p>
              Das Feld <span className="quotable">Auslagen</span> bietet Ihnen die Möglichkeit, einen Auslagenbetrag anzugeben, der anstelle
              der Auslagenpauschale gem. Nr. 7002 VV RVG beantragt wird.
            </p>
            <p>
              Falls der Antragsteller <b>nicht</b> zum Vorsteuerabzug berechtigt ist, markieren Sie bitte das zugehörige Kästchen.
            </p>
          </div>
        )}

        <p>
          In das Feld <span className="quotable">Kennziffer</span> geben Sie, falls vorhanden, die Ihnen vom Gericht zugewiesene
          Prozessvertreterkennziffer ein (8-stellig). Eine Kennziffer ist eine vom zuständigen Mahngericht auf Antrag vergebene Nummer,
          unter der die Daten des Prozessbevollmächtigten bei Gericht hinterlegt sind. Die Nutzung einer Kennziffer wird bei häufiger
          Antragstellung empfohlen. Das Online-Mahnverfahren kann aber auch ohne eine Kennziffer durchgeführt werden.<p></p>
          Die Kennziffer ist grundsätzlich bundesweit bei den Mahngerichten verwendbar.<div></div>
          Lediglich das Mahngericht des Landes Niedersachsen (Amtsgericht Uelzen) akzeptiert keine Kennziffern anderer Bundesländer.
        </p>

        <p>Sofern Ihnen keine Kennziffer zugewiesen wurde, geben Sie in den folgenden Feldern Ihre Daten ein:</p>

        <p>
          Im Feld <span className="quotable">Bezeichnung</span> wählen Sie Ihre Bezeichnung aus.
        </p>

        <p>
          Im Feld <span className="quotable">Vor- und Nachname</span> geben Sie Ihre(n) kompletten Vor- und Nachnamen ohne Abkürzungen ein.
          Bei einer Partnerschaft geben Sie hier den Namen der Partnerschaft entspr. Partnerschaftsgesellschaftsgesetz mit dem Namen
          mindestens eines Partners und dem Zusatz <span className="quotable">und Partner</span> oder{' '}
          <span className="quotable">Partnerschaft</span> an.
        </p>

        <p>
          Im Feld <span className="quotable">Straße, Hausnummer</span> geben Sie Ihre Adresse ein.
        </p>

        <p>
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Sitz nicht in Deutschland ist.
          Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>
        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default RechtsanwaltRechtsbeistand;
