import { applicantOrDefendantType, othersType } from 'app/config/constants';
import { tabType } from 'app/modules/dunning/attorney/tabPanelLayout';
import { translate } from 'react-jhipster';
import { DropdownConfigKeys, getDropdownConfig } from '../reducers/configuration';
import { setForeignDunningPossible } from '../reducers/requeststore';
import { StageNames } from '../reducers/requeststore.interface';
import {
  CombinedClaimType,
  IApplicationGeneralInformation,
  IAttorneyOfRecord,
  ICalculatedInterest,
  ICatalogClaim,
  ICatalogClaimDetailsOfAssignment,
  ICourtData,
  IExpencesAncillaryClaims,
  ILegalRepresentative,
  IOtherClaim,
  IPersonDetails,
  IPersonTypeMap,
  IRunningInterest,
} from '../reducers/requeststore.interface';
import {
  akadGradeStrings,
  alleVokale,
  basisZinsData,
  bisWert,
  C_OptiMahn,
  erhGebuehr,
  erhWert,
  gueltigeZeichen,
  kumGebuehr,
  lkzToStateMap,
  mindestEUR,
  mindestEUR2KostRMoG,
  mindestEURKostRAEG,
  optionsArray,
  stateToLKZ,
  stringsZN,
  tabEUR,
  tabEUR2KostRMoG,
  tabEURKostRAEG2021,
  ungueltigeStringsNachname,
  ungueltigeStringsNameGV,
  ungueltigeStringsNamePV,
  ungueltigeStringsVorname,
  ungueltigeZeichenNachname,
  ungueltigeZeichenNameGV,
  ungueltigeZeichenNamePV,
  ungueltigeZeichenVorname,
  vonWert,
} from '../util/statics';
import { ValidationUtils } from './validationUtils';

let asFaktor = 0.0;
let isKleinerPruefbetragMinus4 = false;
// eslint-disable-next-line complexity
export function validateDunningData(
  applicant: string,
  allPersons: IPersonTypeMap,
  attorneyOfRecord: IAttorneyOfRecord,
  currentStates: string[],
  allClaims: CombinedClaimType[],
  dunningProcedureType: string,
  expencesAncillaryClaims: IExpencesAncillaryClaims,
  calculatedInterest: ICalculatedInterest,
  allgemeinAngaben: IApplicationGeneralInformation,
  dunningCourtData: ICourtData,
  nurFehlerCheck: boolean,
  dispatch: React.Dispatch<any>,
  courtClaimDataMap: Map<string, ICourtData>,
) {
  const isAllPersonsEmpty = allPersons === undefined || Object.keys(allPersons).length === 0;
  const antragstellers = isAllPersonsEmpty ? [] : Object.values(allPersons[StageNames.DUNNING_APPLICANT]);
  const antragsgegners = isAllPersonsEmpty ? [] : Object.values(allPersons[StageNames.DUNNING_DEFENDANT]);
  const currentStateShort = currentStates[0];
  const isCatalogClaim = (claim: any): claim is ICatalogClaim => {
    return (claim as ICatalogClaim).id !== undefined && claim.type === 'ICatalogClaim';
  };

  const isOtherClaim = (claim: any): claim is IOtherClaim => {
    return (claim as IOtherClaim).id !== undefined && claim.type === 'IOtherClaim';
  };
  const catalogClaims = allClaims.filter(isCatalogClaim);
  const otherClaims = allClaims.filter(isOtherClaim);
  const abtretungSize = allClaims.filter(claim => claim.additionalInformation?.detailsOfAssignment !== undefined).length;

  const errors: string[] = [];
  checkBundesland(errors, currentStateShort, applicant, attorneyOfRecord, antragstellers);
  if (nurFehlerCheck && errors.length > 0) {
    return;
  }

  checkMindestAnforderung(errors, antragstellers, antragsgegners, applicant, catalogClaims, otherClaims, dunningCourtData);
  if (nurFehlerCheck && errors.length > 0) {
    return;
  }

  if (attorneyOfRecord !== undefined && attorneyOfRecord !== null) {
    checkProzessVertreter(errors, attorneyOfRecord, currentStateShort);
    if (nurFehlerCheck && errors.length > 0) {
      return;
    }
  }

  if (antragstellers !== undefined && antragstellers.length > 0) {
    for (const einAntragsteller of antragstellers) {
      if (nurFehlerCheck && errors.length > 0) {
        return;
      }
      checkAntragsteller(errors, einAntragsteller, attorneyOfRecord);
      checkMussGVHaben(errors, einAntragsteller);
      if (nurFehlerCheck && errors.length > 0) {
        return;
      }

      const alleGV = Object.values(einAntragsteller.legalRepresentatives);
      if (alleGV !== null && alleGV.length > 0 && (einAntragsteller.innerTab as othersType) !== othersType.pka) {
        for (const einGV of alleGV) {
          checkGesetzlicherVertreter(errors, einGV, einAntragsteller);
          if (nurFehlerCheck && errors.length > 0) {
            return;
          }
        }
      }
    }
  }

  if (antragsgegners !== undefined && antragsgegners.length > 0) {
    for (const einAntragsgegner of antragsgegners) {
      checkAntragsgegner(errors, einAntragsgegner);
      checkMussGVHaben(errors, einAntragsgegner);
      if (nurFehlerCheck && errors.length > 0) {
        return errors;
      }

      if (einAntragsgegner !== undefined && einAntragsgegner.defendantTrialCourtData !== undefined) {
        checkProzessGericht(errors, einAntragsgegner);
        if (nurFehlerCheck && errors.length > 0) {
          return errors;
        }
      }

      const alleGV = Object.values(einAntragsgegner.legalRepresentatives);
      if (alleGV !== undefined && alleGV.length > 0 && (einAntragsgegner.innerTab as othersType) !== othersType.pka) {
        for (const einGV of alleGV) {
          checkGesetzlicherVertreter(errors, einGV, einAntragsgegner);
          if (nurFehlerCheck && errors.length > 0) {
            return;
          }
        }
      }
    }
  }

  let iAnspruch = -1;
  let iKatAnspruch = 0;
  const alleKatAnsprueche = catalogClaims;
  if (alleKatAnsprueche !== undefined && alleKatAnsprueche.length > 0) {
    for (const einKatAnspruch of alleKatAnsprueche) {
      iAnspruch++;
      checkKatAnspruch(
        errors,
        einKatAnspruch,
        iAnspruch,
        antragstellers,
        catalogClaims,
        otherClaims,
        attorneyOfRecord,
        dunningProcedureType,
      );
      if (nurFehlerCheck && errors.length > 0) {
        return;
      }

      const aZusatz = einKatAnspruch.additionalDetails;
      if (aZusatz !== undefined && aZusatz !== null && !isObjectEmpty(aZusatz)) {
        checkAnspruchsZusatz(
          errors,
          aZusatz,
          einKatAnspruch,
          antragsgegners,
          antragstellers,
          dispatch,
          catalogClaims,
          otherClaims,
          courtClaimDataMap.get(einKatAnspruch.id),
        );
        if (nurFehlerCheck && errors.length > 0) {
          return;
        }
      }

      const aVKG = einKatAnspruch.terms;
      if (aVKG) {
        checkVerbrKreditGesetz(errors, aVKG, einKatAnspruch);
        if (nurFehlerCheck && errors.length > 0) {
          return;
        }
      }

      const alleZinsenKA = einKatAnspruch.runningInterest;
      if (alleZinsenKA !== undefined && Object.values(alleZinsenKA).length > 0) {
        let j = 0;
        for (const aZinsenKA of Object.values(alleZinsenKA)) {
          checkZinsen(
            errors,
            dunningProcedureType,
            aZinsenKA,
            calculatedInterest,
            einKatAnspruch,
            iKatAnspruch,
            j,
            catalogClaims,
            otherClaims,
            allClaims,
          );
          if (nurFehlerCheck && errors.length > 0) {
            return;
          }
          j++;
        }
      }

      const aAbtretung = einKatAnspruch.additionalInformation?.detailsOfAssignment;
      if (aAbtretung !== undefined && aAbtretung.formerCreditor !== '' && aAbtretung.formerCreditor !== undefined) {
        checkAbtretung(errors, aAbtretung, abtretungSize);
        if (nurFehlerCheck && errors.length > 0) {
          return;
        }
      }
      iKatAnspruch++;
    }
  }

  if (otherClaims !== undefined && otherClaims.length > 0) {
    let iSonstigerAnspruch = 0;
    for (const einSonstigerAnspruch of otherClaims) {
      iAnspruch++;
      checkSonstigerAnspruch(
        errors,
        einSonstigerAnspruch,
        otherClaims,
        catalogClaims,
        antragstellers,
        dunningProcedureType,
        iSonstigerAnspruch,
      );
      if (nurFehlerCheck && errors.length > 0) {
        return;
      }

      const aVKG = einSonstigerAnspruch.terms;
      if (aVKG) {
        checkVerbrKreditGesetz(errors, aVKG, einSonstigerAnspruch);
        if (nurFehlerCheck && errors.length > 0) {
          return;
        }
      }

      const alleZinsenSA = einSonstigerAnspruch.runningInterest;
      if (alleZinsenSA !== undefined && Object.values(alleZinsenSA).length > 0) {
        let j = 0;
        for (const aZinsenSA of Object.values(alleZinsenSA)) {
          checkZinsen(
            errors,
            dunningProcedureType,
            aZinsenSA,
            calculatedInterest,
            einSonstigerAnspruch,
            iAnspruch,
            j,
            catalogClaims,
            otherClaims,
            allClaims,
          );
          if (nurFehlerCheck && errors.length > 0) {
            return;
          }
          j++;
        }
      }

      const aAbtretung = einSonstigerAnspruch.additionalInformation?.detailsOfAssignment;
      if (aAbtretung !== undefined && aAbtretung.formerCreditor !== '' && aAbtretung.formerCreditor !== undefined) {
        checkAbtretung(errors, aAbtretung, abtretungSize);
        if (nurFehlerCheck && errors.length > 0) {
          return;
        }
      }
      iSonstigerAnspruch++;
    }
  }

  if (calculatedInterest !== undefined) {
    checkZinsen(errors, dunningProcedureType, null, calculatedInterest, null, -1, -1, catalogClaims, otherClaims, allClaims);
    if (nurFehlerCheck && errors.length > 0) {
      return;
    }
  }

  if (expencesAncillaryClaims !== undefined) {
    checkAuslagenNebenforderungen(errors, allClaims, expencesAncillaryClaims, attorneyOfRecord, antragstellers, catalogClaims, otherClaims);
  }

  checkAllgemeineAngaben(errors, allgemeinAngaben, allClaims);
  if (nurFehlerCheck && errors.length > 0) {
    return errors;
  }

  return errors;
}

/**
 * plausibilisiert einen Betrag und setzt die entsprechenden Messages. <br>
 * Es wird geprft:
 * <ul>
 * <li> WaehrungObject dirty (also ungltiges Whrungsformat)
 * <li> Betrag zu gro
 * <li> Betrag zu klein
 * </ul>
 */
export const checkBetragAuslagenNebenforderungen = (
  errors: string[],
  aCurrency: number,
  lowBetrag: number,
  highBetrag: number,
  lowMessage: string,
  highMessage: string,
) => {
  if (aCurrency > highBetrag) {
    errors.push(translate(`error.dunning.${highMessage}`));
  }
  if (aCurrency < lowBetrag) {
    errors.push(translate(`error.dunning.${lowMessage}`));
  }
};

/**
 * plausibilisiert einen Zinssatz und setzt die entsprechenden Messages. <br>
 * Es wird geprft:
 * <ul>
 * <li> ZinssatzObject dirty (also ungltiges Whrungsformat)
 * <li> Zinssatz zu gro
 * <li> Zinssatz zu klein
 * </ul>
 */
export const checkZinssatzAuslagenNebenForderungen = (
  errors: string[],
  aZinssatz: number,
  lowBetrag: number,
  highBetrag: number,
  lowMessage: string,
  highMessage: string,
  aText: string,
) => {
  if (aZinssatz > highBetrag) {
    errors.push(translate(highMessage, { aText }));
  }
  if (aZinssatz < lowBetrag) {
    errors.push(translate(lowMessage, { aText }));
  }
};

/**
 * Zinsangaben drfen nur gemacht werden, wenn das Betragsfeld gefllt wurde. <br>
 */
export const checkZinsangaben = (
  errors: string[],
  aBetrag: number,
  aZinssatz: number,
  isUeberBasis: boolean,
  aVon: string,
  aBis: string,
  aMessageZinsangaben: string,
  aMessageZinssatz: string,
  aMessageVon: string,
  aMessageBis: string,
  aMessageVonBis: string,
  aMessageVonZeitraum: string,
  aMessageBisZeitraum: string,
  aText: string,
) => {
  if (aBetrag === undefined) {
    if ((aZinssatz > 0 && aZinssatz !== undefined) || isUeberBasis || aVon !== undefined || aBis !== undefined) {
      errors.push(translate(aMessageZinsangaben, { aText }));
    }
  } else if ((aZinssatz === 0 || aZinssatz === undefined) && (isUeberBasis || aVon !== undefined || aBis !== undefined)) {
    errors.push(translate(aMessageZinssatz, { aText }));
  } else {
    const aVonDate = new Date(aVon);
    const aBisDate = new Date(aBis);
    if (aVon !== undefined && aBis !== undefined && aVonDate > aBisDate) {
      errors.push(translate(aMessageVonBis, { aText }));
    }

    if (aVon !== undefined && !ValidationUtils.isYearInRange(aVon, 1970, 2069)) {
      errors.push(translate(aMessageVonZeitraum, { aText }));
    }
    if (aBis !== undefined && !ValidationUtils.isYearInRange(aBis, 1970, 2069)) {
      errors.push(translate(aMessageBisZeitraum, { aText }));
    }
  }
};

/**
 * plausibilisiert die Betrge der AuslagenNebenforderungen. <br>
 * Prfung aller Betrge durch Aufruf der Methode checkBetrag().
 * Es wird geprft:
 * <ul>
 * <li> VordruckPorto (Message=AUSL1) (Message=AUSL2) (Message=AUSL3)
 * <li> SonstBetrag (Message=AUSL4) (Message=AUSL5) (Message=AUSL6)
 * <li> MahnKosten (Message=NEBF1) (Message=NEBF2) (Message=NEBF3)
 * <li> AuskunftsKosten (Message=NEBF4) (Message=NEBF5) (Message=NEBF6)
 * <li> BankRuecklastKosten (Message=NEBF7) (Message=NEBF8) (Message=NEBF9)
 * <li> InkassoKosten (Message=NEBF10) (Message=NEBF11) (Message=NEBF12)
 * <li> SonstigeKostenBetrag (Message=NEBF13) (Message=NEBF14) (Message=NEBF15)
 * </ul>
 */
export const checkBetraege = (errors: string[], theAuslagenNebenforderungen: IExpencesAncillaryClaims) => {
  checkBetragAuslagenNebenforderungen(
    errors,
    theAuslagenNebenforderungen.formPostage,
    0.0,
    999999.99,
    'amount-form-postage-too-low',
    'amount-form-postage-too-high',
  );
  checkBetragAuslagenNebenforderungen(
    errors,
    theAuslagenNebenforderungen.otherExpences,
    0.0,
    999999.99,
    'amount-other-expenses-too-low',
    'amount-other-expenses-too-low',
  );
  if (theAuslagenNebenforderungen.expences !== null && theAuslagenNebenforderungen.expences.dunningCost !== undefined) {
    checkBetragAuslagenNebenforderungen(
      errors,
      theAuslagenNebenforderungen.expences.dunningCost.value,
      0.0,
      99999.99,
      'amount-dunning-cost-too-low',
      'amount-dunning-cost-too-high',
    );

    checkZinssatzAuslagenNebenForderungen(
      errors,
      theAuslagenNebenforderungen.expences.dunningCost.plusInterest,
      0.0,
      99.999,
      'error.dunning.interest-rate-too-low',
      'error.dunning.interest-rate-too-high',
      'Mahnkosten',
    );

    checkZinsangaben(
      errors,
      theAuslagenNebenforderungen.expences.dunningCost.value,
      theAuslagenNebenforderungen.expences.dunningCost.plusInterest,
      theAuslagenNebenforderungen.expences.dunningCost.pointsAbovePrimeRate,
      theAuslagenNebenforderungen.expences.dunningCost.from,
      theAuslagenNebenforderungen.expences.dunningCost.to,
      'error.dunning.interest-amount-missing',
      'error.dunning.interest-rate-missing',
      'error.dunning.invalid-start-date',
      'error.dunning.invalid-end-date',
      'error.dunning.end-date-before-start',
      'error.dunning.invalid-start-year',
      'error.dunning.invalid-end-year',
      'Mahnkosten',
    );
  }

  if (theAuslagenNebenforderungen.expences !== null && theAuslagenNebenforderungen.expences.informationCost !== undefined) {
    checkBetragAuslagenNebenforderungen(
      errors,
      theAuslagenNebenforderungen.expences.informationCost.value,
      0.0,
      99999.99,
      'information-amount-too-low',
      'information-amount-too-high',
    );

    checkZinssatzAuslagenNebenForderungen(
      errors,
      theAuslagenNebenforderungen.expences.informationCost.plusInterest,
      0.0,
      99.999,
      'error.dunning.interest-rate-too-low',
      'error.dunning.interest-rate-too-high',
      'Ausknfte',
    );

    checkZinsangaben(
      errors,
      theAuslagenNebenforderungen.expences.informationCost.value,
      theAuslagenNebenforderungen.expences.informationCost.plusInterest,
      theAuslagenNebenforderungen.expences.informationCost.pointsAbovePrimeRate,
      theAuslagenNebenforderungen.expences.informationCost.from,
      theAuslagenNebenforderungen.expences.informationCost.to,
      'error.dunning.interest-amount-missing',
      'error.dunning.interest-rate-missing',
      'error.dunning.invalid-start-date',
      'error.dunning.invalid-end-date',
      'error.dunning.end-date-before-start',
      'error.dunning.invalid-start-year',
      'error.dunning.invalid-end-year',
      'Ausknfte',
    );
  }

  if (theAuslagenNebenforderungen.expences !== null && theAuslagenNebenforderungen.expences.bankCost !== undefined) {
    checkBetragAuslagenNebenforderungen(
      errors,
      theAuslagenNebenforderungen.expences.bankCost.value,
      0.0,
      99999.99,
      'bank-chargeback-amount-too-low',
      'bank-chargeback-amount-too-high',
    );

    checkZinssatzAuslagenNebenForderungen(
      errors,
      theAuslagenNebenforderungen.expences.bankCost.plusInterest,
      0.0,
      99.999,
      'error.dunning.interest-rate-too-low',
      'error.dunning.interest-rate-too-high',
      'Bankrcklastkosten',
    );

    checkZinsangaben(
      errors,
      theAuslagenNebenforderungen.expences.bankCost.value,
      theAuslagenNebenforderungen.expences.bankCost.plusInterest,
      theAuslagenNebenforderungen.expences.bankCost.pointsAbovePrimeRate,
      theAuslagenNebenforderungen.expences.bankCost.from,
      theAuslagenNebenforderungen.expences.bankCost.to,
      'error.dunning.interest-amount-missing',
      'error.dunning.interest-rate-missing',
      'error.dunning.invalid-start-date',
      'error.dunning.invalid-end-date',
      'error.dunning.end-date-before-start',
      'error.dunning.invalid-start-year',
      'error.dunning.invalid-end-year',
      'Bankrcklastkosten',
    );
  }
  if (theAuslagenNebenforderungen.expences !== null && theAuslagenNebenforderungen.expences.debtCollectionCost !== undefined) {
    checkBetragAuslagenNebenforderungen(
      errors,
      theAuslagenNebenforderungen.expences.debtCollectionCost.value,
      0.0,
      99999.99,
      'debt-collection-amount-too-low',
      'debt-collection-amount-too-high',
    );

    checkZinssatzAuslagenNebenForderungen(
      errors,
      theAuslagenNebenforderungen.expences.debtCollectionCost.plusInterest,
      0.0,
      99.999,
      'error.dunning.interest-rate-too-low',
      'error.dunning.interest-rate-too-high',
      'Inkassokosten',
    );

    checkZinsangaben(
      errors,
      theAuslagenNebenforderungen.expences.debtCollectionCost.value,
      theAuslagenNebenforderungen.expences.debtCollectionCost.plusInterest,
      theAuslagenNebenforderungen.expences.debtCollectionCost.pointsAbovePrimeRate,
      theAuslagenNebenforderungen.expences.debtCollectionCost.from,
      theAuslagenNebenforderungen.expences.debtCollectionCost.to,
      'error.dunning.interest-amount-missing',
      'error.dunning.interest-rate-missing',
      'error.dunning.invalid-start-date',
      'error.dunning.invalid-end-date',
      'error.dunning.end-date-before-start',
      'error.dunning.invalid-start-year',
      'error.dunning.invalid-end-year',
      'Inkassokosten',
    );
  }

  if (theAuslagenNebenforderungen.expences !== null && theAuslagenNebenforderungen.expences.lawyerCost !== undefined) {
    checkBetragAuslagenNebenforderungen(
      errors,
      theAuslagenNebenforderungen.expences.lawyerCost.value,
      0.0,
      99999.99,
      'pre-court-activity-amount-too-low',
      'pre-court-activity-amount-too-high',
    );

    checkZinssatzAuslagenNebenForderungen(
      errors,
      theAuslagenNebenforderungen.expences.lawyerCost.plusInterest,
      0.0,
      99.999,
      'error.dunning.interest-rate-too-low',
      'error.dunning.interest-rate-too-high',
      'Anwaltsvergtung fr vorgerichtl. Ttigkeit',
    );

    checkZinsangaben(
      errors,
      theAuslagenNebenforderungen.expences.lawyerCost.value,
      theAuslagenNebenforderungen.expences.lawyerCost.plusInterest,
      theAuslagenNebenforderungen.expences.lawyerCost.pointsAbovePrimeRate,
      theAuslagenNebenforderungen.expences.lawyerCost.from,
      theAuslagenNebenforderungen.expences.lawyerCost.to,
      'error.dunning.interest-amount-missing',
      'error.dunning.interest-rate-missing',
      'error.dunning.invalid-start-date',
      'error.dunning.invalid-end-date',
      'error.dunning.end-date-before-start',
      'error.dunning.invalid-start-year',
      'error.dunning.invalid-end-year',
      'Anwaltsvergtung fr vorgerichtl. Ttigkeit',
    );
  }

  checkBetragAuslagenNebenforderungen(
    errors,
    theAuslagenNebenforderungen.preTrialActivity,
    0.0,
    99999999.99,
    'value-in-dispute-pre-court-activity-amount-too-low',
    'value-in-dispute-pre-court-activity-amount-too-high',
  );

  checkBetragAuslagenNebenforderungen(
    errors,
    theAuslagenNebenforderungen.proceduralFee,
    0.0,
    99999999.99,
    'amount-procedural-fee-too-low',
    'amount-procedural-fee-too-high',
  );
};

/**
 * Die vorgerichtliche Anwaltsvergtung darf nur von Rechtsanwlten eingetragen werden (Warnung NEBF34).
 * Der Gegenstandswert zur vorgerichtliche Anwaltsvergtung darf nur von Rechtsanwlten eingetragen werden (Warnung NEBF35).
 * Der Gegenstandswert soll nicht kleiner der Summe der Hauptforderungen sein (Warnung NEBF36).
 * Angabe zum Umfang der vorgerichtl. ttigkeit ist nur mglich, wenn eine Anwaltsvergtung fr vorgerichtl. Ttigkeit angegeben wurde (NEBF40).
 * Anrechnungsbetrag auf die Verfahrensgebhr kann nur bei Prozessbevollmchtigung durch Rechtsanwlte angegeben werden (NEBF41).
 * Der auf die Verfahrensgebhr Nr. 3305 VV RVG anzurechnende Betrag (= anrechnungspflichtiger Teil der Geschftsgebhr nach Nr. 2300 / 2302 VV RVG - ohneAuslagen und MWSt.) erscheint unter Bercksichtigung der geltend gemachten Hauptforderung berhht (NEBF42).
 * Der auf die Verfahrensgebhr Nr. 3305 VV RVG anzurechnende Betrag erscheint unter Bercksichtigung der geltend gemachten Hauptforderung zu niedrig (NEBF43).
 */
export const checkVorgerAnwaltsverguetung = (
  errors: string[],
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  antragstellers: IPersonDetails[],
  claims: CombinedClaimType[],
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  if (attorneyOfRecord !== undefined && !isRechtsanwalt(attorneyOfRecord) && !isObjectEmpty(theAuslagenNebenforderungen)) {
    // Kein Prozessvertreter(PV) oder PV ist kein Anwalt
    if (theAuslagenNebenforderungen.expences === undefined || theAuslagenNebenforderungen.expences === null) {
      return;
    }
    if (theAuslagenNebenforderungen.expences.lawyerCost !== undefined && theAuslagenNebenforderungen.expences.lawyerCost.value > 0) {
      errors.push(translate('error.dunning.lawyer-fees-pre-court-activity-authorization-required'));
    }

    // lt Mail von Herrn Selbmann (21.04.2014): Fehler nur anzeigen, wenn kein
    // Rechtsanwalt und keine Inkassokosten.
    if (theAuslagenNebenforderungen.expences.debtCollectionCost !== undefined) {
      if (theAuslagenNebenforderungen.preTrialActivity > 0) {
        errors.push(translate('error.dunning.value-in-dispute-pre-court-activity-authorization-required'));
      }

      if (theAuslagenNebenforderungen.expences.lawyerCost.value < 0 && theAuslagenNebenforderungen.preJudicialActivity) {
        errors.push(translate('error.dunning.extent-difficulty-pre-court-activity-authorization-required'));
      }
    }

    if (theAuslagenNebenforderungen.proceduralFee > 0) {
      if (attorneyOfRecord !== undefined && (attorneyOfRecord.type as tabType) === tabType.collectionAgency) {
        // kein Fehlermeldung, Inkassounternehmen drfen Minderungsbetrag beantragen
      } else {
        errors.push(translate('error.dunning.credit-amount-procedural-fee-authorization-required'));
      }
    }
  }
  checkInputDisputeValuePreCourtActivityFees(errors, attorneyOfRecord, theAuslagenNebenforderungen);

  // Neu ab Ende Oktober 2008
  if (antragstellers.length > 0 && claims.length > 0) {
    checkVorgerAnwaltsverguetungHauptmodul(
      errors,
      antragstellers,
      theAuslagenNebenforderungen,
      attorneyOfRecord,
      catalogClaims,
      otherClaims,
    );
  }
};

export const checkInputDisputeValuePreCourtActivityFees = (
  errors: string[],
  attorneyOfRecord: IAttorneyOfRecord,
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
) => {
  if (theAuslagenNebenforderungen.expences === null || theAuslagenNebenforderungen.expences === undefined) {
    return;
  }
  if (!isObjectEmpty(attorneyOfRecord) && isRechtsanwalt(attorneyOfRecord) && !isObjectEmpty(theAuslagenNebenforderungen)) {
    if (theAuslagenNebenforderungen.expences.lawyerCost.value > 0) {
      if (theAuslagenNebenforderungen.preTrialActivity > 0 || theAuslagenNebenforderungen.proceduralFee > 0) {
        errors.push(translate('error.dunning.check-input-value-dispute-pre-court-activity-fees-required'));
      }
    }
  }
};

export const checkVorgerAnwaltsverguetungHauptmodul = (
  errors: string[],
  antragstellers: IPersonDetails[],
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  // Kein PV, dann wird keine Plausiprfung auf die Betrge gemacht.
  if (isObjectEmpty(attorneyOfRecord) || isObjectEmpty(theAuslagenNebenforderungen)) {
    return;
  }

  isKleinerPruefbetragMinus4 = false;

  if (theAuslagenNebenforderungen.proceduralFee === undefined || theAuslagenNebenforderungen.proceduralFee === 0) {
    // Minderungsbetrag nicht vorhanden - alte Regelung
    const pruefbetragP1 = getPruefbetragP1(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);

    if (theAuslagenNebenforderungen.proceduralFee <= pruefbetragP1) {
      // Kein Warnhinweis
    } else {
      // Warnhinweis W1 NEBFW1
      errors.push(translate('error.dunning.excessive-lawyer-fees-pre-court-activity-check-input'));
    }
  } else {
    // Minderungsbetrag vorhanden - Regelung nach BGH
    const pruefbetragP2 = getPruefbetragP2(theAuslagenNebenforderungen, antragstellers, attorneyOfRecord, catalogClaims, otherClaims);
    if (theAuslagenNebenforderungen.expences === undefined || theAuslagenNebenforderungen.expences === null) {
      return;
    }
    if (
      theAuslagenNebenforderungen.expences.lawyerCost !== undefined &&
      theAuslagenNebenforderungen.expences?.lawyerCost?.value <= pruefbetragP2
    ) {
      if (theAuslagenNebenforderungen.expences.lawyerCost.value < pruefbetragP2 - 4.0) {
        // setzen Kleiner-Merkmal
        isKleinerPruefbetragMinus4 = true;
      }

      // Modul P3 (Entweder kein Warnhinweis oder NEBFW3 oder NEBFW4)
      checkMinderungsbetragP3(errors, antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
    } else {
      // Warnhinweis W2 NEBFW2
      errors.push(translate('error.dunning.excessive-lawyer-fees-pre-court-activity-check-completeness'));
    }
  }
};

export const getMinderungsbetragM5 = (
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  antragstellers: IPersonDetails[],
) => {
  let aErgebnis =
    berechneGebuehr(getGebuehrDatum(attorneyOfRecord), getHauptforderungssummeAntrag(catalogClaims, otherClaims), 10, 10) *
    getFaktorASMIN(antragstellers);
  aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  return aErgebnis;
};

export const checkMinderungsbetragP3 = (
  errors: string[],
  antragstellers: IPersonDetails[],
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  if (
    aANF.preTrialActivity === undefined ||
    aANF.preTrialActivity === 0 ||
    aANF.preTrialActivity > getHauptforderungssummeAntrag(catalogClaims, otherClaims)
  ) {
    if (aANF.preJudicialActivity) {
      const minderungsbetragM1 = getMinderungsbetragM1(aANF, attorneyOfRecord, catalogClaims, otherClaims);
      if (aANF.proceduralFee > minderungsbetragM1 + 1.0) {
        // Warnhinweis W4 NEBFW4
        errors.push(translate('error.dunning.excessive-creditable-part-business-fee-pre-court-activity'));
      } else {
        if (aANF.proceduralFee < minderungsbetragM1) {
          // Warnhinweis W3 NEBFW3
          errors.push(translate('error.dunning.low-creditable-part-business-fee-pre-court-activity'));
        }
      }
    } else {
      if (isKleinerPruefbetragMinus4) {
        const minderungsbetragM1 = getMinderungsbetragM1(aANF, attorneyOfRecord, catalogClaims, otherClaims);
        const minderungsbetragM5 = getMinderungsbetragM5(aANF, attorneyOfRecord, catalogClaims, otherClaims, antragstellers);

        if (aANF.proceduralFee > minderungsbetragM1 + 1.0) {
          // Warnhinweis W4 NEBFW4
          errors.push(translate('error.dunning.excessive-creditable-part-business-fee-pre-court-activity'));
        } else {
          if (aANF.proceduralFee < minderungsbetragM5) {
            // Warnhinweis W3 NEBFW3
            errors.push(translate('error.dunning.low-creditable-part-business-fee-pre-court-activity'));
          }
        }
      } else {
        const minderungsbetragM1 = getMinderungsbetragM1(aANF, attorneyOfRecord, catalogClaims, otherClaims);
        const minderungsbetragM2 = getMinderungsbetragM2(aANF, attorneyOfRecord, catalogClaims, otherClaims);

        if (aANF.proceduralFee > minderungsbetragM1 + 1.0) {
          // Warnhinweis W4 NEBFW4
          errors.push(translate('error.dunning.excessive-creditable-part-business-fee-pre-court-activity'));
        } else {
          if (aANF.proceduralFee < minderungsbetragM2) {
            // Warnhinweis W3 NEBFW3
            errors.push(translate('error.dunning.low-creditable-part-business-fee-pre-court-activity'));
          }
        }
      }
    }
  } else {
    if (aANF.preJudicialActivity) {
      const minderungsbetragM3 = getMinderungsbetragM3(aANF, attorneyOfRecord);
      if (aANF.proceduralFee > minderungsbetragM3 + 1.0) {
        // Warnhinweis W4 NEBFW4
        errors.push(translate('error.dunning.excessive-creditable-part-business-fee-pre-court-activity'));
      } else {
        if (aANF.proceduralFee < minderungsbetragM3) {
          // Warnhinweis W3 NEBFW3
          errors.push(translate('error.dunning.low-creditable-part-business-fee-pre-court-activity'));
        }
      }
    } else {
      if (isKleinerPruefbetragMinus4) {
        const minderungsbetragM3 = getMinderungsbetragM3(aANF, attorneyOfRecord);
        const minderungsbetragM6 = getMinderungsbetragM6(aANF, attorneyOfRecord, antragstellers);

        if (aANF.proceduralFee > minderungsbetragM3 + 1.0) {
          // Warnhinweis W4 NEBFW4
          errors.push(translate('error.dunning.excessive-creditable-part-business-fee-pre-court-activity'));
        } else {
          if (aANF.proceduralFee < minderungsbetragM6) {
            // Warnhinweis W3 NEBFW3
            errors.push(translate('error.dunning.low-creditable-part-business-fee-pre-court-activity'));
          }
        }
      } else {
        const minderungsbetragM3 = getMinderungsbetragM3(aANF, attorneyOfRecord);
        const minderungsbetragM4 = getMinderungsbetragM4(aANF, attorneyOfRecord);

        if (aANF.proceduralFee > minderungsbetragM3 + 1.0) {
          // Warnhinweis W4 NEBFW4
          errors.push(translate('error.dunning.excessive-creditable-part-business-fee-pre-court-activity'));
        } else {
          if (aANF.proceduralFee < minderungsbetragM4) {
            // Warnhinweis W3 NEBFW3
            errors.push(translate('error.dunning.low-creditable-part-business-fee-pre-court-activity'));
          }
        }
      }
    }
  }
};

export const getMinderungsbetragM6 = (
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  antragstellers: IPersonDetails[],
) => {
  let aErgebnis = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), aANF.preTrialActivity, 10, 10 * getFaktorASMIN(antragstellers));
  aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  return aErgebnis;
};

export const getPruefbetragP2 = (
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
  antragstellers: IPersonDetails[],
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  let aErgebnis = 0.0;

  if (theAuslagenNebenforderungen.preTrialActivity === undefined || theAuslagenNebenforderungen.preTrialActivity === 0) {
    if (theAuslagenNebenforderungen.preJudicialActivity) {
      // modul G1
      aErgebnis = getGeschaeftsgebuehrG1(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
    } else {
      // modul G2
      aErgebnis = getGeschaeftsgebuehrG2(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
    }
  } else {
    if (theAuslagenNebenforderungen.preTrialActivity > getHauptforderungssummeAntrag(catalogClaims, otherClaims)) {
      if (theAuslagenNebenforderungen.preJudicialActivity) {
        // modul G3
        aErgebnis = getGeschaeftsgebuehrG3(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
      } else {
        // modul G4
        aErgebnis = getGeschaeftsgebuehrG4(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord);
      }
    } else {
      if (theAuslagenNebenforderungen.preJudicialActivity) {
        // modul G3
        aErgebnis = getGeschaeftsgebuehrG3(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
      } else {
        // modul G4
        aErgebnis = getGeschaeftsgebuehrG4(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord);
      }
    }
  }

  return aErgebnis;
};

export const getAUSL = (aGebuehr: number, aASFaktor: number) => {
  const auslagen = Math.round(aGebuehr * aASFaktor * 0.2 * 100) / 100;

  if (auslagen > 20.0) {
    return 20.0;
  }

  return auslagen;
};

//
// Module G1 bis G4
//

export const getGeschaeftsgebuehrG1 = (
  antragstellers: IPersonDetails[],
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  const aRAGebuehr = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), getHauptforderungssummeAntrag(catalogClaims, otherClaims), 10, 10);
  let aErgebnis = 0.0;
  asFaktor = getFaktorASUSJ(antragstellers); // Faktor merken

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = (aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor)) * (1.0 + mwstSatz) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  }

  return aErgebnis;
};

export const getFaktorASUSJ = (antragstellers: IPersonDetails[]) => {
  switch (antragstellers.length) {
    case 0:
      return 0.0;
    case 1:
      return 2.5;
    case 2:
      return 2.8;
    case 3:
      return 3.1;
    case 4:
      return 3.4;
    case 5:
      return 3.7;
    case 6:
      return 4.0;
    case 7:
      return 4.3;
    default:
      return 4.5;
  }
};

export const getAbzugsbetragA1 = (
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  let aErgebnis = 0.0;

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = getMinderungsbetragM1(aANF, attorneyOfRecord, catalogClaims, otherClaims) * (1.0 + mwstSatz);
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = getMinderungsbetragM1(aANF, attorneyOfRecord, catalogClaims, otherClaims);
  }

  return aErgebnis;
};

export const getMinderungsbetragM1 = (
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  const aErgebnis = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), getHauptforderungssummeAntrag(catalogClaims, otherClaims), 75, 100);
  return aErgebnis;
};

export const getGeschaeftsgebuehrG2 = (
  antragstellers: IPersonDetails[],
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  const aRAGebuehr = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), getHauptforderungssummeAntrag(catalogClaims, otherClaims), 10, 10);
  let aErgebnis = 0.0;
  asFaktor = getFaktorASUSN(antragstellers); // Faktor merken

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = (aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor)) * (1.0 + mwstSatz) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  }

  return aErgebnis;
};

export const getFaktorASUSN = (antragstellers: IPersonDetails[]) => {
  switch (antragstellers.length) {
    case 0:
      return 0.0;
    case 1:
      return 1.3;
    case 2:
      return 1.6;
    case 3:
      return 1.9;
    case 4:
      return 2.2;
    case 5:
      return 2.5;
    case 6:
      return 2.8;
    case 7:
      return 3.1;
    default:
      return 3.3;
  }
};

export const getFaktorASMIN = (antragstellers: IPersonDetails[]) => {
  switch (antragstellers.length) {
    case 0:
      return 0.0;
    case 1:
      return 0.15;
    case 2:
      return 0.3;
    case 3:
      return 0.45;
    case 4:
      return 0.6;
    default:
      return 0.75;
  }
};

export const getAbzugsbetragA2 = (
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  let aErgebnis = 0.0;

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = getMinderungsbetragM2(aANF, attorneyOfRecord, catalogClaims, otherClaims) * (1.0 + mwstSatz);
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = getMinderungsbetragM2(aANF, attorneyOfRecord, catalogClaims, otherClaims);
  }

  return aErgebnis;
};

export const getMinderungsbetragM2 = (
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  let aErgebnis = 0.0;

  //  asFaktor wurde vorher in G1 bis G4 gesetzt.
  if (asFaktor > 1.3) {
    aErgebnis = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), getHauptforderungssummeAntrag(catalogClaims, otherClaims), 75, 100);
  } else {
    aErgebnis = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), getHauptforderungssummeAntrag(catalogClaims, otherClaims), 65, 100);
  }

  return aErgebnis;
};

export const getGeschaeftsgebuehrG4 = (
  antragstellers: IPersonDetails[],
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
) => {
  const aRAGebuehr = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), aANF.preTrialActivity, 10, 10);
  let aErgebnis = 0.0;
  asFaktor = getFaktorASUSN(antragstellers); // Faktor merken

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = (aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor)) * (1.0 + mwstSatz) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  }

  return aErgebnis;
};

//
// Module P1 bis P3
//

export const getPruefbetragP1 = (
  antragstellers: IPersonDetails[],
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  let aErgebnis = 0.0;

  if (theAuslagenNebenforderungen.preTrialActivity === undefined || theAuslagenNebenforderungen.preTrialActivity === 0) {
    if (theAuslagenNebenforderungen.preJudicialActivity) {
      // modul G1 abzgl Modul A1
      aErgebnis =
        getGeschaeftsgebuehrG1(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims) -
        getAbzugsbetragA1(theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
      aErgebnis = Math.round(aErgebnis * 100) / 100; // Kaufmnnisch runden wg. double
    } else {
      // modul G2 abzgl Modul A2
      aErgebnis =
        getGeschaeftsgebuehrG2(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims) -
        getAbzugsbetragA2(theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
      aErgebnis = Math.round(aErgebnis * 100) / 100; // Kaufmnnisch runden wg. double
    }
  } else {
    if (theAuslagenNebenforderungen.preTrialActivity > getHauptforderungssummeAntrag(catalogClaims, otherClaims)) {
      if (theAuslagenNebenforderungen.preJudicialActivity) {
        // modul G3 abzgl Modul A1
        aErgebnis =
          getGeschaeftsgebuehrG3(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims) -
          getAbzugsbetragA1(theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
        aErgebnis = Math.round(aErgebnis * 100) / 100; // Kaufmnnisch runden wg. double
      } else {
        // modul G4 abzgl Modul A2
        aErgebnis =
          getGeschaeftsgebuehrG4(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord) -
          getAbzugsbetragA2(theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims);
        aErgebnis = Math.round(aErgebnis * 100) / 100; // Kaufmnnisch runden wg. double
      }
    } else {
      if (theAuslagenNebenforderungen.preJudicialActivity) {
        // modul G3 abzgl Modul A3
        aErgebnis =
          getGeschaeftsgebuehrG3(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord, catalogClaims, otherClaims) -
          getAbzugsbetragA3(theAuslagenNebenforderungen, attorneyOfRecord);
        aErgebnis = Math.round(aErgebnis * 100) / 100; // Kaufmnnisch runden wg. double
      } else {
        // modul G4 abzgl Modul A4
        aErgebnis =
          getGeschaeftsgebuehrG4(antragstellers, theAuslagenNebenforderungen, attorneyOfRecord) -
          getAbzugsbetragA4(theAuslagenNebenforderungen, attorneyOfRecord);
        aErgebnis = Math.round(aErgebnis * 100) / 100; // Kaufmnnisch runden wg. double
      }
    }
  }

  return aErgebnis;
};

export const getAbzugsbetragA4 = (aANF: IExpencesAncillaryClaims, attorneyOfRecord: IAttorneyOfRecord) => {
  let aErgebnis = 0.0;

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = getMinderungsbetragM4(aANF, attorneyOfRecord) * (1.0 + mwstSatz);
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = getMinderungsbetragM4(aANF, attorneyOfRecord);
  }

  return aErgebnis;
};

export const getMinderungsbetragM4 = (aANF: IExpencesAncillaryClaims, attorneyOfRecord: IAttorneyOfRecord) => {
  let aErgebnis = 0.0;

  //  asFaktor wurde vorher in G1 bis G4 gesetzt.
  if (asFaktor > 1.3) {
    aErgebnis = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), aANF.preTrialActivity, 75, 100);
  } else {
    aErgebnis = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), aANF.preTrialActivity, 65, 100);
  }

  return aErgebnis;
};

export const getMinderungsbetragM3 = (aANF: IExpencesAncillaryClaims, attorneyOfRecord: IAttorneyOfRecord) => {
  const aRAGebuehr = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), aANF.preTrialActivity, 75, 100);
  return aRAGebuehr;
};

export const getAbzugsbetragA3 = (aANF: IExpencesAncillaryClaims, attorneyOfRecord: IAttorneyOfRecord) => {
  let aErgebnis = 0.0;

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = getMinderungsbetragM3(aANF, attorneyOfRecord) * (1.0 + mwstSatz);
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = getMinderungsbetragM3(aANF, attorneyOfRecord);
  }

  return aErgebnis;
};

export const getGeschaeftsgebuehrG3 = (
  antragstellers: IPersonDetails[],
  aANF: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  const aRAGebuehr = berechneGebuehr(getGebuehrDatum(attorneyOfRecord), aANF.preTrialActivity, 10, 10);
  let aErgebnis = 0.0;
  asFaktor = getFaktorASUSJ(antragstellers); // Faktor merken

  if (!isObjectEmpty(attorneyOfRecord) && !attorneyOfRecord.isApplicantEntitledToDeductInputText) {
    const mwstSatz = 0.19;
    aErgebnis = (aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor)) * (1.0 + mwstSatz) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  } else {
    aErgebnis = aRAGebuehr * asFaktor + getAUSL(aRAGebuehr, asFaktor) + 2.0;
    aErgebnis = Math.floor(aErgebnis * 100) / 100; // Nachkommastellen abschneiden
  }

  return aErgebnis;
};

/**
 * Plausibilisiert die Auslagen und Nebenforderungen. <br>
 * Aufruf von checkBetraege() und checkBegruendung().
 */
export const checkAuslagenNebenforderungen = (
  errors: string[],
  claims: CombinedClaimType[],
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
  attorneyOfRecord: IAttorneyOfRecord,
  antragstellers: IPersonDetails[],
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  checkBetraege(errors, theAuslagenNebenforderungen);

  // Nur wenn bisher keine Fehler aufgetreten sind
  if (errors.length < 1) {
    checkVorgerAnwaltsverguetung(errors, theAuslagenNebenforderungen, attorneyOfRecord, antragstellers, claims, catalogClaims, otherClaims);
  }
  if (errors.length < 1) {
    const dateNow = new Date();
    const date = new Date('29.07.2014');
    if (dateNow < date) {
      checkVerzugspauschaleParagraph288Abs5(claims, errors, theAuslagenNebenforderungen);
    }
  }

  checkBegruendungAN(errors, theAuslagenNebenforderungen, catalogClaims);
};

const isLetter = (char: string): boolean => {
  return /^[a-zA-Z]$/.test(char);
};

const checkIfLetter = (aString: string, index: number): boolean => {
  if (index < 0 || index >= aString.length) {
    return false; // handle out-of-bounds index
  }
  return isLetter(aString.charAt(index));
};

/**
 * plausibilisiert die Begrndung fr sonstige AuslagenNebenforderungen. <br>
 * Es wird geprft:
 * <ul>
 * <li> ist Sonstige Auslagen Betrag nicht leer aber Begrndung leer (Message=AUSL8)
 * <li> ist Sonstige Auslagen Betrag leer aber Begrndung nicht leer (Message=AUSL7)
 * <li> SonstigeBezeichnug besteht nur aus Zahlen (Message=NEBF23)
 * <li> ist Sonstige Nebenforderungen Betrag nicht leer aber Begrndung leer (Message=AUSL17)
 * <li> ist Sonstige Nebenforderungen Betrag leer aber Begrndung nicht leer (Message=AUSL16)
 * <li> SonstigeBezeichnug besteht nur aus Zahlen (Message=NEBF24)
 * <li> Wenn gedruckt werden soll, Begrndungsfelder wenn ntig krzen, Lngen aus Tabelle Feld. Warnung ausgeben (Mesage=AUSL10) (Message=NEBF22)
 * <li> es drfen keine geschtzten Begriffe in den Sonstige Auslagene Bezeichnungen stehen, z.B. Porto (Message=AUSL9)
 * <li> es drfen das keine geschtzten Begriffe in den Sonstige Nebenforderungen Bezeichnungen stehen, z.B. Mahnkosten (Message=NEBF18)
 * <li> es drfen das keine geschtzten Begriffe in den Sonstige Nebenforderungen Bezeichnungen stehen, z.B. Auskunftskosten (Message=NEBF19)
 * <li> es drfen das keine geschtzten Begriffe in den Sonstige Nebenforderungen Bezeichnungen stehen, z.B. Bankrcklastkosten (Message=NEBF20)
 * <li> es drfen das keine geschtzten Begriffe in den Sonstige Nebenforderungen Bezeichnungen stehen, z.B. Inkassokosten (Message=NEBF21)
 * </ul>
 */
export const checkBegruendungAN = (errors: string[], theAuslagenNebenforderungen: IExpencesAncillaryClaims, catalogClaims: ICatalogClaim[]) => {
  // sonstige Bezeichnung darf nicht nur aus Zahlen bestehen
  const whenOthers = theAuslagenNebenforderungen.whenOthers;
  if (theAuslagenNebenforderungen.otherExpencesOption === 'andere' && whenOthers === '') {
    errors.push(translate('error.dunning.fill-option'));
    return;
  }
  if (whenOthers !== undefined && whenOthers.length > 0) {
    let i;
    for (i = 0; i < whenOthers.length; i++) {
      if (checkIfLetter(whenOthers, i)) {
        break;
      }
    }
    if (i === whenOthers.length || !checkIfLetter(whenOthers, i)) {
      errors.push(translate('error.dunning.other-expenses-description-no-letters'));
    }
  }

  // Plausis auf Sonstige Eingabefelder
  const sonst = whenOthers.toUpperCase();
  const mahnNichtLeer = theAuslagenNebenforderungen.expences?.dunningCost !== undefined;

  // Warnung wenn Mahnkosten und EDV-Mahnkosten (OMO) gleichzeitig angegeben werden
  // Kann zu Monierung fhren
  if (mahnNichtLeer && sonst === 'EDV-MAHNKOSTEN') {
    errors.push(translate('warning.double-declaration-lead-to-complaint'));
  }

  // Plausi: Bei Katalognummer 71 drfen keine Gebhren nach 2400 VV RVG
  // oder 2402 VV RVG geltend gemacht werden.
  if (hasKatNr(catalogClaims, '71')) {
    if (sonst.includes('2400') && sonst.includes('RVG')) {
      errors.push(translate('error.dunning.can-not-claim-fees-for-cat-71-according-to-rules', { rvgNo: '2400' }));
    }
    if (sonst.includes('2402') && sonst.includes('RVG')) {
      errors.push(translate('error.dunning.can-not-claim-fees-for-cat-71-according-to-rules', { rvgNo: '2402' }));
    }
    if (sonst.includes('2300') && sonst.includes('RVG')) {
      errors.push(translate('error.dunning.can-not-claim-fees-for-cat-71-according-to-rules', { rvgNo: '2300' }));
    }
    if (sonst.includes('2302') && sonst.includes('RVG')) {
      errors.push(translate('error.dunning.can-not-claim-fees-for-cat-71-according-to-rules', { rvgNo: '2302' }));
    }
  }
};

export const checkVerzugspauschaleParagraph288Abs5 = (
  claims: CombinedClaimType[],
  errors: string[],
  theAuslagenNebenforderungen: IExpencesAncillaryClaims,
) => {
  if (
    theAuslagenNebenforderungen.otherExpencesOption === 'Pauschale  288 Abs. 5 BGB' ||
    theAuslagenNebenforderungen.otherExpencesOption === 'Verzugspauschale  288 Abs. 5 BGB'
  ) {
    if (hasAnspruchMitAngabenZumVerbrKrG(claims)) {
      // Verbraucher ist beteiligt.
      errors.push(translate('error.dunning.consumer-involved-no-fee-according-to-288-BGB"'));
      return;
    }

    if (theAuslagenNebenforderungen.otherExpences > 40) {
      errors.push(translate('error.dunning.flat-rate-288-5-BGB-exceeds-40-EUR'));
      return;
    }
  }
};

/**
 * prft, ob ein Antrag mindestens einen Anspruch mit Angaben zum
 * VerbrKrG hat.
 */
export const hasAnspruchMitAngabenZumVerbrKrG = (claims: CombinedClaimType[]) => {
  if (claims.length === 0) {
    return false;
  }

  for (const claim of claims) {
    if (
      claim.additionalInformation !== undefined &&
      claim.additionalInformation.contractDate !== undefined &&
      claim.additionalInformation.contractDate !== '' &&
      claim.additionalInformation.effectiveAnnualInterestRate !== undefined &&
      claim.additionalInformation.effectiveAnnualInterestRate !== 0
    ) {
      return true;
    }
  }

  return false;
};

/**
 * plausibilisiert den SonstigerAnspruch. <br>
 * Generell Von-Datum und Begrndung auf isEmpty() und Betrag prfen
 * Wenn UMV, dann Nur ein Anspruch und Von-Datum auf dirty prfen.
 * Sonst Von und Bis-Datum auf Empty und Dirty prfen.
 */
export const checkSonstigerAnspruch = (
  errors: string[],
  otherClaim: IOtherClaim,
  otherClaims: IOtherClaim[],
  catalogClaims: ICatalogClaim[],
  antragstellers: IPersonDetails[],
  dunningPorcedureType: string,
  index: number,
) => {
  let size = errors.length;
  // Zeilenumbrche durch Space ersetzen
  const einSonstigerAnspruch = checkZeilenumbrueche(otherClaim);
  // Nicht mehr als 2 Sonst. Ansprche!
  checkAnzahl(errors, otherClaims, catalogClaims, index);
  if (size < errors.length) return;
  size = errors.length;
  checkBegruendung(errors, einSonstigerAnspruch);
  if (size < errors.length) return;
  checkVon(errors, einSonstigerAnspruch);
  if (isUMV(dunningPorcedureType)) {
    checkUMV(errors, einSonstigerAnspruch, otherClaims, index);
    if (errors.length > 0) return;
  }

  // Im WEG-Verfahren knnen nur Ansprche mit Ansprche mit KatNr. 90 eingetragen werden
  // checkWEG(errors, catalogClaim, antragstellers); // TODO: For IOtherClaim there is no catNr, so it can not be checked, see the logic and think do we need this.
  if (errors.length > 0) return;
  checkAnspruch(errors, einSonstigerAnspruch);
};

export const checkZeilenumbrueche = (einSonstigerAnspruch: IOtherClaim) => {
  let begruendung = einSonstigerAnspruch.claimInformation;
  begruendung = begruendung.replace(/\r\n/g, ' ');
  return {
    ...einSonstigerAnspruch,
    claimInformation: begruendung,
  };
};

export const isUMV = (dunningTypeProcedure: string) => {
  if (dunningTypeProcedure === 'urkunden-mahnverfahren') {
    return true;
  }
  return false;
};
/**
 * plausibilisiert die Anzahl der Ansprche (ab 01.12.2008 keine Prfung mehr). <br>
 * Es wird geprft:
 * <ul>
 * <li> es werden mehr als 2 SonstigeAnsprche angegelgt (Message=ANSPR29)
 * </ul>
 */
export const checkAnzahl = (errors: string[], otherClaims: IOtherClaim[], catalogClaims: ICatalogClaim[], index: number) => {
  const size = otherClaims.length;
  const sonstIndex = index - catalogClaims.length;
  // Schon 2 Ansprche da, neuer kann nicht mehr rein
  if ((index === -1 && size >= 2) || sonstIndex > 1) {
    errors.push(translate('error.dunning.only-2-other-claims-allowed'));
    return;
  }
};

/**
 * plausibilisiert UrkundenMahnVerfahren. <br>
 * Es wird geprft:
 * <ul>
 * <li> es wird mehr als 1 Anspruch im UrkundMahnVerfahren angelegt (Message=ANSPR15)
 * </ul>
 */
export const checkUMV = (errors: string[], einAnspruch: IOtherClaim, otherClaims: IOtherClaim[], index: number) => {
  const size = otherClaims.length;
  if (index > 0 || (index === -1 && size > 0)) {
    errors.push(translate('error.dunning.only-one-claim-allowed-in-documentary-dunning-procedure'));
    return;
  }
  checkVonFormat(errors, einAnspruch);
};

export const checkBegruendung = (errors: string[], einSonstigerAnspruch: IOtherClaim) => {
  if (isObjectEmpty(einSonstigerAnspruch)) {
    return;
  }
  const begruendung = einSonstigerAnspruch.claimInformation.trim();
  // Ist Begrndung vorhanden ?
  if (begruendung.length < 1) {
    errors.push(translate('error.dunning.enter-claim-text'));
    return;
  }
  const index = begruendung.indexOf(' ');
  const erstesWort = (index > 0 ? begruendung.substring(0, index) : begruendung).toUpperCase();
  if (erstesWort === 'URKUNDENMAHNVERFAHREN') {
    errors.push(translate('error.dunning.change-procedure-type-documentary-dunning'));
    return;
  }
  if (erstesWort === 'SCHECKMAHNVERFAHREN') {
    errors.push(translate('error.dunning.change-procedure-type-check-dunning'));
    return;
  }
  if (erstesWort === 'WECHSELMAHNVERFAHREN') {
    errors.push(translate('error.dunning.change-procedure-type-bill-of-exchange-dunning'));
    return;
  }

  validateVokale(errors, begruendung, 'Bezeichnung', 'error.dunning.enter-valid-claim-text');
};

/**
 * plausibilisiert ScheckWechselMahnverfahren. <br>
 * Es wird geprft:
 * <ul>
 * <li> wird versucht ein KatAnspruch mit anderer Katalogart als Scheck/Wechsel zu beantragen (Message=ANSPR16).
 * </ul>
 */
export const checkSWMV = (errors: string[], einKatAnspruch: ICatalogClaim, myVerfahrensArt: string) => {
  // TODO: Uncomment the line below when katalog groups are available in our application.
  // const sw = einKatAnspruch.getKatalogArt().isSW();
  // if (isSWMV(myVerfahrensArt)) {
  //   if (!sw) {
  //    errors.push(translate("ANSPR16"));
  //   }
  // }
};

export const isSWMV = (myVerfahrensArt: string) => {
  if ('wechsel-mahnverfahren' === myVerfahrensArt || 'scheck-mahnverfahren' === myVerfahrensArt) {
    return true;
  }
  return false;
};

/**
 * prft, ob ein Antrag eine bestimmte KatNr enthlt.
 * Dabei einen bestimmten Anspruch nicht berprfen (z.B. weil dieser
 * gerade in Bearbeitung ist).
 */
export const hasKatNrWithIndex = (aKatNr: string, indexNoCheck: number, catalogClaims: ICatalogClaim[]) => {
  if (catalogClaims !== undefined && catalogClaims.length > 0) {
    let i = 0;
    for (const einKatAnspruch of catalogClaims) {
      if (i !== indexNoCheck && einKatAnspruch.catNr === aKatNr) {
        return true;
      }
      i++;
    }
  }
  return false;
};

/**
 * plausibilisiert WEG-Mahnverfahren. <br>
 * Es wird geprft:
 * <ul>
 * <li> wird versucht im WEG-Verfahren Kat 95 oder Kat 38 zu beantragen (Message=ANSPR16).
 * </ul>
 */
export const checkWEG = (errors: string[], catalogClaim: ICatalogClaim, antragstellers: IPersonDetails[]) => {
  if (catalogClaim.catNr === '95' || catalogClaim.catNr === '38') {
    if (isASWEGVerwalterVorhanden(antragstellers)) {
      errors.push(translate('error.dunning.claims-with-catalog-number-38-or-95-not-allowed-in-WEG-procedure'));
    }
  }
};

export const isASWEGVerwalterVorhanden = (antragstellers: IPersonDetails[]) => {
  for (const einAntragsteller of antragstellers) {
    if ((einAntragsteller.innerTab as othersType) === othersType.weg) return true;
  }
  return false;
};

/**
 * plausibilisiert die KatalogArt. <br>
 * Es wird geprft:
 * <ul>
 * <li> wurde keine Katalogart ausgewhlt (Message=ANSPR2)
 * </ul>
 */
export const checkKatArt = (errors: string[], einKatAnspruch: ICatalogClaim) => {
  const katNr = einKatAnspruch.catNr;

  if (katNr === null || katNr === '0') {
    errors.push(translate('error.dunning.enter-claim-text'));
  }
};

/**
 * plausibilisiert, da nur Ansprche innerhalb der gleichen KatNrGruppe beantragt werden. <br>
 *
 * Es wird geprft:
 * <ul>
 * <li> werden Ansprche aus unterschiedlichen Gruppen beantragt? (Message=ANSPR33)
 * </ul>
 */
export const checkKatNr = (errors: string[], einKatAnspruch: ICatalogClaim, catalogClaims: ICatalogClaim[]) => {
  let tmpKatNr = '';
  // let tmpKatNrGruppe = 0;
  const katNr = einKatAnspruch.catNr;
  // TODO: Uncomment the whole commented code below which is for KatalogNrGrupper, this need to be implemented then fixed here.
  // const katNrGruppe = einKatAnspruch.getKatalogArt().getKatalogNrGruppe();
  const andereGruppe = false;

  if (catalogClaims.length === 0) return;

  for (const aAnspruch of catalogClaims) {
    tmpKatNr = aAnspruch.catNr;
    // TODO: Uncomment the whole commented code below which is for KatalogNrGrupper, this need to be implemented then fixed here.
    // tmpKatNrGruppe = aAnspruch.getKatalogArt().getKatalogNrGruppe();
    // if (tmpKatNrGruppe != katNrGruppe) {
    //   andereGruppe = true;

    //   if (tmpKatNrGruppe == KatalogArt.C_KatGruppe_Pflegeversicherung) {
    //     // Folgende KatNrn drfen auch bei Familiensachen beantragt werden
    //     if (katNr === '24' || katNr === '46' || katNr === '71') {
    //       andereGruppe = false;
    //       continue;
    //     }
    //   };
    //   if (katNrGruppe == KatalogArt.C_KatGruppe_Pflegeversicherung) {
    //     // Folgende KatNrn drfen auch bei Familiensachen beantragt werden
    //     if (tmpKatNr === '24' || tmpKatNr === '46' || tmpKatNr === '71') {
    //       andereGruppe = false;
    //       continue;
    //     }
    //   };

    //   if (tmpKatNrGruppe == KatalogArt.C_KatGruppe_Familiensachen) {
    //     // Folgende KatNrn drfen auch bei Familiensachen beantragt werden
    //     if (katNr === '24' || katNr === '39' || katNr === '46' || katNr === '71') {
    //       andereGruppe = false;
    //       continue;
    //     }
    //   };
    //   if (katNrGruppe == KatalogArt.C_KatGruppe_Familiensachen) {
    //     // Folgende KatNrn drfen auch bei Familiensachen beantragt werden
    //     if (tmpKatNr === '24' || tmpKatNr === '39' || tmpKatNr === '46' || tmpKatNr === '71') {
    //       andereGruppe = false;
    //       continue;
    //     }
    //   };

    //   break;
    // }
  }

  if (andereGruppe) {
    errors.push(translate('error.dunning.cannot-apply-for-claims-with-catalog-numbers-together', { katNr, tmpKatNr }));
  }
};

/**
 * plausibilisiert den KatAnspruch. <br>
 * Aufruf von checkSWMV(), checkKatArt(), checkAnzahl(), checkKatNr() jeweils
 * mit Beendigung der Kontrolle bei einem Fehler (return).<br>
 * Dann noch Aufruf von checkVon() und checkAnspruch() im AnspruchController,
 * checkBis(), checkAnspruchsGrund() und checkAnspruchsNr().
 */
export const checkKatAnspruch = (
  errors: string[],
  einKatAnspruch: ICatalogClaim,
  index: number,
  antragstellers: IPersonDetails[],
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  attorneyOfRecord: IAttorneyOfRecord,
  myVerfahrensArt: string,
) => {
  // Scheck/WechselMahnVerfahren
  checkSWMV(errors, einKatAnspruch, myVerfahrensArt);
  if (errors.length > 0) return;
  // Im WEG-Verfahren knnen nur Ansprche mit Ansprche mit KatNr. 90 eingetragen werden
  checkWEG(errors, einKatAnspruch, antragstellers);
  if (errors.length > 0) return;

  const size = errors.length;
  checkKatArt(errors, einKatAnspruch);
  if (size < errors.length) return;
  checkKatNr(errors, einKatAnspruch, catalogClaims);
  if (size < errors.length) return;

  const katNr = einKatAnspruch.catNr;

  if (
    (katNr === '83' && hasKatNrWithIndex('84', index, catalogClaims)) ||
    (katNr === '84' && hasKatNrWithIndex('83', index, catalogClaims))
  ) {
    errors.push(translate('error.dunning.catalog-number-83-not-allowed-with-catalog-number-84'));
  }

  // Katalognummer 82 darf nur einmal je Verfahren vorhanden sein
  if (katNr === '82' && hasKatNrWithIndex('82', index, catalogClaims)) {
    errors.push(translate('error.dunning.catalog-number-82-must-be-present-only-once-per-procedure'));
  }

  if (katNr === '50') {
    // Katalognummer 82 darf nur einmal je Verfahren vorhanden sein
    if (katNr === '50' && hasKatNrWithIndex('50', index, catalogClaims)) {
      errors.push(translate('error.dunning.catalog-number-50-must-be-present-only-once-per-procedure'));
    } else {
      // Katalognummer 50  darf nicht alleine auftreten (Warnung)
      if (isAnspruchEmpty(catalogClaims, otherClaims) || hasOnlyKatNr('50', otherClaims, catalogClaims)) {
        errors.push(translate('error.dunning.at-least-one-other-main-claim-required-for-collection-costs'));
        errors.push(translate('warning.amount-cannot-be-verified'));
      } else {
        const berechneteWerte = berechneGebuehrAuslagenMwStSumme(
          getGebuehrDatum(attorneyOfRecord),
          getHauptforderungssummeAusserKatNr('50', catalogClaims, otherClaims),
          10,
          null,
          10,
        );
        const summe = berechneteWerte.get('Summe');
        if (einKatAnspruch.amount > summe) {
          errors.push(translate('warning.warning-claimed-amount-seems-excessive'));
        }
      }
    }
  }

  checkVon(errors, einKatAnspruch);
  checkBis(errors, einKatAnspruch);
  checkAnspruch(errors, einKatAnspruch);
  checkAnspruchsGrund(errors, einKatAnspruch);
  checkAnspruchsNr(errors, einKatAnspruch);

  // nur Info: Ansprche aus Kat.Nr. 31 und 32 werden im Mahn- /Vollstreckungsbescheid
  //           als Nebenforderung ausgewiesen, wenn gleichzeitig ein
  //           Scheck-/Wechselanspruch (Kat.Nr. 30) geltend gemacht wird.
  if (katNr === '31' || katNr === '32') {
    errors.push(translate('info.claims-from-catalog-numbers-31-and-32-identified-as-secondary-claims'));
  }

  // TODO: Uncomment the code below when we received an updated list with all necessary data that we will implement in the future

  // // Bei Katalognummer 49 und 72 darf der Antragsteller keine natrliche Person sein.
  // const asPlausiMM = einKatAnspruch.getKatalogArt().getASPlausiMM();

  // if (asPlausiMM === 1) {
  //   //Antragsteller darf keine natrliche Person (Herr/Frau) sein.
  //   for (const aAS of antragstellers) {
  //     if (aAS.innerTab === othersType.naturalPersonOrCompany) {
  //       errors.push(translate("ANSPR51"));
  //       break;
  //     }
  //   }
  // }
};

/**
 * plausibilisiert die AnspruchsNr(RechnungsNummer,...). <br>
 * Prft, ob AnspruchsNr angegeben werden mu oder keine angegeben werden darf:<br>
 * Es gilt:      1 = AnspruchsNr mu angegeben werden<br>
 *               2 = AnspruchsNr darf nicht angegeben werden<br>
 * Es wird geprft:
 * <ul>
 * <li> PlausiMM = 1 und AnsprNr leer (Message=ANSPR32)
 * <li> PlausiMM = 2 und AnsprNr nicht leer  (Message=ANSPR31)
 * <li> Wenn Antrag fr Druck, dann eventuell Textkrzung mit Hinweis (Message=ANSPR23).
 * </ul>
 */
export const checkAnspruchsNr = (errors: string[], einKatAnspruch: ICatalogClaim) => {
  // TODO: Uncomment the code below when we received an updated list with all necessary data that we will implement in the future
  // const plausiMM = einKatAnspruch.getKatalogArt().getASPNrPlausiMM();
  // const aspNr = einKatAnspruch.invoiceNumber;
  // if (plausiMM == 1) {
  //   if (aspNr === '') {
  //    errors.push(translate("ANSPR32KAT" + einKatAnspruch.catNr));
  //   }
  // }
  // if (plausiMM == 2) {
  //   if (aspNr !== '') {
  //     errors.push(translate("ANSPR31"));
  //   }
  // }
};

/**
 * plausibilisiert den Anspruchsgrund(Mitteilungsform). <br>
 * Prft, ob Anspruchsgrund angegeben werden mu oder keine angegeben werden darf:<br>
 * Es gilt:      1 = Anspruchsgrund mu angegeben werden<br>
 *               2 = Anspruchsgrund darf nicht angegeben werden<br>
 * Es wird geprft:
 * <ul>
 * <li> PlausiMM = 1 und AnsprGrund leer (Message=ANSPR7)
 * <li> PlausiMM = 2 und AnsprGrund nicht leer  (Message=ANSPR8)
 * <li> wenn AnspruchsGrund ein 'Sonstiger' und AspGrZusatz leer (Message=ANSPR7b)
 * <li> Wenn Antrag fr Druck, dann eventuell Textkrzung mit Hinweis (Message=ANSPR22).
 * </ul>
 */
export const checkAnspruchsGrund = (errors: string[], einKatAnspruch: ICatalogClaim) => {
  const anspGr = einKatAnspruch.claimInformation;
  // TODO: Uncomment the code below when we received an updated list with all necessary data that we will implement in the future
  //  const plausiMM = einKatAnspruch.getKatalogArt().getASPGrPlausiMM();
  // if (plausiMM == 1) {
  //   if (anspGr === undefined || anspGr === '') {
  //     errors.push(translate("ANSPR7"));
  //     return;
  //   }
  // }
  // if (plausiMM == 2) {
  //   if (anspGr !== undefined || anspGr !== '') {
  //     errors.push(translate("ANSPR8"));
  //     return;
  //   }
  // }

  if (einKatAnspruch.whenOther === '' && einKatAnspruch.claimInformation === 'andere') {
    errors.push(translate('warning.enter-text-for-other-notification-form'));
  }
};

export const checkAnspruch = (errors: string[], einAnspruch: CombinedClaimType) => {
  checkBetragAnspruch(errors, einAnspruch);
  checkVonFormat(errors, einAnspruch);
  checkBisFormat(errors, einAnspruch);
  checkVonBis(errors, einAnspruch);
};

/**
 * plausibilisiert das von- und bis-Datum. <br>
 * Es wird geprft:
 * <ul>
 * <li> ist Von-Datum kleiner als Bis-Datum (Message=ANSPR24)
 * <li> ist Von-Datum in der Zukunft (Message=ANSPR25)
 * </ul>
 */
export const checkVonBis = (errors: string[], einAnspruch: CombinedClaimType) => {
  const dateFrom = einAnspruch.from;
  const dateTo = einAnspruch.to;
  if (dateFrom !== undefined && dateTo !== undefined) {
    if (dateFrom > dateTo) {
      errors.push(translate('error.dunning.from-date-must-be-greater-than-or-equal-to-to-date'));
    }
  }
  if (dateFrom !== undefined) {
    if (ValidationUtils.isDateInFuture(dateFrom)) {
      errors.push(translate('error.dunning.from-date-is-in-the-future'));
    }
  }
};

export const checkBetragAnspruch = (errors: string[], einAnspruch: CombinedClaimType) => {
  const betrag = einAnspruch.amount;
  if (betrag === 0) {
    errors.push(translate('error.dunning.enter-an-amount'));
  }
  if (betrag > 99999999.99) {
    errors.push(translate('error.amount-too-high'));
  }
  if (betrag < 0.0) {
    errors.push(translate('error.amount-too-low'));
  }
};

/**
 * plausibilisiert das bis-Datum. <br>
 * Es wird geprft:
 * <ul>
 * <li> ist das Datum dirty (Message=ANSPR6)
 * <li> AMV kann nur von 1970 bis 2069 (Message=ANSPR35).
 * </ul>
 */
export const checkBisFormat = (errors: string[], einAnspruch: CombinedClaimType) => {
  const dateTo = einAnspruch.to;
  if (ValidationUtils.isDateValid(dateTo) && !ValidationUtils.isYearInRange(dateTo, 1970, 2069)) {
    errors.push(translate('error.dunning.to-date-is-invalid-not-in-range-1970-to-2069'));
  }
};

/**
 * plausibilisiert das von-Datum. <br>
 * Es wird geprft:
 * <ul>
 * <li> ist das Datum dirty (Message=ANSPR4)
 * <li> Datum leer (Message=ANSPR34)
 * <li> AMV kann nur von 1970 bis 2069 (Message=ANSPR34).
 * </ul>
 */
export const checkVonFormat = (errors: string[], einAnspruch: CombinedClaimType) => {
  const dateFrom = einAnspruch.from;
  if (dateFrom !== undefined && !ValidationUtils.isYearInRange(dateFrom, 1970, 2069)) {
    errors.push(translate('error.dunning.from-date-is-invalid-not-in-range-1970-to-2069'));
  }
};

/**
 * plausibilisiert das von-Datum. <br>
 * Anhand des PlausiMerkmals wird geprft, ob ein Von-Datum angegeben werden mu, kann
 * oder nicht angegeben werden darf.<br>
 *   Es gilt:     0  =  ASPVD kann wahlweise angegeben werden (keine Plausi)
 *                1  =  ASPVD muss gefllt sein
 *                2  =  ASPVD darf nicht gefllt sein (wird im Moment nicht verwendet)
 * Es wird geprft:
 * <ul>
 * <li> PlausiMM = 0, keine Prfung
 * <li> PlausiMM = 1 und Datum leer  (Message=ANSPR14)
 * <li> PlausiMM = 2, Datum nicht leer aber gefllt (Message=ANSPR9)
 * </ul>
 */
export const checkVon = (errors: string[], einKatAnspruch: CombinedClaimType) => {
  // TODO: Uncomment the code below when we received an updated list with all necessary data that we will implement in the future
  // const plausiMM = einKatAnspruch.getKatalogArt().getASPVDPlausiMM();
  // const von = einKatAnspruch.from;
  // const leer = von === undefined;
  // if (plausiMM == 1) {
  //   if (leer) {
  //     errors.push(translate("ANSPR14"));
  //   }
  // }
  // if (plausiMM == 2) {
  //   if (!leer) {
  //     errors.push(translate("ANSPR9"));
  //   }
  // }
};

/**
 * plausibilisiert das bis-Datum. <br>
 * Anhand des PlausiMerkmals wird geprft, ob ein Bis-Datum angegeben werden mu, kann
 * oder nicht angegeben werden darf.<br>
 * Es gilt:       0  =  ASPBD kann wahlweise angegeben werden (keine Plausi)<br>
 *                      (darf aber nicht in der Zukunft liegen)<br>
 *                1  =  ASPBD mu gefllt sein<br>
 *                      (darf aber nicht in der Zukunft liegen, FEHLER)<br>
 *                2  =  ASPBD darf nicht gefllt sein<br>
 *                3  =  ASPBD kann wahlweise angegeben werden und kann in die<br>
 *                      Zukunft reichen<br>
 *                4  =  ASPBD mu gefllt sein und kann in die Zukunft reichen
 *                5  =  ASPBD mu gefllt sein<br>
 *                      (darf aber nicht in der Zukunft liegen, WARNUNG)<br>
 * Es wird geprft:
 * <ul>
 * <li> PlausiMM = 0, Datum nicht leer und liegt in der Zukunft (Message=ANSPR26)
 * <li> PlausiMM = 1 und Datum leer  (Message=ANSPR5)
 * <li> PlausiMM = 1, Datum nicht leer und liegt in der Zukunft (Message=ANSPR26)
 * <li> PlausiMM = 2, Datum nicht leer und liegt in der Zukunft (Message=ANSPR26)
 * </ul>
 */
export const checkBis = (errors: string[], einKatAnspruch: ICatalogClaim) => {
  // TODO: Uncomment the code below when we received an updated list with all necessary data that we will implement in the future
  // const plausiMM = einKatAnspruch.getKatalogArt().getASPBDPlausiMM();
  // const bis = einKatAnspruch.to;
  // const leer = bis === undefined;
  // if (plausiMM == 0) {
  //   if (!leer && ValidationUtils.isDateInFuture(bis)) {
  //     errors.push(translate("ANSPR26"));
  //   }
  // }
  // if (plausiMM == 1) {
  //   if (leer) {
  //     errors.push(translate("ANSPR5"));
  //   } else if (ValidationUtils.isDateInFuture(bis)) {
  //     errors.push(translate("ANSPR26"));
  //   }
  // }
  // if (plausiMM == 2) {
  //   if (!leer) {
  //     errors.push(translate("ANSPR10"));
  //   }
  // }
  // if (plausiMM == 4) {
  //   if (leer) {
  //     errors.push(translate("ANSPR5"));
  //   }
  // }
  // if (plausiMM == 5) {
  //   if (leer) {
  //     errors.push(translate("ANSPR5"));
  //   } else if (ValidationUtils.isDateInFuture(bis)) {
  //     errors.push(translate("ANSPR26w"));
  //   }
  // }
};

export const getGebuehrDatum = (attorneyOfRecord: IAttorneyOfRecord) => {
  if (!isObjectEmpty(attorneyOfRecord) && attorneyOfRecord.commissionDate !== undefined) {
    return new Date(attorneyOfRecord.commissionDate);
  } else {
    return new Date();
  }
};

/**
 *Berechnet die Gerichtsgebuehr abhngig von der Forderungshhe in Euro
 *
 * @return Waehrung
 */
export const berechneGebuehrEURKostRAEG2021 = (aBetrag: number, aZaehler: number, aNenner: number) => {
  let restbetrag = 0;
  let theGebuehr = 0;
  let divErgebnis = 0;

  if (aBetrag <= tabEURKostRAEG2021[0][vonWert]) {
    theGebuehr = tabEURKostRAEG2021[0][kumGebuehr];
  }
  if (aBetrag > tabEURKostRAEG2021[0][vonWert]) {
    for (let i = 0; i < tabEURKostRAEG2021.length; i++) {
      if (aBetrag < tabEURKostRAEG2021[i][bisWert] || tabEURKostRAEG2021[i][bisWert] === 0) {
        restbetrag = aBetrag - tabEURKostRAEG2021[i][vonWert];
        divErgebnis = Math.ceil(restbetrag / tabEURKostRAEG2021[i][erhWert]);
        theGebuehr = divErgebnis * tabEURKostRAEG2021[i][erhGebuehr] + tabEURKostRAEG2021[i][kumGebuehr];
        break;
      }
    }
  }

  theGebuehr = Math.round(((theGebuehr * aZaehler) / aNenner) * 100) / 100;
  // theGebuehr = aufrundenAufEineDezimalstelle(theGebuehr);

  if (theGebuehr < mindestEURKostRAEG) {
    theGebuehr = mindestEURKostRAEG;
  }

  return theGebuehr;
};

/**
 * Liegt das aktuelle Datum ab dem 01.08.2013 wird gem dem
 * 2. KostRMoG berechnet. (Wrapperclass)
 *
 * @return Waehrung
 */
export const berechneGebuehr = (aDatum: Date, aBetrag: number, aZaehler: number, aNenner: number) => {
  if (isKostRAEG2021InKraft(aDatum)) {
    return berechneGebuehrEURKostRAEG2021(aBetrag, aZaehler, aNenner);
  }

  if (is2KostRMoGInKraft(aDatum)) {
    // Wir nehmen ab 1.8.2013 die neue Tabelle
    return berechneGebuehrEUR2KostRMoG(aBetrag, aZaehler, aNenner);
  }

  return berechneGebuehrEUR(aBetrag, aZaehler, aNenner);
};

/**
 *Berechnet die Gerichtsgebuehr abhngig von der Forderungshhe in Euro
 *
 * @return Waehrung
 */
export const berechneGebuehrEUR = (aBetrag: number, aZaehler: number, aNenner: number) => {
  let restbetrag = 0;
  let theGebuehr = 0;
  let divErgebnis = 0;

  if (aBetrag <= tabEUR[0][vonWert]) {
    theGebuehr = tabEUR[0][kumGebuehr];
  }
  if (aBetrag > tabEUR[0][vonWert]) {
    for (let i = 0; i < tabEUR.length; i++) {
      if (aBetrag < tabEUR[i][bisWert] || tabEUR[i][bisWert] === 0) {
        restbetrag = aBetrag - tabEUR[i][vonWert];
        divErgebnis = Math.ceil(restbetrag / tabEUR[i][erhWert]);
        theGebuehr = divErgebnis * tabEUR[i][erhGebuehr] + tabEUR[i][kumGebuehr];
        break;
      }
    }
  }

  theGebuehr = Math.round(((theGebuehr * aZaehler) / aNenner) * 100) / 100;
  // theGebuehr = aufrundenAufEineDezimalstelle(theGebuehr);

  if (theGebuehr < mindestEUR) {
    theGebuehr = mindestEUR;
  }

  return theGebuehr;
};

/**
 *Berechnet die Gerichtsgebuehr abhngig von der Forderungshhe in Euro
 *
 * @return Waehrung
 */
export const berechneGebuehrEUR2KostRMoG = (aBetrag: number, aZaehler: number, aNenner: number) => {
  let restbetrag = 0;
  let theGebuehr = 0;
  let divErgebnis = 0;

  if (aBetrag <= tabEUR2KostRMoG[0][vonWert]) {
    theGebuehr = tabEUR2KostRMoG[0][kumGebuehr];
  }
  if (aBetrag > tabEUR2KostRMoG[0][vonWert]) {
    for (let i = 0; i < tabEUR2KostRMoG.length; i++) {
      if (aBetrag < tabEUR2KostRMoG[i][bisWert] || tabEUR2KostRMoG[i][bisWert] === 0) {
        restbetrag = aBetrag - tabEUR2KostRMoG[i][vonWert];
        divErgebnis = Math.ceil(restbetrag / tabEUR2KostRMoG[i][erhWert]);
        theGebuehr = divErgebnis * tabEUR2KostRMoG[i][erhGebuehr] + tabEUR2KostRMoG[i][kumGebuehr];
        break;
      }
    }
  }

  theGebuehr = Math.round(((theGebuehr * aZaehler) / aNenner) * 100) / 100;
  // theGebuehr = aufrundenAufEineDezimalstelle(theGebuehr);

  if (theGebuehr < mindestEUR2KostRMoG) {
    theGebuehr = mindestEUR2KostRMoG;
  }

  return theGebuehr;
};

/**
 * Ist das Kostenrechtsnderungsgesetz 2021  KostRG 2021 in Kraft. </br>
 *
 * @return boolean
 */
export const isKostRAEG2021InKraft = (date: Date) => {
  if (date < new Date('01.01.2021')) {
    return false;
  } else {
    return true;
  }
};

/**
 * Ist das 2. KostRMoG in Kraft. </br>
 *
 * @return boolean
 */
export const is2KostRMoGInKraft = (date: Date) => {
  const aDatum = new Date('01.08.2013');

  if (date < aDatum) {
    return false;
  } else {
    return true;
  }
};

/**
 * Es werden von dem Betrag 15% berechnet und zurck geliefert
 *
 * @return Waehrung
 */
export const berechneAuslagen = (aBetrag: number) => {
  let theAuslagen = Math.round(aBetrag * 20) / 100;
  // Gem. RVG 7002 drfen die Auslagen 20%, jedoch nicht mehr als 20 Eur betragen
  if (theAuslagen > 20.0) theAuslagen = 20.0;

  return theAuslagen;
};

export const berechneGebuehrAuslagenMwStSumme = (aDatum: Date, aBetrag: number, aZaehler: number, aAuslagen: number, aNenner: number) => {
  const aMap = new Map<string, number>();
  const aGebuehr = berechneGebuehr(aDatum, aBetrag, aZaehler, aNenner);
  aMap.set('Gebuehr', aGebuehr);
  if (aAuslagen === null) {
    aAuslagen = berechneAuslagen(berechneGebuehr(aDatum, aBetrag, aZaehler, aNenner));
    aMap.set('Auslagen', aAuslagen);
  }
  let aMwSt = 0;
  let aSumme = 0;
  aSumme += aGebuehr;
  aSumme += aAuslagen;
  aMwSt = berechneMwSt(aSumme);
  aMap.set('MwSt', aMwSt);
  aSumme += aMwSt;
  aMap.set('Summe', aSumme);
  return aMap;
};

/**
 * Es werden von dem Betrag 15% berechnet und zurck geliefert
 *
 * @return Waehrung
 */
export const berechneMwSt = (aBetrag: number) => {
  let theMwStSatz = 0.0;
  const mwstSatz = 0.19;
  theMwStSatz = Math.round(aBetrag * mwstSatz * 100) / 100;

  return theMwStSatz;
};

// * plausibilisiert den Antragsgegner. <br>
// * Aufruf von checkPartner() aus dem PartnerController und checkProzessGericht().
// * Wegen der Beschrnkung des Auslandsmahnverfahrens auf bestimmte
// * (europische) Lnder noch der Aufruf von checkNationAuslandMB() in diesem Controller.
// */
export const checkAntragsgegner = (errors: string[], einAntragsgegner: IPersonDetails) => {
  if ((einAntragsgegner.innerTab as othersType) === othersType.pka) {
    checkAntragsgegnerPkA(errors, einAntragsgegner);
  } else {
    checkPartner(errors, einAntragsgegner);
  }
  if (isInDeutschland(einAntragsgegner)) {
    checkProzessGericht(errors, einAntragsgegner);
  }
  //  if(errors.length === 0) checkNationAuslandMB(errors, einAntragsgegner); // TODO: Needs to be done when foreign country logic is implemneted or deleted.
};

export const checkProzessGericht = (errors: string[], einAntragsgegner: IPersonDetails) => {
  if (einAntragsgegner.defendantTrialCourtData === undefined || einAntragsgegner.defendantTrialCourtData === null) {
    errors.push(translate('warning.court-cannot-be-determined'));
  }
};

/**
 * plausibilisiert den Antragsgegner (Partei kraft Amtes). <br>
 * Es wird geprft:
 * <ul>
 * <li> Wurde ein Vertetenes Vermgen angegeben (Message=AS4)
 * </ul>
 * Bei PkA steht im Antragsgegner das Vertretene Vermgen und in dem GV die<br>
 * restlichen Daten des PkA.<br>
 * Eigentlich wird nur das vertretene Vermgen (PkA) geplaust.<br>
 * Geprft wird, ob das vertretene Vermgen wenigstens ein Zeichen enthlt.<br>
 * Wenn nur im zweiten Eingabefeld ein Text steht, wir dier in das erste bertregen.
 * Also der Name3 wird in Name1 geschoben.
 */
export const checkAntragsgegnerPkA = (errors: string[], einAntragsgegner: IPersonDetails) => {
  const laenge = einAntragsgegner.name[0].trim().length;
  const laenge2 = einAntragsgegner.name[1].trim().length;
  if (laenge < 1 && laenge2 < 1) {
    errors.push(translate('error.dunning.specify-the-managed-assets'));
  }
};

export const checkGesetzlicherVertreter = (
  errors: string[],
  einGesetzlicherVertreter: ILegalRepresentative,
  einAntragsteller: IPersonDetails,
) => {
  const antragstellerInnerTab = einAntragsteller.innerTab as othersType;
  if (antragstellerInnerTab === othersType.pka) {
    checkGesetzlicherVertreterPkA(errors, einGesetzlicherVertreter);
  } else {
    // Wenn WEG vertreten durch jur. Person, dann die GV-Funktion für den ersten GV nicht prüfen
    if (antragstellerInnerTab === othersType.legalEntity && Object.values(einAntragsteller.legalRepresentatives).length === 0) {
      // nicht prüfen
    } else {
      checkVertreterFunktion(errors, einGesetzlicherVertreter, einAntragsteller);
    }

    // TODO: Uncoomment this after Rechtsform object is implemented.
    // if (einAntragsteller.isEingetragenerKaufmann()) {
    //   checkNameEK(errors, einGesetzlicherVertreter);
    // } else
    if (
      antragstellerInnerTab === othersType.naturalPersonOrCompany ||
      //  || einAntragsteller.isGbR()   // TODO fix when Rechtsform is implemented
      !(antragstellerInnerTab === othersType.legalEntity && Object.values(einAntragsteller.legalRepresentatives).length === 1) ||
      //  || einGesetzlicherVertreter.isGmbHUndCoKGVertreter()  // TODO fix when Rechtsform is implmeneted
      isGmbHUndCoKG(einAntragsteller)
    ) {
      checkNameWithAntragsteller(errors, einGesetzlicherVertreter, einAntragsteller);
    } else {
      validateName(errors, einGesetzlicherVertreter.name);
    }
    // Der GesetzlicheVertreter einr NAt. Person muß vollständige Adresse haben
    if (antragstellerInnerTab === othersType.naturalPersonOrCompany || antragstellerInnerTab !== othersType.legalEntity) {
      checkStr(errors, einGesetzlicherVertreter.streetOrHouseNumber);
    }
    validateStr(errors, einGesetzlicherVertreter.streetOrHouseNumber);
    // checkNation(errors, einGesetzlicherVertreter); // TODO: Implement when necessary or delete.
  }
};

export const validateNameWithAntragsteller = (
  errors: string[],
  einGesetzlicherVertreter: ILegalRepresentative,
  einAntragsteller: IPersonDetails,
) => {
  const aString = einGesetzlicherVertreter.name[0].trim();
  const laenge = aString.length;
  if (laenge > 0 && laenge < 2) {
    errors.push(translate('error.dunning.first-or-last-name-is-too-short'));
  } else {
    // Wenn es sich bei dem GV um eine Firma handelt (bei GmbH & Co KG).
    // Oder es handelt sich um eine juristische Person die eine WEG vertritt (dann der erste Vertreter und der hat keiner VertreterFunktion).

    // TODO: Uncomment the line after Rechtsform is implemented and put the condition inside the below if.
    // if (einGesetzlicherVertreter.isGmbHUndCoKGVertreter() ||
    if (
      einAntragsteller != null &&
      (einAntragsteller.innerTab as othersType) === othersType.legalEntity &&
      einGesetzlicherVertreter.function === ''
    ) {
      validateString(
        errors,
        einGesetzlicherVertreter.name[0].trim(),
        '',
        [],
        'warning.description-contains-invalid-characters-or-words',
        'warning.description-contains-invalid-characters-or-words',
      );
      validateString(
        errors,
        einGesetzlicherVertreter.name[1].trim(),
        '',
        [],
        'warning.description-contains-invalid-characters-or-words',
        'warning.description-contains-invalid-characters-or-words',
      );
    } else {
      validateString(
        errors,
        einGesetzlicherVertreter.name[0].trim(),
        ungueltigeZeichenNameGV,
        ungueltigeStringsNameGV,
        'warning.first-or-last-name-contains-invalid-characters-or-words',
        'warning.first-or-last-name-contains-invalid-characters-or-words',
      );
      validateStringSZ(errors, aString);
      validateVokale(errors, aString, 'Der Vor-/Nachname', 'warning.name-contains-no-vowel');
    }
  }
};

export const checkNameWithAntragsteller = (
  errors: string[],
  einGesetzlicherVertreter: ILegalRepresentative,
  einAntragsteller: IPersonDetails,
) => {
  const laenge = einGesetzlicherVertreter.name[0].trim().length;
  if (laenge < 1) {
    errors.push(translate('error.dunning.enter-the-name-of-the-legal-representative'));
  } else {
    validateNameWithAntragsteller(errors, einGesetzlicherVertreter, einAntragsteller);
  }
};

export const checkNameEK = (errors: string[], einGesetzlicherVertreter: ILegalRepresentative) => {
  const laenge = einGesetzlicherVertreter.name[0].trim().length;
  if (laenge < 1) {
    errors.push(translate('warning.enter-the-name-of-the-owner'));
  } else {
    validateName(errors, einGesetzlicherVertreter.name);
  }
};

export const checkGesetzlicherVertreterPkA = (errors: string[], einGesetzlicherVertreter: ILegalRepresentative) => {
  checkVorname(errors, einGesetzlicherVertreter.name[0]);
  checkNachname(errors, einGesetzlicherVertreter.name[1]);
  checkStr(errors, einGesetzlicherVertreter.streetOrHouseNumber);
  // checkNation(errors, einGesetzlicherVertreter); // TODO: Implement when necessary or delete.
};

/**
 * plausibilisiert die Funktion des gesetzlichen Vertreters für den Antragsteller. <br>
 * Es wird geprüft:
 * <ul>
 * <li> ist Vertreterfunktiob leer (Message=GV10)
 * <li> passt die Vertreterfunktion nicht zur natürl. Person (Message=GV4)
 * <li> passt die Vertreterfunktion nicht zur natürl. Firma (Message=GV3)
 * <li> Manche Firmenformen brauchen Firma als GV (GmbH&CoKG) (Message=GV5)
 * </ul>
 *
 * @param aMC
 * @param einGesetzlicherVertreter
 * @param einAntragsteller
 */
export const checkVertreterFunktion = (
  errors: string[],
  einGesetzlicherVertreter: ILegalRepresentative,
  einAntragsteller: IPersonDetails,
) => {
  // Bei natürlichen Personen wird die VertreterFunktion nicht anhand der Rechtsform geprüft.
  if ((einAntragsteller.innerTab as othersType) === othersType.naturalPersonOrCompany) {
    // Ist die ermittelte Vertreterfunktion nicht in der Liste der möglichen, dann Fehler.
    const aGVFunktion = einGesetzlicherVertreter.function;

    // Unterschiedliche Fehlermeldungen, wenn keine ausgewählt und wenn sie nicht passt
    if (aGVFunktion === undefined || aGVFunktion === '') {
      errors.push(translate('error.dunning.specify-a-function'));
      return;
    }
    // TODO: Comment code below, need to be fixed after Rechtsform object it is done.
    //   else if (!einAntragsteller.getAnrede().isVertreterFunktionErlaubt(aGVFunktion)) {
    //     errors.push(translate("GV4"));
    //     return ;
    //   }
    return;
  }

  // Ist GVFirma gefüllt (>0) so handelt es sich um eine Firma die durch eine Firma vertreten wird.<br>
  // Da die VertreterFirma fest vorgegeben ist, muß diese nicht weiter plausibilisiert werden.
  // Die möglichen Vertreterfunktionen der VertreterFirma sind über die vertretene Firma hinterlegt.
  const aRechtsform = einAntragsteller.companyLegalOption;

  // TODO: Comment code below, need to be fixed after Rechtsform object it is done.
  // if (aRechtsform !== undefined && aRechtsform !== '') {
  //   const aGVFirma = aRechtsform.getGVFirma();
  //   if (aGVFirma != 0) {
  //     int indexGV = einAntragsteller.getGesetzlicheVertreter().indexOf(einGesetzlicherVertreter);
  //     //Nur wenn es sich bei dem ersten GV um eine Firma mit GVFirma handelt ist die
  //     // Plausi auf die VertreterFunktion auszusetzen.
  //     if (indexGV == 0 || einGesetzlicherVertreter.isGmbHUndCoKGVertreter()) {
  //       if (null != einGesetzlicherVertreter.getVertreterFunktion()) {
  //         MessageFactory theFactory = MessageFactory.getFactory() ;
  //         Message theMessage = theFactory.getMessage("GV5", new Object[] {einAntragsteller.getRechtsform().getDisplayName(), Rechtsformen.getRechtsform(aGVFirma).getDisplayName() });
  //         aMC.addToMessages(theMessage);
  //       }
  //       return ;
  //     } else if (einAntragsteller.isGmbHUndCoKG()) {
  //       if (einAntragsteller.getGesetzlicheVertreter().size() > 0 && indexGV != 0) {
  //         aRechtsform = Rechtsformen.getRechtsform(aGVFirma);
  //       }
  //     }
  //   }
  // }

  if (einGesetzlicherVertreter.function === undefined || einGesetzlicherVertreter.function === '') {
    // Ist keine Funktion ausgewählt worden, dann Fehlermeldung
    errors.push(translate('error.dunning.specify-a-function'));
  }
  // TODO: Comment code below, need to be fixed after Rechtsform object it is done.
  // else if (aRechtsform != null && !aRechtsform.isVertreterFunktionErlaubt(einGesetzlicherVertreter.getVertreterFunktion())) {
  //   //Ist die ermittelte Vertreterfunktion nicht in der Liste der möglichen, dann Fehler.
  //   errors.push(translate("GV3"));
  // }
};

/**
 * plausibilisiert, ob Firmen auch einen GV haben. <br>
 * Diese Methode wird aus der MBUebersicht aufgerufen. <br>
 * Es wird geprft:
 * <ul>
 * <li> Firma hat (auer wenn EK) nicht wenigsten einen GV (Message=GV15)
 * <li> GmbH u. Co.KG hat nicht wenigsten zwei GV (Message=GV15)
 * </ul>
 */
export const checkMussGVHaben = (errors: string[], einAG: IPersonDetails) => {
  if (isGmbHUndCoKG(einAG)) {
    const einAGInnerTab = einAG.innerTab as othersType;
    if (
      (einAG.tab as applicantOrDefendantType) === applicantOrDefendantType.company ||
      einAGInnerTab === othersType.more ||
      einAGInnerTab !== othersType.naturalPersonOrCompany
    ) {
      // TODO: Uncomment the code below when Rechtsform is implemented correctly and fix it as well.
      //   if (!einAG.isEinzelfirma() && !einAG.isEingetragenerKaufmann() && Object.values(einAG.legalRepresentatives).length == 0) {
      //     errors.push(translate("GV15"));
      //   } else
      if (einAGInnerTab === othersType.legalEntity) {
        errors.push(translate('error.dunning.specify-another-legal-representative'));
      }
    }
  }
};

export const isGmbHUndCoKG = (antragsteller: IPersonDetails) => {
  if (antragsteller.companyLegalOption === 'GmbH' || antragsteller.companyLegalOption.includes('Co KG')) {
    return true;
  }
  return false;
};

/**
 * plausibilisiert den ProzessVertreter. <br>
 * Aufruf von checkKennziffer(), checkBetrag(), checkTitelMM(), checkName(), checkStr,
 * checkPLZOrt(), checkNation() und checkBankVerbindung()
 */
export const checkProzessVertreter = (errors: string[], attorneyOfRecord: IAttorneyOfRecord, currentStateShort: string) => {
  const aMCSize = errors.length;
  checkKennzifferForProzessVertreter(errors, attorneyOfRecord, currentStateShort);

  // Nur weiterprfen, wenn checkKennziffer keine Message hinzugefgt hat.
  if (aMCSize === errors.length) {
    checkBetragErklaerungVorsteuer(errors, attorneyOfRecord);

    if (!isDatenEmptyProzessVertrter(attorneyOfRecord)) {
      checkTitelMM(errors, attorneyOfRecord);
      checkNameProzessVertreter(errors, attorneyOfRecord);
      checkStr(errors, attorneyOfRecord.streetAndHouseNumber);
      // checkNation(errors, attorneyOfRecord); // TODO: Implement this when logic for Nation for LocationDetailsField is implemnted as well.
    }
  }
};

/**
 * plausibilisiert den Namen des ProzessVertreters. <br>
 * Es wird geprft:
 * <ul>
 * <li> Name leer (Message=PV1)
 * <li> Name enthlt nur 1 Zeichen (Message=PV2)
 * <li> enthlt ungltige Zeichen (durch Methode validateString()) (Message=PV29)
 * </ul>
 * Aufruf von validateVokale().
 */
export const checkNameProzessVertreter = (errors: string[], attorneyOfRecord: IAttorneyOfRecord) => {
  if (attorneyOfRecord.name === undefined) {
    return;
  }
  const aString = attorneyOfRecord.name[0];
  if (aString === undefined || aString === '') {
    return;
  }
  const laenge = aString.length;
  if (laenge < 1) {
    errors.push(translate('error.dunning.enter-first-and-last-names'));
  } else if (laenge < 2) {
    errors.push(translate('warning.first-or-last-name-is-too-short'));
  } else {
    validateString(
      errors,
      aString.trim(),
      ungueltigeZeichenNamePV,
      ungueltigeStringsNamePV,
      'warning.first-or-last-name-contains-invalid-characters-or-words',
      'warning.first-or-last-name-contains-invalid-characters-or-words',
    );
    validateVokale(errors, aString, 'Die Bezeichnung', 'warning.name-contains-no-vowel');
  }
};

/**
 * plausibilisiert das TitelMM des ProzessVertreters. <br>
 * Es wird geprft:
 * <ul>
 * <li> kein Titel fr den PV angegeben (Message=PV5)
 * </ul>
 */
export const checkTitelMM = (errors: string[], attorneyOfRecord: IAttorneyOfRecord) => {
  const attorneyOfRecordType = attorneyOfRecord.type as tabType;
  if (!isTitelMMGueltig(attorneyOfRecord)) {
    errors.push(translate('error.dunning.specify-the-designation-of-the-process-representative'));
  } else {
    if (attorneyOfRecordType === tabType.lawyerCompany && attorneyOfRecord.descriptionOrLegalOption === '') {
      errors.push(translate('error.dunning.specify-the-legal-form-of-the-process-representative'));
    }
    if (attorneyOfRecordType === tabType.collectionAgency && attorneyOfRecord.descriptionOrLegalOption === '') {
      errors.push(translate('error.dunning.specify-the-legal-form-of-the-process-representative'));
    }
  }
};

export const isTitelMMGueltig = (attorneyOfRecord: IAttorneyOfRecord) => {
  if (optionsArray.includes(attorneyOfRecord.descriptionOrLegalOption)) {
    return true;
  } else if (
    [tabType.collectionAgency, tabType.consumerAssociation, tabType.lawyer, tabType.lawyerCompany, tabType.mrMrs].includes(
      attorneyOfRecord.type,
    )
  ) {
    return true;
  }
  return false;
};

export const isObjectEmpty = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0) &&
        value !== false &&
        value[0] !== ''
      ) {
        return false;
      }
    }
  }
  return true;
};

/**
 * prft, ob bei Betragsangabe, Bauftragungsdatumangabe oder Erklrung Vorsteuer auch
 * weitere Angaben zum PV (Kennziffer oder Name, etc.)gemacht wurden.
 */
export const checkBetragErklaerungVorsteuer = (errors: string[], attorneyOfRecord: IAttorneyOfRecord) => {
  if (isObjectEmpty(attorneyOfRecord)) {
    return;
  }
  if (
    isDatenEmptyProzessVertrter(attorneyOfRecord) &&
    (attorneyOfRecord.readerServiceCode === '' || attorneyOfRecord.readerServiceCode === undefined) &&
    isBetragErklaerungVorsteuerNotEmpty(attorneyOfRecord)
  ) {
    errors.push(
      translate(
        'error.dunning.provide-information-on-expenses-fees-vat-or-input-tax-deduction-eligibility-you-must-specify-either-process-representative-data-or-a-process-representative-identifier',
      ),
    );
  } else if (
    isDatenEmptyProzessVertrter(attorneyOfRecord) &&
    (attorneyOfRecord.readerServiceCode === '' || attorneyOfRecord.readerServiceCode === undefined) &&
    !isBeauftragungsdatumEmpty(attorneyOfRecord.commissionDate)
  ) {
    errors.push(translate('error.dunning.specify-either-identifier-or-process-representative-data'));
  }

  checkAuftragsDatum(errors, attorneyOfRecord);
  checkBetrag(errors, attorneyOfRecord);
  checkMehrwertsteuersatz(errors, attorneyOfRecord);
};

export const checkRenumerationRVG = (errors: string[],attorneyOfRecord: IAttorneyOfRecord) => {
  if (
    isInDeutschland(attorneyOfRecord) &&
    (attorneyOfRecord.readerServiceCode === '' || attorneyOfRecord.readerServiceCode === undefined) &&
    attorneyOfRecord.renumerationRvg !== 0 && attorneyOfRecord.renumerationRvg !== undefined
  ) {
    errors.push(
      translate('error.dunning.specifying-a-different-tax-rate-from-the-legal-tax-rate-for-a-fee-is-only-possible-with-foreign-lawyers'),
    );
  }
}

export const checkMehrwertsteuersatz = (errors: string[], attorneyOfRecord: IAttorneyOfRecord) => {
  if (
    !isObjectEmpty(attorneyOfRecord) &&
    attorneyOfRecord.renumerationRvg !== undefined
  ) {
    const renumerationRvg = attorneyOfRecord.renumerationRvg;
    checkRenumerationRVG(errors, attorneyOfRecord);
    if (renumerationRvg > 99.99) {
      errors.push(translate('error.dunning.value-for-the-vat-rate-is-too-high'));
    }
    if (renumerationRvg < 0.01) {
      errors.push(translate('error.dunning.value-for-the-vat-rate-is-too-low'));
    }

    if (!isRechtsanwalt(attorneyOfRecord)) {
      errors.push(translate('error.dunning.specifying-a-vat-rate-is-only-possible-for-lawyers'));
    }
  }
};

export const isBetragErklaerungVorsteuerNotEmpty = (attorneyOfRecord: IAttorneyOfRecord): boolean => {
  const { expenses, isApplicantEntitledToDeductInputText, isLegalRepresentativeExemptFromPaymentSalesTax } = attorneyOfRecord;

  if (expenses === undefined) {
    return false;
  }

  return isApplicantEntitledToDeductInputText || isLegalRepresentativeExemptFromPaymentSalesTax;
};

export const checkBetrag = (errors: string[], attorneyOfRecord: IAttorneyOfRecord) => {
  const expenses = attorneyOfRecord.expenses;
  if (expenses !== undefined && expenses !== 0) {
    if (expenses > 999999.99) {
      errors.push(translate('error.dunning.amount-for-expenses-is-too-high'));
    }
    if (expenses < 0.0) {
      errors.push(translate('error.dunning.amount-for-expenses-is-too-low'));
    }

    if (!isRechtsanwalt(attorneyOfRecord)) {
      errors.push(translate('error.dunning.specifying-expenses-is-only-possible-for-lawyers'));
    }
  }
};

export const checkAuftragsDatum = (errors: string[], attorneyOfRecord: IAttorneyOfRecord) => {
  if (
    (!isObjectEmpty(attorneyOfRecord) && !isDatenEmptyProzessVertrter(attorneyOfRecord)) ||
    (attorneyOfRecord.readerServiceCode !== '' && attorneyOfRecord.readerServiceCode !== undefined)
  ) {
    if (
      !isBeauftragungsdatumEmpty(attorneyOfRecord.commissionDate) &&
      !ValidationUtils.isYearInRange(attorneyOfRecord.commissionDate, 1970, 2069)
    ) {
      errors.push(translate('error.dunning.commissioning-date-is-not-valid-in-range-1970-to-2069'));
    }
  }
};

/**
 * liefert true, wenn keine Betrag und keine Erklrung zur Vorsteuer
 * angegeben ist.
 *
 * @return boolean
 */
export const isBeauftragungsdatumEmpty = (commissionDate: string) => {
  if (ValidationUtils.isDateValid(commissionDate)) return false;
  return true;
};

/**
 * plausibilisiert die Kennziffer des ProzessVertreters. <br>
 * Es wird geprft:
 * <ul>
 * <li> PVDaten und Kennziffer eingegeben (Message=PV6)
 * </ul>
 * Wenn Kennziffer nicht leer, dann Aufruf von validatePVKennziffer() im KennzifferController.
 */
export const checkKennzifferForProzessVertreter = (errors: string[], attorneyOfRecord: IAttorneyOfRecord, currentStateShort: string) => {
  const aMCSize = errors.length;
  if (attorneyOfRecord.readerServiceCode !== '' && attorneyOfRecord.readerServiceCode !== undefined) {
    validatePVKennziffer(errors, attorneyOfRecord.readerServiceCode);
  }
  if (errors.length > aMCSize) {
    return;
  }
  validateKennzifferBundesland(errors, attorneyOfRecord.readerServiceCode, currentStateShort);
};

export const validateKennzifferBundesland = (errors: string[], kennziffer: string, bundesland: string) => {
  if (bundesland === null) {
    return;
  }
  if (kennziffer === '' || kennziffer === undefined) {
    return;
  }
  if (kennziffer.length < 8) {
    errors.push(translate('error.dunning.enter-the-input-identifier-as-8-digits'));
    return;
  }

  try {
    const kennzifferInt = parseInt(kennziffer, 10);
    const bundeslandInt = parseInt(stateToLKZ.get(bundesland), 10);
    validateKennzifferBundeslandNumber(errors, kennzifferInt, bundeslandInt);
  } catch (error) {
    errors.push(translate('error.dunning.identifier-contains-invalid-characters'));
  }
};

export const validateKennzifferBundeslandNumber = (errors: string[], kennzifferInt: number, bundeslandInt: number) => {
  const blkennziffer = Math.floor(kennzifferInt / 1000000);
  let blkennziffervergleich;
  if (blkennziffer < 10) blkennziffervergleich = Math.floor(blkennziffer % 10);
  else blkennziffervergleich = blkennziffer;

  if (blkennziffervergleich !== bundeslandInt) {
    errors.push(translate('error.dunning.identifier-does-not-match-the-selected-state'));
  }
};

/**
 * plausibilisiert die PVKennziffer anhand des Kennzifferstrings. <br>
 * Es wird geprft:
 * <ul>
 * <li> Kennziffer nicht 8 Zeichen lang (Message=P32)
 * <li> Kennziffer besteht nicht nur aus Ziffern  (Message=P22)
 * </ul>
 * Danch Aufruf von validatePVKennziffer(MessageCollection aMC , int pvKennziffer).
 */
export const validatePVKennziffer = (errors: string[], pvKennziffer: string) => {
  if (pvKennziffer === undefined || pvKennziffer === '') {
    return;
  }
  if (pvKennziffer.length < 8) {
    errors.push(translate('error.dunning.enter-the-input-identifier-as-8-digits'));
    return;
  }
  try {
    const kennzifferInt = parseInt(pvKennziffer, 10);
    validatePVKennzifferNumber(errors, kennzifferInt);
  } catch (error) {
    errors.push(translate('error.dunning.identifier-contains-invalid-characters'));
  }
};

export const validatePVKennzifferNumber = (errors: string[], pvKennziffer: number) => {
  let pvkennzifferB;
  pvkennzifferB = Math.floor(pvKennziffer / 100000);
  pvkennzifferB = Math.floor(pvkennzifferB % 10);
  validateKennziffer(errors, pvKennziffer);
};

export const validateServiceCodeAgainstPVKennziffer = (errors: string[], readerServiceCode: number) => {
  let pvkennzifferB;
  pvkennzifferB = Math.floor(readerServiceCode / 100000);
  pvkennzifferB = Math.floor(pvkennzifferB % 10);

  if (pvkennzifferB < 5 || pvkennzifferB > 7) {
    errors.push(translate('error.dunning.no-valid-reader-service-code-linked-to-pv-code'));
    return;
  }
};

export const checkBundesland = (
  errors: string[],
  currentStateShort: string,
  applicant: any,
  attorneyOfRecord: IAttorneyOfRecord,
  antragstellers: IPersonDetails[],
) => {
  const antragstellerTesten = true;
  if (antragstellerTesten) {
    if (antragstellers.length > 0) {
      let newDunningApplicant = undefined;
      let checken = true;
      for (const dunningApplicant of antragstellers) {
        const currentErroLenght = errors.length;
        checkDunningApplicantBundesland(errors, dunningApplicant, currentStateShort, applicant, attorneyOfRecord, true);
        if (currentErroLenght === errors.length) {
          checken = false;
          break;
        } else {
          newDunningApplicant = dunningApplicant;
        }
      }
      if (checken) {
        checkDunningApplicantBundesland(errors, newDunningApplicant, currentStateShort, applicant, attorneyOfRecord, true);
      }
    }
  }
};

/**
 * plausibilisiert den Antragsteller in Bezug auf das Bundesland. <br>
 * Aufruf aus der Methode checkBundesLAnd() im MBAntragController
 * Es wird geprft:
 * <ul>
 * <li> stimmt das ermittelte AntragstellerBundesland nicht mit dem bergebenen BL berein (Message=AS8) oder (Message=AS14)
 * </ul>
 */
// eslint-disable-next-line complexity
const checkDunningApplicantBundesland = (
  errors: string[],
  dunningApplicant: IPersonDetails,
  currentStateShort: string,
  applicant: any,
  attorneyOfRecord: IAttorneyOfRecord,
  isStreng: boolean,
) => {
  let blAS = '';
  if (
    dunningApplicant.name !== undefined &&
    dunningApplicant.name.length > 0 &&
    dunningApplicant.streetOrHouseNumber !== '' &&
    dunningApplicant.streetOrHouseNumber !== undefined &&
    dunningApplicant.postCode !== '' &&
    dunningApplicant.postCode !== undefined &&
    dunningApplicant.city !== '' &&
    dunningApplicant.city !== undefined
  ) {
    // Prfen, ob der Antragsteller eine Kennziffer hat.
    // Es kann sich auch um einen Rechtsanwalt in eigener Sache handeln,
    // in dem Falle ist die Kennziffer im PV hinterlegt.
    if (
      (dunningApplicant.applicantCode !== '' && dunningApplicant.applicantCode !== undefined) ||
      (applicant === 'own-lawyer' &&
        attorneyOfRecord != null &&
        attorneyOfRecord !== undefined &&
        attorneyOfRecord.readerServiceCode !== undefined &&
        attorneyOfRecord.readerServiceCode !== '')
    ) {
      if (applicant === 'own-lawyer') {
        blAS = attorneyOfRecord.readerServiceCode;
      } else {
        // blAS = dunningApplicant.applicantCode;
      }
    } else if (applicant === 'own-lawyer' && attorneyOfRecord !== null && attorneyOfRecord !== undefined) {
      // Hier handelt es sich also um einen Ra in eigener Sache,
      // der nicht durch eine Kennziffer dargestellt ist.
      if (attorneyOfRecord.foreignCountry !== '' && attorneyOfRecord.foreignCountry !== 'D') {
        blAS = C_OptiMahn.C_Berlin;
      } else {
        blAS = currentStateShort;
      }
    }
  } else {
    if (dunningApplicant.defendantTrialCourtData === undefined || dunningApplicant.defendantTrialCourtData === undefined) {
      return;
    }
    const isDunningApplicantPkA = (dunningApplicant.innerTab as othersType) === othersType.pka;
    if (isDunningApplicantPkA) {
      if (Object.values(dunningApplicant.legalRepresentatives).length > 0) {
        const firstLegalRepresentative = Object.values(dunningApplicant.legalRepresentatives)[0];
        if (
          firstLegalRepresentative.foreignCountry !== undefined &&
          firstLegalRepresentative.foreignCountry !== '' &&
          firstLegalRepresentative.foreignCountry !== 'D'
        ) {
          blAS = C_OptiMahn.C_Berlin;
        } else {
          blAS = lkzToStateMap.get(dunningApplicant.defendantTrialCourtData.LKZ);
        }
      }
    } else {
      if (
        dunningApplicant.foreignCountry !== undefined &&
        dunningApplicant.foreignCountry !== '' &&
        dunningApplicant.foreignCountry !== 'D'
      ) {
        blAS = C_OptiMahn.C_Berlin;
      } else {
        blAS = lkzToStateMap.get(dunningApplicant.defendantTrialCourtData.LKZ);
      }
    }
  }

  if (
    dunningApplicant.foreignCountry !== undefined &&
    dunningApplicant.foreignCountry !== '' &&
    dunningApplicant.foreignCountry !== 'D' &&
    blAS !== ''
  ) {
    // Wenn es einen Antragsteller gibt der eine Zweiniederlassung in der Bezeichnung hat,
    // dann wird statt einer Fehlermeldung (siehe weiter unten) ein Hinweis ausgegeben.
    const isSomeStringInDunningApplicantName = stringsZN.some(str => dunningApplicant.name.some(name => name.includes(str)));
    if ((dunningApplicant.tab as applicantOrDefendantType) === applicantOrDefendantType.company && isSomeStringInDunningApplicantName) {
      errors.push(
        translate(
          'hint.application-identifier-contains-a-reference-to-a-branch-location-the-jurisdiction-of-the-called-court-cannot-be-verified',
        ),
      );
      return;
    }

    if (currentStateShort !== blAS) {
      // Wenn trotz unterschiedlicher Bundeslnder das selbe ZMG zugeordnet wird,
      // dann ist alles in Ordnung, also keine Message (tel. Absprache mit Herrn Selbmann 26.10.2009)
      const zmgBl = stateToLKZ.get(currentStateShort);
      const zmgBlAS = stateToLKZ.get(blAS);

      if (zmgBl !== zmgBlAS) {
        // TODO: THis if needs to be rechecked when companyLegalOption is refactored.
        //   if (hasAGmitAuslandsRFimInland(allDefendants) && !aMBAntrag.isErklaerungAuslaendischeRechtsformAG()) {
        //     //ist Auslndische Rechtsform mit Adresse im Inland und Erklrung zu auslndischer Rechtsform wurde nicht gegeben
        //     // dann: Hinweis: Bitte Begrndung beifgen
        //     errors.push(translate("ALL12"));
        //     return;
        //   }
        const states = getDropdownConfig(DropdownConfigKeys.states);
        const blString = states.find(s => currentStateShort === s.short)?.long;

        // Hier ist dann klar, dass das ausgewhlte Bundesland nicht passt.
        if (isStreng) {
          errors.push(
            translate('error.dunning.applicant-or-lawyer-residence-or-identifier-is-not-valid-for-the-selected-state', {
              blString,
            }),
          );
        } else {
          errors.push(translate('applicant-is-not-in-the-selected-state', { blString }));
        }
      }
    }
  }
};

/**
 * Kontrolliert, ob die Mindestanforderungen an einen MBAntrag erfllt sind. <br>
 * Es wird geprft:
 * <ul>
 * <li> kein Antragsgegner, kein Antragsteller und kein Anspruch (Message=ALL2)
 * <li> kein Antragsteller (Message=ALL3)
 * <li> mehrere Antragsteller trotz RA in eigener Sache (Message=ALL15)
 * <li> mehrere Antragsteller trotz Kennziffer (Message=ALL14)
 * <li> kein Antragsgegner (Message=ALL4)
 * <li> kein Anspruch (Message=ALL5)
 * <li> Summe der Anspruchsbetrge zu hoch (Message=ALL5b)
 * <li> kein Mahngericht (Message=ALL10)
 * </ul>
 */
export const checkMindestAnforderung = (
  errors: string[],
  antragstellers: IPersonDetails[],
  antragsgengers: IPersonDetails[],
  applicant: any,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  dunningCourtData: ICourtData,
) => {
  const asEmpty = antragstellers.length === 0;
  const agEmpty = antragsgengers.length === 0;
  const aspEmpty = isAnspruchEmpty(catalogClaims, otherClaims);

  if (asEmpty && agEmpty && aspEmpty) {
    errors.push(translate('error.dunning.in-a-mb-application-at-least-one-applicant-one-respondent-and-one-claim-must-be-included'));
    return;
  }
  if (asEmpty) {
    errors.push(translate('error.dunning.have-not-yet-created-an-applicant'));
  } else {
    if (antragstellers.length > 1) {
      const antragsteller = antragstellers[0];
      if (isRAInEigenerSache(applicant)) {
        errors.push(translate('error.dunning.if-an-applicant-is-a-lawyer-in-their-own-matter-no-further-applicant-may-be-specified'));
      } else if (antragsteller.applicantCode !== '' && antragsteller.applicantCode !== undefined) {
        errors.push(translate('error.dunning.if-an-applicant-is-specified-by-identifier-no-further-applicant-may-be-specified'));
      }
    }
  }
  if (agEmpty) {
    errors.push(translate('error.dunning.have-not-yet-created-a-respondent'));
  }
  if (aspEmpty) {
    errors.push(translate('error.dunning.have-not-yet-created-a-claim'));
  } else {
    if (getHauptforderungssummeAntrag(catalogClaims, otherClaims) > 99999999.99) {
      errors.push(translate('error.dunning.sum-of-the-claim-amounts-is-too-high'));
    }
  }

  if (dunningCourtData === undefined || dunningCourtData === null) {
    errors.push(translate('error.dunning.no-dunning-court-could-be-determined'));
    return;
  }

  // 13.1.2012: Wenn Auslandsmahnverfahren und AS ist durch Kennziffer vertreten,
  // dann prfen, ob die Kennziffer zum Bundesland passt.
  if (!asEmpty) {
    // TODO: put the method in the below if-statemnet check isAuslandsMV() in the new app.
    // This flag is set if all properties allow a dunning process in a foreign country.
    if (antragstellers.length === 1) {
      const aAS = antragstellers[0];
      if (aAS.applicantCode !== '' && aAS.applicantCode !== undefined) {
        // prfen, ob Kennziffer zum Bundesland passt.
        const asBldKuerzel = lkzToStateMap.get(aAS.applicantCode.substring(0, 2));
        const asMgKuerzel = lkzToStateMap.get(dunningCourtData.LKZ);

        if (asBldKuerzel !== asMgKuerzel) {
          errors.push(translate('error.dunning.applicant-identifier-provided-is-not-known-at-the-dunning-court'));
        }
      }
    }
  }
  if (isNurASWEGVorhanden(antragstellers) && !hasKatNr(catalogClaims, '90')) {
    errors.push(translate('error.dunning.as-a-manager-of-a-condominium-association-you-can-only-claim-service-charges'));
  }
};

/**
 * plausibilisiert den Antragsteller. <br>
 * Es wird geprft:
 * <ul>
 * <li> wenn Altes Verfahren, mehr als erlaubte Anzahl AS (Message=AS11)
 * <li> wenn Antragsteller RA in eigener Sache ist, ist PV gefllt (Message=AS1)
 * <li> wenn Antragsteller RA in eigener Sache ist, ist PV kein Rechtsanwalt (Message=AS2)
 * </ul>
 * Sonst Aufruf von checkAntragstellerPkA(), wenn AS PkA ist, sonst checkAntragsteller(MC, AG)
 * Es wird die Druckfreigabe nur berprft, wenn mitDruckfreigabeCheck = true.
 */

export const checkLawyerOnOwnBehalf = (errors: string[], antragsteller: IPersonDetails, attorneyOfRecord: IAttorneyOfRecord) => {
  if ((antragsteller.innerTab as othersType) !== othersType.ownLawyer) {
    return;
  }
  if (antragsteller.isLawyerOnOwnBehalf === false) {
    errors.push(translate('error.dunning.check-attorney-or-enter-another-applicant'));
    return;
  }

  if (attorneyOfRecord === undefined || isObjectEmpty(attorneyOfRecord)) {
    errors.push(translate('error.dunning.representing-yourself-is-not-possible-no-process-representative-provided'));
  } else if (!isRechtsanwalt(attorneyOfRecord)) {
    errors.push(translate('error.dunning.representing-yourself-is-not-possible-the-process-representative-is-not-a-lawyer'));
  }
};

export const checkAntragsteller = (errors: string[], antragsteller: IPersonDetails, attorneyOfRecord: IAttorneyOfRecord) => {
  checkLawyerOnOwnBehalf(errors, antragsteller, attorneyOfRecord);
  if (antragsteller.isLawyerOnOwnBehalf === false && (antragsteller.innerTab as othersType) !== othersType.ownLawyer) {
    if ((antragsteller.innerTab as othersType) === othersType.pka) {
      checkAntragstellerPkA(errors, antragsteller);
    } else {
      checkAntragstellerAllgemein(errors, antragsteller);
    }
  }
};

/**
 * plausibilisiert den Antragsgegner (Partei kraft Amtes). <br>
 * Bei PkA steht im Antragsgegner das Vertretene Vermgen und in dem GV die<br>
 * restlichen Daten des PkA.<br>
 * Eigentlich wird nur das vertretene Vermgen (PkA) geplaust.<br>
 * Geprft wird, ob das vertretene Vermgen wenigstens ein Zeichen enthlt.<br>
 * Wenn nur im zweiten Eingabefeld ein Text steht, wir dier in das erste bertregen.
 * Also der Name3 wird in Name1 geschoben.
 * Es wird geprft:
 * <ul>
 * <li> ist kein Vertretenens Vermgen angegeben (Message=AS4)
 * </ul>
 *
 */
export const checkAntragstellerPkA = (errors: string[], antragsteller: IPersonDetails) => {
  if (antragsteller.name.length < 1) {
    errors.push(translate('error.dunning.specify-the-managed-assets'));
  }
};

/**
 * plausibilisiert den Antragsteller. <br>
 * Aufruf von checkKennziffer(), wenn Kennziffer gefllt ist,<br>
 * sonst checkPartner(MC, AS) im PartnerController.
 */
export const checkAntragstellerAllgemein = (errors: string[], antragsteller: IPersonDetails) => {
  if (antragsteller.applicantCode !== '' && antragsteller.applicantCode !== undefined) {
    checkKennziffer(errors, antragsteller);
  } else {
    if (antragsteller.name.length > 0 && hasNumberInArray(antragsteller.name)) {
      errors.push(translate('warning.designation-is-invalid-please-remove-all-details-if-a-reference-number-is-to-be-provided'));
    } else {
      //  // TODO: Add this condition below after rebuild the logic for Rechtsform(companyLegalOption).
      // if (antragsteller.getRechtsform() != null && einAntragsteller.getRechtsform().isGebuehrenbefreit()) {
      //   // Hinweis auf mgliche Gebhrenbefreiung
      //   errors.push(translate("AS18"));
      // }
      checkPartner(errors, antragsteller);
    }
  }
};

/**
 * plausibilisiert den Partner. <br>
 * Wenn Partner ein NAtPers, dann Aufruf checkVorname() und checkNachname(), sonst
 * checkName(). Danach checkStr(), checkPLZOrt() und checkNation().<br>
 */
export const checkPartner = (errors: string[], antragsteller: IPersonDetails) => {
  if (antragsteller.salutation === 'Herr' || antragsteller.salutation === ' Frau') {
    checkVorname(errors, antragsteller.name[0]);
    checkNachname(errors, antragsteller.name[1]);
  } else {
    checkName(errors, antragsteller);
  }
  checkStr(errors, antragsteller.streetOrHouseNumber);
};

/**
 * plausibilisiert die Strae des Partners. <br>
 * Es wird geprft:
 * <ul>
 * <li> Straenbezeichnug leer (Message=P1)
 * </ul>
 */
export const checkStr = (errors: string[], streetAndHouseNumber: string) => {
  if (streetAndHouseNumber === undefined) {
    return;
  }
  const laenge = streetAndHouseNumber.trim().length;
  if (laenge < 1) {
    errors.push(translate('error.dunning.enter-street-and-house-number'));
  } else {
    validateStr(errors, streetAndHouseNumber);
  }
};

/**
 * plausibilisiert die Strae des Partners, falls angegeben. <br>
 * Es wird geprft:
 * <ul>
 * <li> Straenbezeichnung enthlt nur 1 Zeichen (Message=P2)
 * <li> Straenbezeichnung enthlt das Wort 'Postfach' (Message=P3)
 * </ul>
 */
export const validateStr = (errors: string[], streetAndHouseNumber: string) => {
  if(streetAndHouseNumber === undefined){
    return;
  }
  const laenge = streetAndHouseNumber.trim().length;
  if (laenge > 0) {
    if (laenge < 2) {
      errors.push(translate('warning.street-name-is-too-short'));
    } else {
      const pos = streetAndHouseNumber.toUpperCase().indexOf('POSTFACH');
      if (pos > -1) {
        errors.push(translate('warning.do-not-enter-p-o-box'));
      }
      validateString(
        errors,
        streetAndHouseNumber.trim(),
        '',
        [],
        'warning.street-contains-invalid-characters',
        'warning.street-contains-invalid-string',
      );
    }
  }
};

/**
 * plausibilisiert den Namen des Partners (bei Firma). <br>
 * Es wird geprft:
 * <ul>
 * <li> weniger als 1 Zeichen bei Firma (Message=P11Fa)
 * <li> weniger als 1 Zeichen bei NatPers (Message=P11)
 * <li> nur 1 Zeichen bei Firma (Message=P12Fa)
 * <li> nur 1 Zeichen bei Firma (Message=P12)
 * </ul>
 * Dann Aufruf von validateName().
 */
export const checkName = (errors: string[], antragsteller: IPersonDetails) => {
  const laenge = antragsteller.name[0].length;
  const antragstellerTab = antragsteller.tab as applicantOrDefendantType;
  if (laenge < 1) {
    if (antragstellerTab === applicantOrDefendantType.company) {
      errors.push(translate('error.dunning.enter-company-name'));
    }
  } else if (laenge < 2) {
    if (antragstellerTab === applicantOrDefendantType.company) {
      errors.push(translate('warning.company-name-too-short'));
    } else {
      errors.push(translate('warning.description-too-short'));
    }
  } else {
    validateName(errors, antragsteller.name);
  }
};

export const validateName = (errors: string[], name: string[]) => {
  if (name.length === 1) {
    validateString(errors, name[0], '', [], 'warning.name-contains-invalid-characters', 'warning.name-contains-invalid-string');
  } else if (name.length === 2) {
    validateString(errors, name[0], '', [], 'warning.name-contains-invalid-characters', 'warning.name-contains-invalid-string');
    validateString(errors, name[1], '', [], 'warning.name-contains-invalid-characters', 'warning.name-contains-invalid-string');
  }

  // TODO: Uncomment the if below after rechtsform is implemented and properly fixed below
  // if (aPartner.isEinzelfirma() && aPartner.isEinzelfirmaMussEK()) {
  //   if (validateStringEK(antragsteller.name[0].trim(), mussStringsEinzelfirma)) {
  //     return;
  //   }
  //   if (validateStringEK(antragsteller.name[1].trim(), mussStringsEinzelfirma)) {
  //     return;
  //   }

  //   errors.push(translate("P34"));
  // }
};

/**
 * plausibilisiert <i>aName</i> auf eingetragener Kaufmann. <br>
 * @param aName String der berprft werden soll.
 * @param ekStrings Stringarray aller ek-Bezeichnungen und Abkrzungen.
 * @return boolean true, wenn ek-String gefunden wurde
 */
export const validateStringEK = (aName: string, ekStrings: string[]) => {
  if (aName.length > 0) {
    for (let i = 0; i < ekStrings.length; i++) {
      if (aName.indexOf(ekStrings[i]) !== -1) {
        return true;
      }
    }
  }

  return false;
};

/**
 * plausibilisiert den Nachnamen des Partners. <br>
 * Es wird geprft:
 * <ul>
 * <li> weniger als 1 Zeichen (Message=P10)
 * <li> nur 1 Zeichen (Message=P14)
 * <li> ungltige Zeichen mittels validateString(aMC,aString,ungueltigeZeichenVorname,ungueltigeStringsVorname,"P16","P16") (Message=P16),<br>
 *       validateStringSZ(aMC,aString) und validateVokale(aMC,aString,"Der Vorname")
 * </ul>
 */
export const checkNachname = (errors: string[], nachname: string) => {
  if(nachname === undefined){
    return;
  }
  const laenge = nachname.length;
  if (laenge < 1) {
    errors.push(translate('error.dunning.enter-last-name'));
  } else if (laenge < 2) {
    errors.push(translate('warning.last-name-too-short'));
  } else {
    validateString(
      errors,
      nachname,
      ungueltigeZeichenNachname,
      ungueltigeStringsNachname,
      'warning.last-name-contains-invalid-characters-or-words',
      'warning.last-name-contains-invalid-characters-or-words',
    );
    validateStringSZ(errors, nachname);
    validateVokale(errors, nachname, 'Der Nachname', 'warning.name-contains-no-vowel');
  }
};

export const checkVorname = (errors: string[], name: string) => {
  if (name === undefined) {
    return;
  }
  const laenge = name.length;
  if (laenge < 1) {
    errors.push(translate('error.dunning.enter-first-name'));
  } else if (laenge < 2) {
    errors.push(translate('warning.first-name-too-short'));
  } else if (name.indexOf('.') !== -1) {
    if (!isAkademischerGrad(name)) {
      errors.push(translate('warning.do-not-abbreviate-first-name'));
    }
  } else {
    validateString(
      errors,
      name,
      ungueltigeZeichenVorname,
      ungueltigeStringsVorname,
      'warning.first-name-contains-invalid-characters-or-words',
      'warning.first-name-contains-invalid-characters-or-words',
    );
    validateStringSZ(errors, name);
    validateVokale(errors, name, 'Der Vorname', 'warning.name-contains-no-vowel');
  }
};

/**
 * plausibilisiert <i>aString</i> auf Vokale in weiterem Sinne(aeiou). <br>
 * Es wird geprft:
 * <ul>
 * <li> nicht mindestens ein Vokal vorhanden (Message=P30)
 * </ul>
 *
 * @param aString String der berprft werden soll.
 * @param feldname Name des Eingabefeldes.
 */
export const validateVokale = (errors: string[], aString: string, feldname: string, errorMessage: string) => {
  aString = aString.toLowerCase();
  if (aString.length > 0) {
    for (let i = 0; i < alleVokale.length; i++) {
      if (aString.indexOf(alleVokale[i]) !== -1) {
        return;
      }
    }

    errors.push(translate(errorMessage, { feldname }));
  }
};

/**
 * plausibilisiert <i>aString</i> auf  am Anfang. <br>
 * Es wird geprft:
 * <ul>
 * <li> beinfdet sich '' am Anfang des Strings (Message=P31)
 * </ul>
 * @param aString String der berprft werden soll.
 */
export const validateStringSZ = (errors: string[], aString: string) => {
  const tokens = aString.split(/[ \t\n\r\f]+/);
  for (const teilString of tokens) {
    if (teilString.length > 0 && teilString.charAt(0) === '') {
      errors.push(translate('warning.ss-cannot-be-first-character-in-name', { teilString }));
    }
  }
};

/**
 * plausibilisiert <i>aString</i> auf gltige Zeichen. <br>
 * @param aString String der berprft werden soll.
 * @param ungZeichen String aller ungltigen Zeichen.
 * @param ungStrings Stringarray aller ungltigen Strings.
 * @param f1Message Name der Fehlermeldung bei ungltigem Character.
 * @param f2Message Name der Fehlermeldung bei ungltigem String.
 */
export const validateString = (
  errors: string[],
  aString: string,
  ungZeichen: string,
  ungStrings: string[],
  f1Message: string,
  f2Message: string,
) => {
  aString = aString.toLowerCase();
  if (aString.length > 0) {
    const zeichen = aString.split('');
    for (let i = 0; i < zeichen.length; i++) {
      // Da alle Versandarten jetzt im EDA-Format sind, drfen auch nur
      // diese Zeichen gltig sein:
      if (gueltigeZeichen.indexOf(zeichen[i]) === -1) {
        errors.push(translate(f1Message, { text: zeichen[i] }));
        return;
      }
      if (ungZeichen.indexOf(zeichen[i]) !== -1) {
        errors.push(translate(f1Message, { text: zeichen[i] }));
        return;
      }
    }

    for (let i = 0; i < ungStrings.length; i++) {
      if (aString.indexOf(ungStrings[i]) !== -1) {
        errors.push(translate(f2Message, { text: ungStrings[i] }));
        return;
      }
    }
  }
};

/**
 * prft ob der String einen akademischen Grad enthlt,
 * z.B.: Dr., Prof., Prof. Dr. , Mag., Dipl.-Ing., Dipl.-Vw., Dipl.-Inf.
 */
export const isAkademischerGrad = (aString: string) => {
  if (akadGradeStrings.includes(aString)) {
    return true;
  }
  return false;
};

export const checkKennziffer = (errors: string[], einAntragsteller: IPersonDetails) => {
  if ((einAntragsteller.applicantCode === undefined || einAntragsteller.applicantCode === '') && !isDatenEmpty(einAntragsteller)) {
    errors.push(translate('error.dunning.provide-either-identifier-or-applicant-data'));
    return;
  }

  if (einAntragsteller.applicantCode !== undefined && einAntragsteller.applicantCode !== '') {
    const size = errors.length;
    validateASKennziffer(errors, einAntragsteller.applicantCode);
    if (size !== errors.length) {
      return;
    }
    // Prfung, ob aus Kennziffer auch Bundesland ermittelt werden kann
    let bld = '';
    const applicantCodeFirstTwoDigits = einAntragsteller.applicantCode.substring(0, 2);
    bld = lkzToStateMap.get(applicantCodeFirstTwoDigits);
    if (bld === undefined || bld === '') {
      errors.push(translate('error.dunning.entered-identifier-is-invalid'));
      return;
    }
  } else {
    errors.push(translate('error.dunning.provide-a-valid-identifier'));
  }
};

/**
 * plausibilisiert die ASKennziffer anhand des Kennzifferstrings. <br>
 * Danch Aufruf von validateASKennziffer(MessageCollection aMC , int asKennziffer).
 */
export const validateASKennziffer = (errors: string[], asKennziffer: string): string[] => {
  if (asKennziffer === undefined || asKennziffer === '') {
    return;
  }
  if (asKennziffer.length < 8) {
    errors.push(translate('error.dunning.enter-the-input-identifier-as-8-digits'));
    return;
  }

  try {
    const kennzifferInt = parseInt(asKennziffer, 10);
    validateASKennzifferNumber(errors, kennzifferInt);
  } catch (error) {
    errors.push(translate('error.dunning.identifier-contains-invalid-characters'));
  }
};
/**
 * plausibilisiert die ASKennziffer anhand des Kennzifferints. <br>
 * Es wird geprft:
 * <ul>
 * <li> AntragstellerKennziffer nicht gltig, also 3. Stelle 5, 6 oder 7 (Message=P23)
 * <li> AntragstellerKennziffer nur fr EDA zulssig, also 3. Stelle > 4 (Message=P37)
 * </ul>
 * Ruft dann validateKennziffer(MessageCollection aMC , int asKennziffer) auf.
 */
export const validateASKennzifferNumber = (errors: string[], asKennziffer: number) => {
  let asKennzifferB;
  asKennzifferB = Math.floor(asKennziffer / 100000);
  asKennzifferB = Math.floor(asKennzifferB % 10);
  if (asKennzifferB > 4 && asKennzifferB < 8) {
    errors.push(translate('error.dunning.no-valid-applicant-identifier'));
    return;
  }

  validateKennziffer(errors, asKennziffer);
};

export const validateKennziffer = (errors: string[], kennziffer: number) => {
  // Gleiche Prfung auch in Zeile 342
  const blkennziffer = Math.floor(kennziffer / 1000000);
  switch (blkennziffer) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 11:
    case 23:
      // Gltiges Bundesland
      break;

    default:
      // Ungltiges Bundesland
      errors.push(translate('error.dunning.entered-identifier-is-invalid'));
      return;
  }

  // Die Prfziffer (letzte Ziffer) ermitteln
  const pruefzifferOriginal = Math.floor(kennziffer % 10);
  // Die Prfziffer (letzte Ziffer) abschneiden
  kennziffer = Math.floor(kennziffer / 10);
  const pruefziffer = errechnePruefziffer(kennziffer);
  // Ermittelte Prfziffer mit tatschlicher Prfziffer vergleichen
  if (pruefziffer !== pruefzifferOriginal) {
    errors.push(translate('error.dunning.entered-identifier-is-invalid'));
  }
};

/**
 * errechnet die Pruefziffer. <br>
 * (Formel bei Peter erfragen, 06.04.2001)
 */
export const errechnePruefziffer = (kennziffer: number) => {
  let qsSumme = 0;
  for (let i = 1; i < 7; i++) {
    // Die letzte Ziffer ermitteln
    const ziffer = Math.floor(kennziffer % 10);
    // Die Position (von hinten) addieren
    // davon gilt nur die hintere Ziffer
    let summe = i + ziffer;
    summe = Math.floor(summe % 10);
    // Mit 2 multiplizieren (left-shift)
    const produkt = summe << i; // eslint-disable-line no-bitwise
    // Solange die Quersumme ermitteln bis eine einstellige Zahl brigbleibt
    // Diese dann aufsummieren
    qsSumme = qsSumme + quersummenQuersumme(produkt);
    // Die letzte Ziffer abschneiden
    kennziffer = Math.floor(kennziffer / 10);
  }

  // 10 minus die letzte Ziffer der Aufsummierung
  let pruefziffer = 10 - Math.floor(qsSumme % 10);
  // Wenn Prfziffer 2 stellig, dann Prfziffer = 0
  if (pruefziffer === 10) pruefziffer = 0;

  return pruefziffer;
};

export const quersummenQuersumme = (quersumme: number) => {
  while (quersumme > 9) {
    quersumme = querSumme(quersumme);
  }
  return quersumme;
};

export const querSumme = (zahl: number) => {
  // Zu Zahl wird die Quersumme berechnet

  let quersumme = 0;
  while (zahl > 0) {
    quersumme = quersumme + Math.floor(zahl % 10);
    zahl = Math.floor(zahl / 10);
  }
  return quersumme;
};

export const isDatenEmpty = (antragsteller: IPersonDetails) => {
  if (antragsteller.city !== '' && antragsteller.city !== undefined) return false;
  if (antragsteller.postCode !== '' && antragsteller.postCode !== undefined) return false;
  return true;
};

export const isDatenEmptyProzessVertrter = (attorneyOfRecord: IAttorneyOfRecord) => {
  if (attorneyOfRecord.city !== '' && attorneyOfRecord.city !== undefined) return false;
  if (attorneyOfRecord.postCode !== '' && attorneyOfRecord.postCode !== undefined) return false;
  if (attorneyOfRecord.name !== undefined && attorneyOfRecord.name.length <= 0) return false;
  return true;
};

export const hasNumberInArray = (arr: string[]): boolean => {
  return arr.some(str => !isNaN(parseFloat(str)));
};

export const isRechtsanwalt = (attorneyOfRecord: IAttorneyOfRecord) => {
  if (!isObjectEmpty(attorneyOfRecord)) {
    if (attorneyOfRecord.readerServiceCode !== '' && attorneyOfRecord.readerServiceCode !== undefined) {
      let pvkennzifferB = Math.floor(parseInt(attorneyOfRecord.readerServiceCode, 10) / 100000);
      pvkennzifferB = Math.floor(pvkennzifferB % 10);

      if (pvkennzifferB < 5 || pvkennzifferB > 7) {
        return false;
      }
      return true;
    }

    if (isTitelMM_RA_RB(attorneyOfRecord.descriptionOrLegalOption)) {
      return true;
    }
    if ((attorneyOfRecord.type as tabType) === tabType.lawyerCompany) {
      return true;
    }
  }
  return false;
};

export const isTitelMM_RA_RB = (description: string) => {
  if (optionsArray.includes(description)) {
    return true;
  }
  return false;
};
/**
 * Prft ob nur ein Antragsteller vorhanden ist und dieser
 * ein WEG-Verwalter ist.
 */
export const isNurASWEGVorhanden = (antragstellers: IPersonDetails[]) => {
  if (antragstellers.length === 1 && (antragstellers[0].innerTab as othersType) === othersType.weg) {
    return true;
  }
  return false;
};

export const hasAGImAusland = (antragsgengers: IPersonDetails[]) => {
  for (const antragsgenger of antragsgengers) {
    if (isInDeutschland(antragsgenger)) {
      return true;
    }
  }
  return false;
};

export const isInDeutschland = (object: IPersonDetails | ILegalRepresentative | IAttorneyOfRecord) => {
  if (object.foreignCountry === undefined || object.foreignCountry === '' || object.foreignCountry === 'D') {
    return true;
  }
  return false;
};

export const isAnspruchEmpty = (catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  if (catalogClaims.length > 0) return false;
  if (otherClaims.length > 0) return false;
  return true;
};

export const isForeignCountryInDeutschland = (foreignCountry: string) => {
  if (foreignCountry === undefined || foreignCountry === null || foreignCountry === 'D') {
    return true;
  }
  return false;
};

export const isRAInEigenerSache = (applicant: any) => {
  if (applicant === 'own-lawyer') {
    return true;
  }
  return false;
};

export const getHauptforderungssummeAntrag = (catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  if (hasKatNr(catalogClaims, '30')) {
    if (hasKatNr(catalogClaims, '31') || hasKatNr(catalogClaims, '32')) {
      const catalogClaimNumberArray = ['31', '32'];
      return getHauptforderungssummeAusserKatNrn(catalogClaims, otherClaims, catalogClaimNumberArray);
    }
  }

  if (hasKatNr(catalogClaims, '50')) {
    if (!hasOnlyKatNr('50', otherClaims, catalogClaims)) {
      return getHauptforderungssummeAusserKatNr('50', catalogClaims, otherClaims);
    }
  }

  return getHauptforderungssumme(catalogClaims, otherClaims);
};

export const getHauptforderungssummeAusserKatNr = (aKatNr: string, catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  let amount = getHauptforderungssumme(catalogClaims, otherClaims);

  if (catalogClaims !== undefined && catalogClaims.length > 0) {
    for (const catalogClaim of catalogClaims) {
      if (catalogClaim.catNr === aKatNr) {
        amount -= catalogClaim.amount;
      }
    }
  }

  return amount;
};

/**
 * prft, ob ein Antrag nur Ansprche mit einer bestimmten KatNr enthlt.
 * Ist eine sonstiger Anspruch vorhanden, wird auch false zurckgegeben.
 * Sind keine Ansprche vorhanden, wird false zurckgegeben.
 */
export const hasOnlyKatNr = (aKatNr: string, otherClaims: IOtherClaim[], catalogClaims: ICatalogClaim[]) => {
  if (otherClaims !== undefined && otherClaims.length > 0) {
    return false;
  }

  if (catalogClaims !== undefined && catalogClaims.length > 0) {
    for (const catalogClaim of catalogClaims) {
      if (catalogClaim.catNr !== aKatNr) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const hasKatNr = (catalogClaims: ICatalogClaim[], aKatNr: string) => {
  if (catalogClaims !== undefined && catalogClaims.length > 0) {
    let i = 0;
    for (const catalogClaim of catalogClaims) {
      if (i !== -1 && catalogClaim.catNr === aKatNr) {
        return true;
      }
      i++;
    }
  }
  return false;
};

export const getHauptforderungssummeAusserKatNrn = (catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[], katNrn: string[]) => {
  let amount = getHauptforderungssumme(catalogClaims, otherClaims);
  if (catalogClaims !== undefined && catalogClaims.length > 0) {
    for (const catalogClaim of catalogClaims) {
      for (let i = 0; i < katNrn.length; i++) {
        if ((catalogClaim.catNr = katNrn[i])) {
          amount -= catalogClaim.amount;
        }
      }
    }
  }

  return amount;
};

export const getHauptforderungssumme = (catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  let amount = 0;

  if (catalogClaims !== undefined && catalogClaims.length > 0) {
    for (const catalogClaim of catalogClaims) {
      amount += catalogClaim.amount;
    }
  }

  if (otherClaims !== undefined && otherClaims.length > 0) {
    for (const otherClaim of otherClaims) {
      amount += otherClaim.amount;
    }
  }

  return amount;
};

// export const  isForeignCountry = () => {
//     if(this.getNation().equals("D")) {
//         return false;
//     }
//     return true;
// }

export const hasAGmitAuslandsRFimInland = (defendants: IPersonDetails[]) => {
  for (const defendant of defendants) {
    if (isForeignCountryInDeutschland(defendant.foreignCountry) && defendant.companyLegalOption !== undefined) {
      //  einAG.getRechtsform().isAuslandsRF()) { // TODO: Add this condition to the if, but first rebuild the logci for Rechtsform(companyLegalOption).
      return true;
    }
  }
  return false;
};
// TODO: This if needs to be rechecked when companyLegalOption is refactored.
export const isErklaerungAuslaendischeRechtsformAG = () => {
  return true;
};

/**
 * plausibilisiert die Allgemeinen Angaben. <br>
 * Es wird geprft:
 * <ul>
 * <li> kein Gegenleistungsmerkmal angegeben  (Message=ALL1)
 * <li> nur ein Anspruch, aber beide Vor-Gegenliestung-Merkmale gefllt (Message=ALL1b)
 * </ul>
 */
export const checkAllgemeineAngaben = (errors: string[], allgemeinAngaben: IApplicationGeneralInformation, claims: CombinedClaimType[]) => {
  if (allgemeinAngaben.considerationDone === false && allgemeinAngaben.considerationNotRelated === false) {
    errors.push(translate('error.not-chosen-consideration'));
  }

  if (claims.length < 2 && allgemeinAngaben.considerationDone === true && allgemeinAngaben.considerationNotRelated === true) {
    errors.push(translate('error.dunning.contradictory-claims-declared'));
  }

  if (
    allgemeinAngaben.freeFromCosts !== '' &&
    allgemeinAngaben.freeFromCosts !== undefined &&
    allgemeinAngaben.legalAid !== '' &&
    allgemeinAngaben.legalAid !== undefined
  ) {
    errors.push(translate('error.dunning.cost-fee-exemption-and-legal-aid-are-mutually-exclusive'));
  }
};

/**
 * plausibilisiert das VertragsDatum. <br>
 * Es wird geprft:
 * <ul>
 * <li> Datum ist dirty (Message=VKG1)
 * <li> Datum ist nicht innerhalb von 1970 und 2069 (Message=VKG8)
 * </ul>
 */
export const checkVertragsDatumKann = (errors: string[], einVerbrKreditGesetz: boolean, theAnspruch: CombinedClaimType) => {
  if (
    theAnspruch.additionalInformation.contractDate !== undefined &&
    theAnspruch.additionalInformation.contractDate !== '' &&
    !ValidationUtils.isYearInRange(theAnspruch.additionalInformation.contractDate, 1970, 2069)
  ) {
    errors.push(translate('error.dunning.contract-date-is-not-valid'));
  }
};

/**
 * plausibilisiert das VertragsDatum, wenn es angegeben sein mu. <br>
 * Es wird geprft:
 * <ul>
 * <li> Datum ist nicht angegeben (Message=VKG2)
 * </ul>
 */
export const checkVertragsDatumMuss = (errors: string[], einVerbrKreditGesetz: boolean, theAnspruch: CombinedClaimType) => {
  if (theAnspruch.additionalInformation.contractDate === undefined || theAnspruch.additionalInformation.contractDate === '') {
    errors.push(translate('error.dunning.enter-a-contract-date'));
  }
  checkVertragsDatumKann(errors, einVerbrKreditGesetz, theAnspruch);
};

/**
 * plausibilisiert das VertragsDatum auf Unter- und Obergrenze. <br>
 * Es wird geprft:
 * <ul>
 * <li> Datum ist liegt in der Zukunft (Message=VKG3)
 * <li> Datum ist liegt vor dem 01.01.1992 (Message=VKG4)
 * </ul>
 */
export const checkDatumUnterUndObergrenze = (errors: string[], einVerbrKreditGesetz: boolean, theAnspruch: CombinedClaimType) => {
  const contractDate = theAnspruch.additionalInformation?.contractDate;
  if (contractDate !== undefined && contractDate !== '') {
    // Datum beginnt in der Zukunft
    if (ValidationUtils.isDateInFuture(contractDate)) {
      errors.push(translate('error.dunning.contract-date-is-in-the-future'));
    }
    // Datum liegt vor dem 1.1.1991
    if (new Date(contractDate) < new Date('01.01.1991')) {
      errors.push(translate('error.dunning.contract-date-is-too-far-in-the-past'));
    }
  }
};

/**
 * Liefert den Zinssatz als Float fr ein Datum
 *
 * @param fe dieses Datum soll Zinssatz geliefert werden
 * @return Float
 */
export const getZinssatzFuerDatum = (aDatum: Date) => {
  const aStartDatum = new Date('01.01.1901');
  for (const [dateString, rate] of Object.entries(basisZinsData)) {
    const date = new Date(dateString);
    if (date >= aStartDatum) {
      if (date <= aDatum) {
        return rate;
      } else {
        break;
      }
    }
  }
  return null;
};

/**
 * plausibilisiert den Zinssatz, wenn er nicht zwingend angegeben werden mu. <br>
 * Es wird geprft:
 * <ul>
 * <li> Zinssatz ist dirty (Message=Z1)
 * <li> Zinssatz ist grer als 12% + BasisZinssatz (Message=VKG6)
 * <li> Zinssatz ist grer als 999999 (Message=Z3)
 * <li> Zinssatz ist kleiner 0 (Message=Z4)
 * </ul>
 */
export const checkVertragsZinssatzKann = (errors: string[], theAnspruch: ICatalogClaim) => {
  const aZinsen = theAnspruch.additionalInformation.effectiveAnnualInterestRate;
  const aDatum = new Date(theAnspruch.additionalInformation.contractDate);
  const katNr = theAnspruch.catNr;
  if (aZinsen === undefined || aZinsen === 0) return;
  // Plausi: Zinssatz nicht hher als BasisZinssatz + 5%
  const basis = getZinssatzFuerDatum(aDatum);
  const zins = aZinsen;
  const zwoelf = 12 + basis;
  if (katNr !== '36' && zwoelf < zins) {
    errors.push(translate('error.dunning.specified-interest-rate-exceeds-the-benchmark-rate'));
  }
  if (aZinsen > 99999999.99) {
    errors.push(translate('error.dunning.interest-rate-is-too-high'));
  }
  if (aZinsen < 0) {
    errors.push(translate('error.dunning.interest-rate-is-too-low'));
  }
};

/**
 * plausibilisiert den Zinssatz. <br>
 * Es wird geprft:
 * <ul>
 * <li> Zinssatz ist dirty (Message=Z1)
 * <li> Zinssatz ist null, also leer (Message=Z2)
 * <li> Zinssatz ist grer als 999999 (Message=Z3)
 * <li> Zinssatz ist kleiner 0 (Message=Z4)
 * <li> Zinssatz ist grer als 12% + BasisZinssatz (Message=VKG6)
 * </ul>
 */
export const checkVertragsZinssatz = (errors: string[], theAnspruch: CombinedClaimType) => {
  const aZinsen = theAnspruch.additionalInformation?.effectiveAnnualInterestRate;
  const aDatum = new Date(theAnspruch.additionalInformation?.contractDate);
  if (aZinsen === undefined || aZinsen === 0) {
    errors.push(translate('error.dunning.enter-an-interest-rate'));
  } else {
    if (aZinsen > 99999999.99) {
      errors.push(translate('error.dunning.interest-rate-is-too-high'));
    }
    if (aZinsen < 0.0) {
      errors.push(translate('error.dunning.interest-rate-is-too-low'));
    }
    // Plausi: Zinssatz nicht hher als BasisZinssatz + 12%
    const basis = getZinssatzFuerDatum(aDatum);
    const zins = aZinsen;
    const zwoelf = 12 + basis;
    if (zwoelf < zins) {
      errors.push(translate('error.dunning.specified-interest-rate-exceeds-the-basis-rate'));
    }
  }
};

const isICatalogClaim = (claim: CombinedClaimType): claim is ICatalogClaim => {
  // Check for properties specific to ICatalogClaim
  return (claim as ICatalogClaim).id !== undefined;
  // Replace 'someUniqueProperty' with a real property unique to ICatalogClaim
};

/**
 * plausibilisiert den VerbrKreditGesetz. <br>
 * Wenn fr einen KatAnspruch und KatNr = 15, dann Aufruf von checkVertragsDatumMuss(),   checkDatumUnterUndObergrenze() und checkVertragsZinssatzKann().<br>
 * Wenn fr einen KatAnspruch und KatNr = 36, dann Aufruf von checkVertragsDatumKann() und checkVertragsZinssatzKann().<br>
 * Sonst Aufruf von checkVertragsDatumMuss(), checkDatumUnterUndObergrenze() und checkVertragsZinssatz().
 */
export const checkVerbrKreditGesetz = (errors: string[], einVerbrKreditGesetz: boolean, theAnspruch: CombinedClaimType) => {
  if (einVerbrKreditGesetz === undefined) return;
  if (isICatalogClaim(theAnspruch)) {
    const aspNr = theAnspruch.catNr;
    if (aspNr === '15') {
      checkVertragsDatumMuss(errors, einVerbrKreditGesetz, theAnspruch);
      checkDatumUnterUndObergrenze(errors, einVerbrKreditGesetz, theAnspruch);
      checkVertragsZinssatzKann(errors, theAnspruch);
      return;
    }
    if (aspNr === '36') {
      checkVertragsDatumKann(errors, einVerbrKreditGesetz, theAnspruch);
      checkVertragsZinssatzKann(errors, theAnspruch);
      return;
    }
  }
  checkVertragsDatumMuss(errors, einVerbrKreditGesetz, theAnspruch);
  const size = errors.length;
  checkDatumUnterUndObergrenze(errors, einVerbrKreditGesetz, theAnspruch);
  if (size !== errors.length) return;
  checkVertragsZinssatz(errors, theAnspruch);
};

export const getZinsenAnzahl = (claims: CombinedClaimType[], catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  let anzahl = 0;
  for (const einAnspruch of claims) {
    anzahl = anzahl + (einAnspruch.runningInterest !== undefined ? Object.values(einAnspruch.runningInterest).length : 0);
  }
  if (getAusgerechneteZinsen(catalogClaims, otherClaims) !== undefined) anzahl++;
  return anzahl;
};

export const getAusgerechneteZinsen = (catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  let zinsArt = undefined;
  // hier fr die ausgerechneten Zinsen die korrekte Zinsart setzen:
  if (hasKatNr(catalogClaims, '83')) {
    if (hasOnlyKatNr('83', otherClaims, catalogClaims)) {
      zinsArt = 1;
    } else {
      zinsArt = 2; // Ausgerechnete Zinsen und Sumniszuschlge
    }
  } else {
    zinsArt = 0; // Ausgerechnete Zinsen
  }

  return zinsArt;
};

/**
 * plausibilisiert die Anzahl der Zinszeilen (ab 01.12.2008 keine Prfung mehr). <br>
 * Es wird geprft:
 * <ul>
 * <li> Ausgerechnete Zinsen und  es werden mehr als 15 Zinsen beantragt (Message=ANSPR37)
 * <li> Laufende Zinsen und  es werden mehr als 15 Zinsen beantragt (Message=ANSPR36)
 * </ul>
 */
export const checkAnzahlZinsen = (
  errors: string[],
  indexZ: number,
  claims: CombinedClaimType[],
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  calcInterest: ICalculatedInterest,
) => {
  let theIndexZ = -1;
  if (null !== indexZ) {
    theIndexZ = indexZ;
  }
  let anzahl = getZinsenAnzahl(claims, catalogClaims, otherClaims);
  if ((calcInterest !== undefined && getAusgerechneteZinsen(catalogClaims, otherClaims) === undefined) || theIndexZ === -1) {
    anzahl++;
  }

  if (anzahl > 15) {
    if (calcInterest !== undefined) {
      errors.push(
        translate(
          'error.dunning.only-a-maximum-of-15-interest-rates-can-be-created-in-an-application-please-delete-one-interest-entry-first',
        ),
      );
    } else {
      errors.push(translate('error.dunning.only-a-maximum-of-15-interest-rates-can-be-created-in-an-application'));
    }
  }
};

export const isAusgerechnet = (theAnspruch: CombinedClaimType) => {
  if (theAnspruch.type === 'calculatedInterest') {
    return true;
  }
  return false;
};

/**
 * plausibilisiert das Datum fr die ausgerechneten Zinsen. <br>
 * Es wird geprft:
 * <ul>
 * <li> VonDatum ist leer (Message=Z5)
 * <li> VonDatum ist dirty (Message=Z25)
 * <li> VonDatum ist nicht innerhalb von 1970 und 2069 (Message=Z27)
 * <li> BisDatum ist leer (Message=Z6)
 * <li> BisDatum ist dirty (Message=Z26)
 * <li> BisDatum ist nicht innerhalb von 1970 und 2069 (Message=Z28)
 * <li> Von- und BisDatum sind leer (Message=Z6)
 * <li> VonDatum ist kleiner als BisDatum (Message=Z8)
 * <li> VonDatum ist gleich dem BisDatum (Message=Z9)
 * </ul>
 */
export const checkDatumAusgerechneteZinsen = (errors: string[], aZinsen: ICalculatedInterest) => {
  const von = aZinsen.from;
  const bis = aZinsen.to;
  if (ValidationUtils.isDateValid(von) && !ValidationUtils.isYearInRange(von, 1970, 2069)) {
    errors.push(translate('error.dunning.from-date-is-invalid-not-in-range-1970-to-2069'));
  }

  if (ValidationUtils.isDateValid(bis) && !ValidationUtils.isYearInRange(bis, 1970, 2069)) {
    errors.push(translate('error.dunning.to-date-is-invalid-not-in-range-1970-to-2069'));
  }

  if (ValidationUtils.isDateValid(bis) && ValidationUtils.isDateValid(von)) {
    const vonDate = new Date(aZinsen.from);
    const bisDate = new Date(aZinsen.to);
    if (vonDate > bisDate) {
      errors.push(translate('error.dunning.from-date-is-before-the-to-date'));
    }
    if (von === bis) {
      errors.push(translate('error.dunning.from-date-and-to-date-are-equal'));
    }
  }
};

/**
 * plausibilisiert das Datum allgemein (Datums-Ober- und Untergrenze). <br>
 * Aufruf von checkDatumZukunftVon(aMC, aZinsen) und checkDatumZukunftBis(aMC, aZinsen).
 */
export const checkDatumZukunft = (errors: string[], aZinsen: IRunningInterest) => {
  checkDatumZukunftWihtMessage(errors, aZinsen.fromBy, 'error.dunning.from-date-is-in-the-future');
  checkDatumZukunftWihtMessage(errors, aZinsen.to, 'error.dunning.to-date-is-in-the-future');
};

/**
 * plausibilisiert ob das vonDatum von<b>aZinsen</b> in der Zukunft liegt. <br>
 * Es wird geprft:
 * <ul>
 * <li> vonDatum des Zins liegt in der Zukunft (Message=Z17)
 * </ul>
 */
export const checkDatumZukunftWihtMessage = (errors: string[], date: string, errorMessage: string) => {
  if (date !== undefined && date !== '') {
    if (ValidationUtils.isDateInFuture(date)) {
      errors.push(translate(errorMessage));
    }
  }
};

/**
 * prft, ob sich 2 Zeitrume berschneiden.
 * Alle 4 Datmer mssen nicht null sein, sonst wird false zurckgeliefert.
 * Es mssen mindestens 3 gltige (!isEmptyOrDirty()) Datmer bergeben werden,
 * ansonsten wird false zurckgeliefert.
 *
 * @param aVon - gltiges Datum
 * @param aBis - gltiges Datum
 * @param bVon - gltiges Datum
 * @param bBis - gltiges Datum
 * @return boolean - true wenn sich die Zeitrume berschneiden
 */
export const zeitraumUeberschneidung = (aVon: string, aBis: string, bVon: string, bBis: string) => {
  if (aVon === undefined || aBis === undefined || bVon === undefined || bBis === undefined) {
    return false;
  }

  if (aVon <= bBis && aVon >= bVon) {
    return true;
  }
  if (aBis <= bBis && aBis >= bVon) {
    return true;
  }
  if (bVon <= aBis && bVon >= aVon) {
    return true;
  }
  if (bBis <= aBis && bBis >= aVon) {
    return true;
  }

  return false;
};

/**
 * Diese Plausi geht durch alle Zinsen eines Anspruchs und kontrolliert auf berschneidungen. <br>
 * Es wird geprft:
 * <ul>
 * <li> Zeitraum der Laufende Zinsen berschneidet sich mit dem Zinszeitraum der Ausgerechneten Zinsen (Message=Z15)
 * <li> Zeitraum der Ausgerechneten Zinsen berschneidet sich mit dem Zinszeitraum der Laufenden Zinsen (Message=Z16)
 * <li> mehr als ein Laufenden Zins ohne bisDatum (Message=Z14)
 * <li> die Summe der Teilbetrge aller Verzinsungen eines Zeitraums berschreitet den Betrag des Anspruchs (Message=Z12)
 * </ul>
 */

const isRunningInterest = (zinsen: any): zinsen is IRunningInterest => {
  return 'fromBy' in zinsen && 'amountSubjectToInterest' in zinsen;
};

export const plausiUeberschneidung = (
  errors: string[],
  theAnspruch: CombinedClaimType,
  aZinsen: ICalculatedInterest | IRunningInterest,
  vonZins: string,
  bisZins: string,
  theIndexZ: number,
) => {
  // Leeres von-Datum = Zinsen ab Zustellung (also frhestens morgen)
  // dient nur der berpfung der Datumsberschneidung
  let vonZinsDat = undefined;
  if (vonZins !== undefined && vonZins !== '') {
    vonZinsDat = new Date();
    vonZinsDat.addDays(1);
  } else {
    vonZinsDat = vonZins;
  }

  // Leeres bis-Datum = Zinsen bis unendlich (also zB bermorgen)
  // dient nur der berpfung der Datumsberschneidung
  let bisZinsDat = undefined;
  if (bisZins !== undefined && bisZins !== '') {
    bisZinsDat = new Date('31.12.9999');
  } else {
    bisZinsDat = bisZins;
  }

  const aList = Object.values(theAnspruch.runningInterest);
  let betrag = theAnspruch.amount;
  let tmpVon = undefined;
  let tmpBis = undefined;
  let ausgerechnetVon = undefined;
  let ausgerechnetBis = undefined;
  if (aZinsen !== undefined) {
    ausgerechnetVon = isRunningInterest(aZinsen) ? aZinsen.fromBy : aZinsen.from;
    ausgerechnetBis = aZinsen.to;
  } else {
    ausgerechnetVon = null;
    ausgerechnetBis = null;
  }

  // Laufende Zinsen
  if (!isAusgerechnet(theAnspruch)) {
    if (zeitraumUeberschneidung(vonZinsDat, bisZinsDat, ausgerechnetVon, ausgerechnetBis)) {
      errors.push(translate('error.dunning.interest-period-overlaps-with-the-calculated-interest-period'));
      return;
    }
  }
  const getAmount = (zinsen: ICalculatedInterest | IRunningInterest) => {
    return isRunningInterest(zinsen) ? zinsen.amountSubjectToInterest : zinsen.amount;
  };

  if (getAmount(aZinsen) !== undefined && getAmount(aZinsen) !== 0) {
    betrag += getAmount(aZinsen);
  } else {
    betrag = +theAnspruch.amount;
  }
  let j = 0;
  for (const aZins of aList) {
    tmpVon = aZins.fromBy;
    if (tmpVon.isEmpty()) {
      tmpVon = new Date();
      tmpVon.addDays(1);
    }
    tmpBis = aZins.to;
    if (tmpBis.isEmpty()) {
      tmpBis = new Date('31.12.9999');
    }

    if (theIndexZ !== j) {
      // berschneidung Zinsdaten
      if (zeitraumUeberschneidung(vonZinsDat, bisZinsDat, tmpVon, tmpBis)) {
        // Na dann los mit dem Vergeleichen! --> Betrge summieren
        if (getAmount(aZinsen) !== undefined && getAmount(aZinsen) !== 0) {
          betrag += aZins.amountSubjectToInterest;
        } else {
          betrag += theAnspruch.amount;
        }
      }
    }
    j++;
  }
  if (!isAusgerechnet(theAnspruch) && betrag > theAnspruch.amount + 0.001) {
    errors.push(translate('error.dunning.sum-of-the-partial-amounts-of-all-interest-amounts-exceeds-the-claim-amount'));
  }
};

/**
 * plausibilisiert das Datum fr die ausgerechneten Zinsen (erweitert). <br>
 * berprft Datums- und Betrags-berschneidungen fr alle Ansprche mittels plausiUeberschneidung(aMC, theAntrag, theAnspruch, aZinsen, vonZins, bisZins, theIndexZ) <br>
 * und liegtInEinemAnspruchZeitraum(theAnspruchListe, vonZins, bisZins).
 */
export const checkDatumAusgerechneteZinsenErweitert = (
  errors: string[],
  aZinsen: ICalculatedInterest,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
) => {
  const theIndexZ = -1;
  let size = 0;
  const vonZins = aZinsen.from;
  const bisZins = aZinsen.to;
  let theAnspruch = undefined;

  if (catalogClaims.length > 0) {
    for (const aKat of catalogClaims) {
      theAnspruch = aKat;
      size = errors.length;
      plausiUeberschneidung(errors, theAnspruch, aZinsen, vonZins, bisZins, theIndexZ);
      if (errors.length !== size) return;
      size = errors.length;
    }
  }
  if (otherClaims.length > 0) {
    for (const aSonst of otherClaims) {
      theAnspruch = aSonst;
      plausiUeberschneidung(errors, theAnspruch, aZinsen, vonZins, bisZins, theIndexZ);
    }
  }
};

/**
 * plausibilisiert den Zinssatz, wenn VonDatum eingegeben wurde. <br>
 * Es wird geprft:
 * <ul>
 * <li> Zinssatz ist dirty (Message=Z1)
 * <li> Zinssatz ist grer als 99,999 (Message=Z3)
 * <li> Zinssatz ist kleiner als 0 (Message=Z4)
 * <li> JahresZinssatz grer als 14,4 (Message=Z30)
 * </ul>
 */
export const checkZinssatzAusgerechneteZinsen = (errors: string[], aZinsen: ICalculatedInterest) => {
  if (aZinsen.annualInterestRate !== undefined && aZinsen.annualInterestRate !== 0) {
    if (aZinsen.annualInterestRate > 99.999) {
      errors.push(translate('error.dunning.interest-rate-is-too-high'));
    }
    if (aZinsen.annualInterestRate < 0.0) {
      errors.push(translate('error.dunning.interest-rate-is-too-low'));
    }
    // Warnung, wenn: Jahreszins > 14.4%, Monatszins > 1.2% oder Tageszins > 0.04%
    if (aZinsen.annualInterestRate > 14.4) {
      errors.push(translate('warning.check-if-the-interest-rate-is-correct'));
    }
  }
};

/**
 * plausibilisiert das Datum fr die laufenden Zinsen. <br>
 * Es wird geprft:
 * <ul>
 * <li> VonDatum ist dirty (Message=Z25)
 * <li> VonDatum ist nicht innerhalb von 1970 und 2069 (Message=Z27)
 * <li> VonDatum ist leer und BisDatum ist nicht leer (Message=Z5)
 * <li> BisDatum ist dirty (Message=Z26)
 * <li> BisDatum ist nicht innerhalb von 1970 und 2069 (Message=Z28)
 * <li> VonDatum ist kleiner als BisDatum (Message=Z8)
 * <li> VonDatum ist gleich dem BisDatum (Message=Z9)
 * </ul>
 */
export const checkDatumLaufendeZinsen = (errors: string[], runningInterest: IRunningInterest) => {
  const von = runningInterest.fromBy;
  const bis = runningInterest.to;
  if (ValidationUtils.isDateValid(von) && !ValidationUtils.isYearInRange(von, 1970, 2069)) {
    errors.push(translate('error.dunning.from-date-is-invalid-not-in-range-1970-to-2069'));
  }
  if (ValidationUtils.isDateValid(bis) && !ValidationUtils.isYearInRange(bis, 1970, 2069)) {
    errors.push(translate('error.dunning.to-date-is-invalid-not-in-range-1970-to-2069'));
  }

  if (ValidationUtils.isDateValid(von) && ValidationUtils.isDateValid(bis)) {
    const vonDate = new Date(runningInterest.fromBy).getTime();
    const bisDate = new Date(runningInterest.to).getTime();

    if (vonDate > bisDate) {
      errors.push(translate('error.dunning.from-date-is-before-the-to-date'));
    }
    if (vonDate === bisDate) {
      errors.push(translate('error.dunning.from-date-and-to-date-are-equal'));
    }
  }
};

/**
 * plausibilisiert Zins fr Scheck/WechselMahnverfahren. <br>
 * Es wird geprft:
 * <ul>
 * <li> Ansprche mit KatNr: 30, 31 UND 32 und es wird versucht nicht nur fr Anspruch mit KatNr. 30 Zinsen zu beantragen (Message=Z21)
 * <li> Zinssatz berschreitet den Basiszinssatz um mehr als 2% (Message=Z23)
 * <li> Zinssatz mehr als 2% ber dem Basiszinssatz beantragt (Message=Z24)
 * </ul>
 */
export const checkSWMVZinsen = (
  errors: string[],
  aZinsen: IRunningInterest,
  catalogClaims: ICatalogClaim[],
  index: number,
  dunningProcedureType: string,
) => {
  let theIndex = -1;
  let katNr = '';
  let katNrTMP = '';
  let katNr30Drin = false;
  let katNr31Drin = false;
  let katNr32Drin = false;
  let isSWMVFlag = false;

  // Aktuellen Anspruch holen
  if (null !== index) {
    theIndex = index;
  }
  const theAnspruch = catalogClaims[index];
  if (null === theAnspruch) return;
  katNr = theAnspruch.catNr;
  isSWMVFlag = isSWMV(dunningProcedureType);

  // Schauen, ob die anderen KatNr da sind
  for (const einKatAnspruch of catalogClaims) {
    katNrTMP = einKatAnspruch.catNr;
    if (katNrTMP === '30') katNr30Drin = true;
    if (katNrTMP === '31') katNr31Drin = true;
    if (katNrTMP === '32') katNr32Drin = true;
  }
  if (katNr !== '30' && katNr30Drin && katNr31Drin && katNr32Drin && isSWMV) {
    errors.push(translate('error.dunning.if-check-exchange-dunning-procedure-claims-katalog-nr-30-31-32'));
  }

  // Max 2% + BasisZinssatz, aber nur, wenn Zins ber 6%
  let aDatum = new Date(aZinsen.fromBy);
  if (!ValidationUtils.isDateInFuture(aZinsen.fromBy)) {
    // Also ab Zustellung, dann nehmen wir zur Ermittlung des Zinses das heutige Datum
    aDatum = new Date();
  }

  const basis = getZinssatzFuerDatum(aDatum);
  const sechs = 6;
  const zwei = 2;
  const zins = aZinsen.interestRate;
  const zweiplusbasis = 2 + basis;
  // Nur Plausi, wenn zins ber 6%
  if (zins > sechs) {
    if (null !== basis) {
      if (zweiplusbasis < zins /* && aZinsen.isUeberBasisZinssatz() */) {
        errors.push(translate('error.dunning.interest-rate-exceeds-the-baseline-rate', { basis }));
      }
    }
  } else if (aZinsen.pointsAbovePrimeRate) {
    if (zins > zwei) {
      errors.push(translate('error.dunning.not-apply-more-than-2-over-the-baseline-rate'));
    }
  }
};

/**
 * plausibilisiert Zinsen fr Ansprche mit KatNr. 30. <br>
 * Es wird geprft:
 * <ul>
 * <li> es werden Zinsen ber Discont fr Anspruch mit KatNr. 30 beantragt (Message=Z24b)
 * </ul>
 */
export const checkCatalogNumber = (
  errors: string[],
  catNr: string,
  aZinsen: IRunningInterest,
  catalogClaims: ICatalogClaim[],
  index: number,
  message: string,
  checkFromDate: boolean,
) => {
  let theIndex = -1;
  // Aktuellen Anspruch holen
  if (null !== index) {
    theIndex = index;
  }
  const aAnspruch = catalogClaims[theIndex];
  if (aAnspruch !== undefined && aAnspruch.catNr === catNr) {
    if (aZinsen.pointsAbovePrimeRate && checkFromDate && ValidationUtils.isDateValid(aZinsen.fromBy)) {
      errors.push(translate(message));
    }
  }
};

/**
 * plausibilisiert die Zeitraumangabe, wenn ber Basiszinssatz. <br>
 * Es wird geprft:
 * <ul>
 * <li> Zinssatz ist nicht jhrlich (Message=Z29)
 * </ul>
 */
export const checkUeberBasisZins = (errors: string[], aZinsen: IRunningInterest) => {
  if (aZinsen.pointsAbovePrimeRate) {
    if (aZinsen.interestOption !== 'Jährlich') {
      errors.push(translate('error.dunning.only-anual-interest-possible'));
    }
  }
};

/**
 * plausibilisiert den Zinssatz, wenn VonDatum eingegeben wurde. <br>
 * Es wird geprft:
 * <ul>
 * <li> Zinssatz ist dirty (Message=Z1)
 * <li> Zinssatz ist leer/0 (Message=Z2)
 * <li> Zinssatz ist grer als 99,999 (Message=Z3)
 * <li> Zinssatz ist kleiner als 0 (Message=Z4)
 * <li> JahresZinssatz grer als 14,4 (Message=Z30)
 * <li> MonatsZinssatz grer als 1,2 (Message=Z30)
 * <li> TagesZinssatz grer als 0,04 (Message=Z30)
 * </ul>
 */
export const checkZinssatz = (errors: string[], aZinsen: IRunningInterest) => {
  const aDatum = aZinsen.fromBy;
  if (ValidationUtils.isDateValid(aDatum)) {
    const interestRate = aZinsen.interestRate;
    if (interestRate === 0 && !aZinsen.pointsAbovePrimeRate) {
      errors.push(translate('error.dunning.please-enter-an-interest-rate'));
    }
    if (interestRate === 0 && aZinsen.pointsAbovePrimeRate) {
      errors.push(translate('error.dunning.enter-an-interest-rate'));
    }
    if (interestRate > 99.999) {
      errors.push(translate('error.dunning.interest-rate-is-too-high'));
    }
    if (interestRate < 0.0) {
      errors.push(translate('error.dunning.interest-rate-is-too-low'));
    }
    // Warnung, wenn: Jahreszins > 14.4%, Monatszins > 1.2% oder Tageszins > 0.04%
    if (aZinsen.interestOption === 'Jährlich') {
      if (interestRate > 14.4) {
        errors.push(translate('warning.check-if-the-interest-rate-is-correct'));
      }
    }
    if (aZinsen.interestOption === 'Monatlich') {
      if (interestRate > 1.2) {
        errors.push(translate('warning.check-if-the-interest-rate-is-correct'));
      }
    }
    if (aZinsen.interestOption === 'Täglich') {
      if (interestRate > 0.04) {
        errors.push(translate('warning.check-if-the-interest-rate-is-correct'));
      }
    }
  }
};

/**
 * plausibilisiert das Datum fr die laufenden Zinsen (erweitert). <br>
 * berprft Datums- und Betrags-berschneidungen mit Aufruf von plausiUeberschneidung(aMC, theAntrag, theAnspruch, aZinsen, vonZins, bisZins, theIndexZ).
 */
export const checkDatumLaufendeZinsenErweitert = (
  errors: string[],
  aZinsen: ICalculatedInterest | IRunningInterest,
  index: number,
  indexZ: number,
  catalogClaims: ICatalogClaim[],
) => {
  const vonZins = isRunningInterest(aZinsen) ? aZinsen.fromBy : aZinsen.from;
  const bisZins = aZinsen.to;

  let theIndexZ = -1;
  let theIndex = -1;
  if (indexZ !== null) {
    theIndexZ = indexZ;
  }
  if (index !== null) {
    theIndex = index;
  }

  const theAnspruch = catalogClaims[theIndex];
  if (theAnspruch === null || theAnspruch === undefined) {
    return;
  }

  // Plausi 2: berschneidungen
  plausiUeberschneidung(errors, theAnspruch, aZinsen, vonZins, bisZins, theIndexZ);
};

export const checkAusgerechneteZinsen = (
  errors: string[],
  calcInterest: ICalculatedInterest,
  indexZ: number,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  claims: CombinedClaimType[],
): string[] => {
  checkAnzahlZinsen(errors, indexZ, claims, catalogClaims, otherClaims, calcInterest);
  if (errors.length > 0) return;
  if (claims.length < 0) {
    errors.push(translate('error.dunning.create-a-claim'));
    return;
  }

  if (hasOnlyKatNr('84', otherClaims, catalogClaims)) {
    errors.push(translate('error.dunning.calculated-interest-not-allowed-for-cat-no-84'));
    return;
  }
  checkDatumAusgerechneteZinsenErweitert(errors, calcInterest, catalogClaims, otherClaims);
  return errors;
};

export const checkLaufendeZinsen = (
  errors: string[],
  dunningProcedureType: string,
  einZinsen: IRunningInterest,
  index: number,
  catalogClaims: ICatalogClaim[],
  indexZ: number,
) => {
  if (isSWMV(dunningProcedureType)) {
    checkDatumLaufendeZinsen(errors, einZinsen);
    checkSWMVZinsen(errors, einZinsen, catalogClaims, index, dunningProcedureType);
  }

  checkCatalogNumber(errors, '30', einZinsen, catalogClaims, index, 'info.interest-over-base-rate-added-text', false);
  checkCatalogNumber(errors, '46', einZinsen, catalogClaims, index, 'error.dunning.interest-cannot-be-claimed-for-katalog-46', false);
  checkCatalogNumber(errors, '83', einZinsen, catalogClaims, index, 'error.dunning.from-date-must-be-filled', true);
  checkCatalogNumber(errors, '84', einZinsen, catalogClaims, index, 'error.dunning.interest-not-allowed-for-katalog-84', false);
  checkDatumLaufendeZinsenErweitert(errors, einZinsen, index, indexZ, catalogClaims);
  return errors;
};

/**
 * plausibilisiert die Zinsen. <br>
 * Aufruf checkAnzahl().<br>
 * Es wird geprft:
 * <ul>
 * <li> noch keien HAupforderung angegelegt, aber ausgerechnete Zinsen (Message=Z7)
 * </ul>
 * Wenn AusgerechneteZinsen, dann Aufruf von checkBetrag(), checkDatumAusgerechneteZinsen(), checkDatumZukunft() und checkDatumAusgerechneteZinsenErweitert().<br>
 * Wenn Scheck/WechselMahnverfahren, dann Aufruf von checkSWMV().<br>
 * Sonst Aufruf von check36(), checkBetrag(), checkZinssatz(), checkDatumLaufendeZinsen() und checkDatumLaufendeZinsenErweitert().
 */
export const checkZinsen = (
  errors: string[],
  dunningProcedureType: string,
  einZinsen: IRunningInterest,
  calcInterest: ICalculatedInterest,
  einKatAnspruch: CombinedClaimType,
  index: number,
  indexZ: number,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  claims: CombinedClaimType[],
) => {
  let size = 0;
  checkAnzahlZinsen(errors, indexZ, claims, catalogClaims, otherClaims, calcInterest);
  if (errors.length > 0) return;
  // Ausgerechnete Zinsen
  if (einKatAnspruch.type === 'calculatedInterest') {
    if (claims.length <= 0) {
      errors.push(translate('error.dunning.create-a-claim'));
      return;
    }

    if (hasOnlyKatNr('84', otherClaims, catalogClaims)) {
      errors.push(translate('error.dunning.calculated-interest-not-allowed-for-cat-no-84'));
      return;
    }

    size = errors.length;
    // Betrag mu gefllt sein
    checkDatumAusgerechneteZinsen(errors, calcInterest);
    if (einZinsen !== null) {
      checkDatumZukunft(errors, einZinsen);
    }
    if (errors.length !== size) return;
    checkDatumAusgerechneteZinsenErweitert(errors, calcInterest, catalogClaims, otherClaims);
    checkZinssatzAusgerechneteZinsen(errors, calcInterest);
  } else {
    // Laufende Zinsen

    if (isSWMV(dunningProcedureType)) {
      // Sonderplausi bei SWMV --> KatNr 30
      size = errors.length;
      checkDatumLaufendeZinsen(errors, einZinsen);
      checkSWMVZinsen(errors, einZinsen, catalogClaims, index, dunningProcedureType);
      if (errors.length !== size) return;
    }

    checkCatalogNumber(errors, '30', einZinsen, catalogClaims, index, 'info.interest-over-base-rate-added-text', false);
    checkCatalogNumber(errors, '46', einZinsen, catalogClaims, index, 'error.dunning.interest-cannot-be-claimed-for-katalog-46', false);
    checkCatalogNumber(errors, '83', einZinsen, catalogClaims, index, 'error.dunning.from-date-must-be-filled', true);
    checkCatalogNumber(errors, '84', einZinsen, catalogClaims, index, 'error.dunning.interest-not-allowed-for-katalog-84', false);
    checkUeberBasisZins(errors, einZinsen);
    checkZinssatz(errors, einZinsen);
    size = errors.length;
    checkDatumLaufendeZinsen(errors, einZinsen);
    if (errors.length !== size) return;
    checkDatumLaufendeZinsenErweitert(errors, einZinsen, index, indexZ, catalogClaims);
  }
};

/**
 * plausibilisiert den Namen des Partners (bei Firma). <br>
 * Es wird geprft:
 * <ul>
 * <li> weniger als 1 Zeichen bei Firma (Message=P11Fa)
 * <li> weniger als 1 Zeichen bei NatPers (Message=P11)
 * <li> nur 1 Zeichen bei Firma (Message=P12Fa)
 * <li> nur 1 Zeichen bei Firma (Message=P12)
 * </ul>
 * Dann Aufruf von validateName().
 */
export const checkNamePartner = (errors: string[], einAbtretung: ICatalogClaimDetailsOfAssignment) => {
  const laenge = einAbtretung.formerCreditor.trim().length;
  let theMessage = '';
  if (laenge < 1) {
    // if (aPartner.tab === applicantOrDefendantType.company) {
    //   theMessage = translate('P11Fa');
    // } else {
    theMessage = translate('error.dunning.enter-description');
    // }
    errors.push(theMessage);
  } else if (laenge < 2) {
    // if (aPartner.tab === applicantOrDefendantType.company) {
    //   theMessage = translate('P12Fa');
    // } else {
    theMessage = translate('warning.description-too-short');
    // }
    errors.push(theMessage);
  } else {
    validateName(errors, [einAbtretung.formerCreditor]);
  }
};

/**
 * plausibilisiert das Abgetreten-am-Datum. <br>
 * Es wird geprft:
 * <ul>
 * <li> Datum ist dirty oder leer (Message=AT3)
 * <li> Datum liegt in der Zukunft (Message=AT5)
 * <li> Liegt nicht innerhalb von 1970 und 2069 (Message=AT6)
 * </ul>
 */
export const checkAbgetretenAm = (errors: string[], einAbtretung: ICatalogClaimDetailsOfAssignment) => {
  if (ValidationUtils.isDateInFuture(einAbtretung.at)) {
    errors.push(translate('error.assignment-date-in-future'));
  }
  if (!ValidationUtils.isYearInRange(einAbtretung.at, 1970, 2069)) {
    errors.push(translate('error.assignment-date-not-in-range'));
  }
};

/**
 * plausibilisiert, Anzahl der Abtretungen (ab 01.12.2008 keine Prfung mehr). <br>
 * Es wird geprft:
 * <ul>
 * <li> mehr als 2 Abtretungen vorhanden sind (Message=AT4).
 * </ul>
 */
export const checkAnzahlAbtretung = (errors: string[], abtretungsSize: number) => {
  if (abtretungsSize > 2) {
    errors.push(translate('error.dunning.more-than-two-assignments-not-allowed'));
  }
};

/** Plausibilisiert die Abtretung. <br>
 * Nur, wenn Abtretung nicht null ist. Wenn nicht:<br>
 * <ul>
 * <li> Aufruf der Methode checkName() und checkPLZOrt() aus dem PartnerController
 * <li> checkAbgetretenAm()
 * <li> checkAnzahl()
 * <li> checkNameLaenge()
 * </ul>
 */
export const checkAbtretung = (errors: string[], einAbtretung: ICatalogClaimDetailsOfAssignment, abtretungSize: number) => {
  if (einAbtretung === undefined) return;
  checkNamePartner(errors, einAbtretung);
  checkAbgetretenAm(errors, einAbtretung);
  checkAnzahlAbtretung(errors, abtretungSize);
};

/**
 * plausibilisiert den AnspruchsZusatz. <br>
 * Aufruf der Methoden:
 * <ul>
 * <li> wenn genau geprft werden soll, ist Zusatz leer (Message=ANSPR44)
 * <li> checkPLZOrt()
 * <li> wenn Antrag not null, dann Aufruf checkAnspruch()
 * <li> wenn Antrag not null, dann prfen auf Druckfreigabe:
 * Soll auf Papier ausgedruckt werden und es liegen Daten fr das Bundesland in der
 * Tabelle DRUCKFREISCHALTUNG vor, dann wird geprft, ob esw sich um einen Anspruch
 * mit mahngerichtsrelevanter Katalognummer (90) handelt und der Ort im Anspruchszusatz
 * in einem freigeschalteten Gerichtsbezirk liegt (Message=AZ5).
 * </ul>
 */
export const checkAnspruchsZusatz = (
  errors: string[],
  catalogClaimAdditionalDetails: any,
  catalogClaim: ICatalogClaim,
  antragsgegners: IPersonDetails[],
  antragstellers: IPersonDetails[],
  dispatch: React.Dispatch<any>,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  courtData: ICourtData,
) => {
  if (catalogClaimAdditionalDetails === null || catalogClaimAdditionalDetails === undefined || isObjectEmpty(catalogClaimAdditionalDetails))
    return;

  if (isKatalogartAnspruchszusatzOrtOrPG(catalogClaim.catNr)) {
    const size = errors.length;
    // TODO: Uncomment when implemented
    // checkNation(aMC, einAnspruchsZusatz);
    if (size !== errors.length) return;

    if (!isInDeutschland(catalogClaimAdditionalDetails)) {
      // TODO: Uncomment when implemented
      // checkNationAuslandMB(aMC, einAnspruchsZusatz, theAntrag);
    }
    if (isInDeutschland(catalogClaimAdditionalDetails)) {
      dispatch(setForeignDunningPossible(false));

      // Anschriften der AGs mssen bei WEGs der Anschrift im Anspruchszusatz entsprechen
      if (isKatalogartAnspruchszusatzPGWEG(catalogClaim.catNr)) {
        let agIsWEG = false;
        // Fr WEG-Antragsgegner prfen, ob der angegebene Ort von der WEG-Adresse abweicht.
        if (antragsgegners !== undefined && antragsgegners.length > 0) {
          for (const aAG of antragsgegners) {
            if (isWEG2007(aAG)) {
              agIsWEG = true;

              // PLZ und Ort vergleichen
              if (aAG.postCode !== catalogClaimAdditionalDetails.postCode || aAG.city !== catalogClaimAdditionalDetails.city) {
                /* Strasse auch noch prfen?
                 * Antwort von Herrn Smailus (Tel. 24.11.2008): Nein.
                 */
                errors.push(translate('warning.different-weg-defendant-and-claim-details'));
                return;
              }
            }
          }
        }

        // Wenn der Antragsgegner kein WEG war:
        // Fr WEG-Antragsteller prfen, ob der angegebene Ort von der WEG-Adresse abweicht.
        if (!agIsWEG && antragstellers !== undefined && antragstellers.length > 0) {
          {
            for (const aAS of antragstellers) {
              if (isWEG2007(aAS)) {
                // PLZ und Ort vergleichen
                if (aAS.postCode !== catalogClaimAdditionalDetails.postCode || aAS.city !== catalogClaimAdditionalDetails.city) {
                  /* Strasse auch noch prfen?
                   * Antwort von Herrn Smailus (Tel. 24.11.2008): Nein.
                   */
                  errors.push(translate('warning.different-common-properties-of-applicant'));
                  return;
                }
              }
            }
          }
        }
      }

      checkAnspruchsZusatzPG(errors, catalogClaimAdditionalDetails, catalogClaim, catalogClaims, otherClaims, courtData);
    }
  }
};

/**
 * plausibilisiert den AnsprZusatz mit den anderen. <br>
 * Es wird geprft:
 * <ul>
 * <li> Haben alle AnspruchsZustze in allen Ansprchen das gleiche Prozessgericht (Message=AZ4)
 * </ul>
 */
export const checkAnspruchsZusatzPG = (
  errors: string[],
  einAnspruchsZusatz: any,
  einKatAnspruch: ICatalogClaim,
  catalogClaims: ICatalogClaim[],
  otherClaims: IOtherClaim[],
  courtData: ICourtData,
) => {
  if (courtData === undefined) {
    return;
  }
  if (isKatalogartAnspruchszusatzOrtOrPG(einKatAnspruch.catNr)) {
    let aPGAZ1 = null;
    let aPGAZ2 = null;

    // Wenn Kat17 Zusammen mit Kat19, 20 oder 90 dann kann nur das Amtsgericht zustndig sein,
    // niemals das Landgericht. Sind jedoch nur Anspruchszustze zu Kat17 vorhanden,
    // dann kann auch das Landgericht zustmdig sein.
    const katNrnAusschlielicheZustaendigkeitAG = ['19', '20', '90'];
    // TODO: Uncomment the code below when more info is provided for getAmstgericht how it shuld work
    // const hasAusschlielicheZustaendigkeitAG = hasKatNrnOneOrMore(katNrnAusschlielicheZustaendigkeitAG, catalogClaims, otherClaims);
    let aAZ = einAnspruchsZusatz;
    // if (!hasAusschlielicheZustaendigkeitAG && einKatAnspruch.catNr === '17') {
    const aHF = getHauptforderungssummeAntrag(catalogClaims, otherClaims);
    aPGAZ1 = courtData;
    // } else {
    //   // aPGAZ1 = ProzessGerichte.getAmtsgericht(aAZ.getPLZ(), aAZ.getOrt(), aAZ.getStr());
    // }

    if (catalogClaims !== undefined && catalogClaims.length > 0) {
      for (const katAnspruch of catalogClaims) {
        if (isKatalogartAnspruchszusatzOrtOrPG(katAnspruch.catNr)) {
          aAZ = katAnspruch.additionalDetails;
          if (aAZ === undefined || isObjectEmpty(aAZ)) {
            continue;
          }
          // TODO: Uncomment the code below when more info is provided for getAmstgericht how it shuld work
          // if (!hasAusschlielicheZustaendigkeitAG && katAnspruch.catNr === '17') {
          //   const aHF = getHauptforderungssummeAntrag(catalogClaims, otherClaims);
          aPGAZ2 = courtData;
          // } else {
          // aPGAZ2 = ProzessGerichte.getAmtsgericht(aAZ.getPLZ(), aAZ.getOrt(), aAZ.getStr());
          // }

          if (aPGAZ1 !== null && aPGAZ2 !== null) {
            if (aPGAZ1.LKZ !== aPGAZ2.LKZ && aPGAZ1.OLG !== aPGAZ2.OLG && aPGAZ1.LG !== aPGAZ2.LG && aPGAZ1.AG !== aPGAZ2.AG) {
              errors.push(translate('warning.only-claim-supplements-for-locations-wiht-same-trial-courts-can-be-specified'));
              break;
            }
          }
        }
      }
    }
  }
};

/**
 * prft, ob ein Antrag nur Ansprche mit den bergebenen Katalognummern hat.
 * Ist ein sonstiger Anspruch vorhanden, wird true zurckgegeben.
 * Sind keine Ansprche vorhanden, wird false zurckgegeben.
 */
export const hasKatNrnOneOrMore = (katNrn: string[], catalogClaims: ICatalogClaim[], otherClaims: IOtherClaim[]) => {
  if (isAnspruchEmpty(catalogClaims, otherClaims)) {
    return false;
  }
  for (const einKatAnspruch of catalogClaims) {
    const aKatNr = einKatAnspruch.catNr;

    if (katNrn.includes(aKatNr)) {
      return true;
    }
  }

  return false;
};

export const isKatalogartAnspruchszusatzOrtOrPG = (catlogNr: string) => {
  if (catlogNr === '17') return true;
  if (catlogNr === '19') return true;
  if (catlogNr === '20') return true;
  if (catlogNr === '90') return true;
  return false;
};

/**
 * bedeutet: ist Katalogart bei der das Prozessgericht anhand des
 * Anspruchszusatzes ermittelt wird. In diesem Falle gilt das nur wenn der Antragsgegner eine WEG ist.
 */
export const isKatalogartAnspruchszusatzPGWEG = (catalogNr: string) => {
  if (catalogNr === '90') return true;
  return false;
};

/**
 * Liefert true, wenn Antragsteller oder Antragsgegner eine WEG ist.
 * Nach WEG-nderung 01.07.2007
 *
 * @return boolean
 */
export const isWEG2007 = (person: IPersonDetails) => {
  if ((person.tab as othersType) === othersType.naturalPersonOrCompany) {
    // WEG vertreten durch eine juristische Person
    return true;
  }
  // if (person.naturalPersonDesignationOption !== undefined && person.naturalPersonDesignation !== ''
  // && C_OptiMahn.C_WEG2007.equals(myRechtsform.getArt())) {
  //   // WEG vertreten durch eine natrliche Person
  //   return true;
  // }
  return false;
};
