import warningMessages from '../../../../i18n/de/warning.json';
import errorMessages from '../../../../i18n/de/error.json';
import infoMessages from '../../../../i18n/de/info.json';
import hintMessages from '../../../../i18n/de/hint.json';

export interface SideContainerContent {
  messages: string[];
}

type NestedObject = {
  [key: string]: string | NestedObject;
};
export const extractValues = (obj: NestedObject): string[] => {
  let result: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        result = result.concat(extractValues(value));
      } else {
        result.push(value as string);
      }
    }
  }

  return result;
};

export const compareMessagesByStructure = (template: string, actual: string): boolean => {
  const regexPattern = template.replace(/\{\{.*?\}\}/g, '.*');
  const regex = new RegExp(`^${regexPattern}$`);
  return regex.test(actual);
};

export const ERROR_TEMPLATES = extractValues(errorMessages);
export const WARNING_TEMPLATES = extractValues(warningMessages);
export const INFO_TEMPLATES = extractValues(infoMessages);
export const HINT_TEMPLATES = extractValues(hintMessages);

export const filterMessagesByType = (messages: string[], templates: string[]): string[] => {
  if (messages === null || messages === undefined || templates === undefined || templates === null) {
    return [];
  }

  return messages.filter(msg => templates.includes(msg) || templates.some(template => compareMessagesByStructure(template, msg)));
};
