import React from 'react';
import { Route } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';
import Inhaltsverzeichnis from 'app/shared/oldPages/help/dunning/overview/inhaltsverzeichnis';
import Bedienung from 'app/shared/oldPages/help/dunning/bedienung/bedienung';
import Einfuehrung from 'app/shared/oldPages/help/dunning/einfuehrung/einfuehrung';
import Bundeslandauswahl from 'app/shared/oldPages/help/dunning/bundeslandauswahl/bundeslandauswahl';
import Antragstellerauswahl from 'app/shared/oldPages/help/dunning/antragstellerauswahl/antragstellerauswahl';
import ErrorBoundaryRoutes from '../../../error/error-boundary-routes';
import Rechtsbeistand from 'app/shared/oldPages/help/dunning/rechtsbeistand/rechtsbeistand';
import Eigen from 'app/shared/oldPages/help/dunning/rechtsbeistand/eigen/eigen';
import Fremd from 'app/shared/oldPages/help/dunning/rechtsbeistand/fremd/fremd';
import Gesellschaft from 'app/shared/oldPages/help/dunning/rechtsbeistand/gesellschaft/gesellschaft';
import HerrFrau from 'app/shared/oldPages/help/dunning/rechtsbeistand/herrFrau/herrFrau';
import Inkasso from 'app/shared/oldPages/help/dunning/rechtsbeistand/inkasso/inkasso';
import Verbraucher from 'app/shared/oldPages/help/dunning/rechtsbeistand/verbraucher/verbraucher';
import RechtsbeistandVertretung from 'app/shared/oldPages/help/dunning/rechtsbeistand/rechtsbeistandVertretung/rechtsbeistandVertretung';
import RechtsbeistandVertretungMain from 'app/shared/oldPages/help/dunning/rechtsbeistandVertretung/rechtsbeistandVertretung';
import Antragsteller from 'app/shared/oldPages/help/dunning/antragsteller/antragsteller';
import Ausland from 'app/shared/oldPages/help/dunning/antragsteller/ausland';
import AntragsgengerAusland from 'app/shared/oldPages/help/dunning/antragsgenger/ausland';
import NatuerlichePerson from 'app/shared/oldPages/help/dunning/antragsteller/natuerlichePerson';
import AntragsgengerNatuerlichePerson from 'app/shared/oldPages/help/dunning/antragsgenger/natuerlichePerson';
import Firma from 'app/shared/oldPages/help/dunning/antragsteller/firma';
import AntragsgengerFirma from 'app/shared/oldPages/help/dunning/antragsgenger/firma';
import Kennziffer from 'app/shared/oldPages/help/dunning/antragsteller/kenziffer';
import Sonstige from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/sonstige';
import AntragsgengerSonstige from 'app/shared/oldPages/help/dunning/antragsgenger/sonstige/sonstige';
import Pka from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/pka';
import AntragsgengerPka from 'app/shared/oldPages/help/dunning/antragsgenger/sonstige/pka';
import AnwaltEigen from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/anwaltEigen';
import Weg from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/weg';
import AntragsgengerWeg from 'app/shared/oldPages/help/dunning/antragsgenger/sonstige/weg';
import WegVerwalter from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/wegVerwalter';
import WegAntragsteller from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/wegAntragsteller';
import Weitere from 'app/shared/oldPages/help/dunning/antragsteller/sonstige/weitere';
import AntragsgengerWeitere from 'app/shared/oldPages/help/dunning/antragsgenger/sonstige/weitere';
import Antragsgegner from 'app/shared/oldPages/help/dunning/antragsgenger/antragsgegner';
import Nato from 'app/shared/oldPages/help/dunning/antragsgenger/nato';
import AntragsgegnerWegVerwalter from 'app/shared/oldPages/help/dunning/antragsgenger/sonstige/wegVerwalter';
import WegAntrasgegner from 'app/shared/oldPages/help/dunning/antragsgenger/sonstige/wegAntragsgegner';
import Hauptforderungen from 'app/shared/oldPages/help/dunning/hauptforderungen/hauptforderungen';
import RegulaeresMahnverfahren from 'app/shared/oldPages/help/dunning/hauptforderungen/regulaeresMahnverfahren';
import UrkundenMahnverfahren from 'app/shared/oldPages/help/dunning/hauptforderungen/urkundenMahnverfahren';
import ScheckMahnverfahren from 'app/shared/oldPages/help/dunning/hauptforderungen/scheckMahnverfahren';
import WechselMahnverfahren from 'app/shared/oldPages/help/dunning/hauptforderungen/wechselMahnverfahren';
import Katalognummer from 'app/shared/oldPages/help/dunning/hauptforderungen/katalognummer/katalognummer';
import Sonstiges from 'app/shared/oldPages/help/dunning/hauptforderungen/sonstiges/sonstiges';
import Katalognummern from 'app/shared/oldPages/help/dunning/hauptforderungen/katalognummer/katalognummern/katalognummern';
import Verbraucherkredit from 'app/shared/oldPages/help/dunning/hauptforderungen/sonstiges/verbraucherkredit';
import AusgerechneterZins from 'app/shared/oldPages/help/dunning/hauptforderungen/ausgerechneterZins';
import LaufendeZinsen from 'app/shared/oldPages/help/dunning/hauptforderungen/laufendeZinsen';
import BasisZins from 'app/shared/oldPages/help/dunning/basiszins/basisZins';
import AuslagenNebenforderungen from 'app/shared/oldPages/help/dunning/auslagenNebenforderungen/auslagenNebenforderungen';
import AuslagenNebenForderungenAntragsteller from 'app/shared/oldPages/help/dunning/auslagenNebenforderungen/antragsteller';
import Andere from 'app/shared/oldPages/help/dunning/auslagenNebenforderungen/andere';
import Prozessgerichte from 'app/shared/oldPages/help/dunning/prozessgerichte/prozessgerichte';
import AllgemeineAngaben from 'app/shared/oldPages/help/dunning/allgemeineAngaben/allgemeineAngaben';
import Bankverbindung from 'app/shared/oldPages/help/dunning/bankverbindung/bankverbindung';
import Uebersicht from 'app/shared/oldPages/help/dunning/uebersicht/uebersicht';
import Barcode from 'app/shared/oldPages/help/dunning/barcode/barcode';
import Chrome from 'app/shared/oldPages/help/generic/hinweis/chrome';
import Firefox from 'app/shared/oldPages/help/generic/hinweis/firefox';
import Edge from 'app/shared/oldPages/help/generic/hinweis/edge';
import Eda from 'app/shared/oldPages/help/dunning/eda/eda';
import Eid from 'app/shared/oldPages/help/dunning/eid/eid';
import Glossar from 'app/shared/oldPages/help/dunning/glossar/glossar';
import GlossWeg from 'app/shared/oldPages/help/dunning/gloss/glossweg';
import GlossVVRvg from 'app/shared/oldPages/help/dunning/gloss/glossvvrvg';
import GlossRVG from 'app/shared/oldPages/help/dunning/gloss/glossrvg';
import GlossRaineiEigenersache from 'app/shared/oldPages/help/dunning/gloss/glossraineigenersache';
import GlossKennziffer from 'app/shared/oldPages/help/dunning/gloss/glosskennziffer';
import GlossAuslkz from 'app/shared/oldPages/help/dunning/gloss/glossauslkz';
import Ansprechpartner from '../generic/ansprechpartner/ansprechpartner';
import { UrlPrefix } from 'app/config/constants';
import Anwaelte2018 from '../generic/anwaelte2018';
import VersionsInfo from '../generic/versionsInfo';

export const routeObject = (urlPrefix: UrlPrefix) => ({
  [`${urlPrefix}/inhaltsverzeichnis`]: <Inhaltsverzeichnis />,
  [`${urlPrefix}/einfuehrung`]: <Einfuehrung />,
  [`${urlPrefix}/bedienung`]: <Bedienung />,
  [`${urlPrefix}/bundeslandauswahl`]: <Bundeslandauswahl />,
  [`${urlPrefix}/antragstellerart`]: <Antragstellerauswahl />,
  [`${urlPrefix}/rechtsbeistand`]: <Rechtsbeistand />,
  [`${urlPrefix}/rechtsbeistand/eigen`]: <Eigen />,
  [`${urlPrefix}/rechtsbeistand/fremd`]: <Fremd />,
  [`${urlPrefix}/rechtsbeistand/gesellschaft`]: <Gesellschaft />,
  [`${urlPrefix}/rechtsbeistand/herr_frau`]: <HerrFrau />,
  [`${urlPrefix}/rechtsbeistand/inkasso`]: <Inkasso />,
  [`${urlPrefix}/rechtsbeistand/verbraucher`]: <Verbraucher />,
  [`${urlPrefix}/rechtsbeistand/rechtsbeistand_vertretung`]: <RechtsbeistandVertretung />,
  [`${urlPrefix}/antragsteller`]: <Antragsteller />,
  [`${urlPrefix}/antragsteller/ausland`]: <Ausland />,
  [`${urlPrefix}/antragsteller/natuerliche_person`]: <NatuerlichePerson />,
  [`${urlPrefix}/antragsteller/firma`]: <Firma />,
  [`${urlPrefix}/antragsteller/kennziffer`]: <Kennziffer />,
  [`${urlPrefix}/antragsteller/sonstige`]: <Sonstige />,
  [`${urlPrefix}/antragsteller/sonstige/pka`]: <Pka />,
  [`${urlPrefix}/antragsteller/sonstige/anwalt_eigen`]: <AnwaltEigen />,
  [`${urlPrefix}/antragsteller/sonstige/weg`]: <Weg />,
  [`${urlPrefix}/antragsteller/sonstige/weg_verwalter`]: <WegVerwalter />,
  [`${urlPrefix}/antragsteller/sonstige/weg_antragsteller`]: <WegAntragsteller />,
  [`${urlPrefix}/antragsteller/sonstige/weitere`]: <Weitere />,
  [`${urlPrefix}/antragsgegner`]: <Antragsgegner />,
  [`${urlPrefix}/antragsgegner/ausland`]: <AntragsgengerAusland />,
  [`${urlPrefix}/antragsgegner/natuerliche_person`]: <AntragsgengerNatuerlichePerson />,
  [`${urlPrefix}/antragsgegner/nato`]: <Nato />,
  [`${urlPrefix}/antragsgegner/firma`]: <AntragsgengerFirma />,
  [`${urlPrefix}/antragsgegner/sonstige`]: <AntragsgengerSonstige />,
  [`${urlPrefix}/antragsgegner/sonstige/pka`]: <AntragsgengerPka />,
  [`${urlPrefix}/antragsgegner/sonstige/weg`]: <AntragsgengerWeg />,
  [`${urlPrefix}/antragsgegner/sonstige/weg_verwalter`]: <AntragsgegnerWegVerwalter />,
  [`${urlPrefix}/antragsgegner/sonstige/weg_antragsgegner`]: <WegAntrasgegner />,
  [`${urlPrefix}/antragsgegner/sonstige/weitere`]: <AntragsgengerWeitere />,
  [`${urlPrefix}/rechtsbeistand_vertretung`]: <RechtsbeistandVertretungMain />,
  [`${urlPrefix}/hauptforderungen`]: <Hauptforderungen />,
  [`${urlPrefix}/hauptforderungen/regulaeres_mahnverfahren`]: <RegulaeresMahnverfahren />,
  [`${urlPrefix}/hauptforderungen/urkunden_mahnverfahren`]: <UrkundenMahnverfahren />,
  [`${urlPrefix}/hauptforderungen/scheck_mahnverfahren`]: <ScheckMahnverfahren />,
  [`${urlPrefix}/hauptforderungen/wechsel_mahnverfahren`]: <WechselMahnverfahren />,
  [`${urlPrefix}/hauptforderungen/katalognummer`]: <Katalognummer />,
  [`${urlPrefix}/hauptforderungen/katalognummer/katalognummern`]: <Katalognummern />,
  [`${urlPrefix}/hauptforderungen/sonstiges`]: <Sonstiges />,
  [`${urlPrefix}/hauptforderungen/sonstiges/verbraucherkredit`]: <Verbraucherkredit />,
  [`${urlPrefix}/hauptforderungen/ausgerechneter_zins`]: <AusgerechneterZins />,
  [`${urlPrefix}/hauptforderungen/laufende_zinsen`]: <LaufendeZinsen />,
  [`${urlPrefix}/basiszins`]: <BasisZins />,
  [`${urlPrefix}/auslagen_nebenforderungen`]: <AuslagenNebenforderungen />,
  [`${urlPrefix}/auslagen_nebenforderungen/antragsteller`]: <AuslagenNebenForderungenAntragsteller />,
  [`${urlPrefix}/auslagen_nebenforderungen/andere`]: <Andere />,
  [`${urlPrefix}/prozessgerichte`]: <Prozessgerichte />,
  [`${urlPrefix}/allgemeine_angaben`]: <AllgemeineAngaben />,
  [`${urlPrefix}/bankverbindung`]: <Bankverbindung />,
  [`${urlPrefix}/uebersicht`]: <Uebersicht />,
  [`${urlPrefix}/barcode`]: <Barcode />,
  [`${urlPrefix}/hinweis_chrome`]: <Chrome />,
  [`${urlPrefix}/hinweis_firefox`]: <Firefox />,
  [`${urlPrefix}/hinweis_edge`]: <Edge />,
  [`${urlPrefix}/eda`]: <Eda />,
  [`${urlPrefix}/eid`]: <Eid />,
  [`${urlPrefix}/glossar`]: <Glossar />,
  [`${urlPrefix}/glossweg`]: <GlossWeg />,
  [`${urlPrefix}/glossvvrvg`]: <GlossVVRvg />,
  [`${urlPrefix}/glossrvg`]: <GlossRVG />,
  [`${urlPrefix}/glossraineieigenersache`]: <GlossRaineiEigenersache />,
  [`${urlPrefix}/glosskennziffer`]: <GlossKennziffer />,
  [`${urlPrefix}/glossauslkz`]: <GlossAuslkz />,
  [`${urlPrefix}/ansprechpartner`]: <Ansprechpartner />,
  [`${urlPrefix}/anwaelte2018`]: <Anwaelte2018 />,
  [`${urlPrefix}/versionsinfo`]: <VersionsInfo />,
});

const HelpRoutes = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const routeObjectWithPrefix = routeObject(urlPrefix);
  return (
    <div>
      <ErrorBoundaryRoutes>
        {Object.keys(routeObjectWithPrefix).map(route => (
          <Route path={route} element={routeObjectWithPrefix[route]} key={route} />
        ))}
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default HelpRoutes;
