import React, { useState } from 'react';
import { Translate } from 'react-jhipster';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import { getPrintAndSign } from 'app/shared/reducers/requeststore';
import EdaDownloadLayout from './edaDownload/edaDownload';
import BarcodeLayout from './barcode/barcode';
import ElDidentityCardLayout from './eIDidentityCard/eIDidentityCard';
import { useAppSelector } from 'app/config/store';

export enum printAndSignType {
  barcode,
  edaDownload,
  eldStaffSelection,
}

export const PrintAndSign = () => {
  const printAndSignCurrentType = useSelector(getPrintAndSign)?.type;
  const [selectedTab, setSelectedTab] = useState(printAndSignCurrentType);
  const shippingTypeQuestions = useAppSelector(state => state.requestStore.shippingTypeQuestions);
  const shouldShowEdaDownload = shippingTypeQuestions?.secureTransport === true || shippingTypeQuestions === null;
  const shouldShowElDidentityCard = shippingTypeQuestions?.eID === true || shippingTypeQuestions === null;

  const availableTabs = [
    { type: printAndSignType.barcode, visible: true, translation: 'barcode' },
    { type: printAndSignType.edaDownload, visible: shouldShowEdaDownload, translation: 'eda-download' },
    { type: printAndSignType.eldStaffSelection, visible: shouldShowElDidentityCard, translation: 'eld-staff-selection' },
  ].filter(tab => tab.visible);

  const selectedIndex = availableTabs.findIndex(tab => tab.type === (selectedTab as printAndSignType));
  const safeSelectedIndex = selectedIndex !== -1 ? selectedIndex : 0;

  return (
    <div className="tabs-container">
      <Tabs selectedIndex={safeSelectedIndex} onSelect={index => setSelectedTab(availableTabs[index].type)}>
        <TabList className="tabs-list">
          {availableTabs.map((tab, index) => (
            <Tab name = {tab.translation} key={tab.type} className={safeSelectedIndex === index ? 'active-tab' : 'tab'}>
              <Translate contentKey={`generic.print-and-sign.${tab.translation}`}>{tab.translation}</Translate>
            </Tab>
          ))}
        </TabList>
        <div>
          {availableTabs.map(tab => (
            <TabPanel key={tab.type}>
              {tab.type === printAndSignType.barcode && <BarcodeLayout activeTab={tab.type} />}
              {tab.type === printAndSignType.edaDownload && <EdaDownloadLayout activeTab={tab.type} />}
              {tab.type === printAndSignType.eldStaffSelection && <ElDidentityCardLayout />}
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  );
};

export default PrintAndSign;
