import './attornyOfRecord.scss';
import React, { useEffect, useRef, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button, Col, Form, FormText, Label, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import InputField from 'app/shared/layout/custom-fields/input/inputField';
import ValidatedDropdown from 'app/shared/components/validated/drop-down-box/validatedDropdown';
import dunningDropdownStyles from 'app/shared/components/validated/drop-down-box/dunningDropdownStyle';
import LocationDetailsField from 'app/shared/layout/custom-fields/input/locationDetailsField';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  addPersonForStage,
  getApplicant,
  getAttorneyOfRecord,
  getCurrentStage,
  initialAttorneyOfRecordState,
  initialPersonDetails,
  resetAttorneyOfRecord,
  setApplicant,
  setAttorneyOfRecord as setAttorneyOfRecordToStore,
  setFunctionToAttorneyOfRecord,
} from 'app/shared/reducers/requeststore';
import { DropdownConfigKeys, fetchDropdownConfig, getDropdownConfig } from 'app/shared/reducers/configuration';
import InputWithFormText from 'app/shared/layout/custom-fields/input-with-form-text/inputWithFormText';
import DunningNavigation from 'app/shared/components/UI/navigation/dunning-navigation';
import GenericInputValidation from 'app/shared/validations/genericInputValidation';
import DropdownEmptyValidation from 'app/shared/validations/dropdownEmptyValidation';
import LabelWithComponent from 'app/shared/layout/custom-fields/labelWithComponent';
import { RequestTypes, StageNames } from 'app/shared/reducers/requeststore.interface';
import { RegisteredValidationMap } from 'app/shared/components/validated/validatedComponent';
import {
  checkAuftragsDatum,
  checkBetrag,
  checkKennzifferForProzessVertreter,
  checkMehrwertsteuersatz,
  checkNameProzessVertreter,
  validateServiceCodeAgainstPVKennziffer,
} from 'app/shared/validations/dunningDataValidation';
import { hide as hideSideContainer, show as showSideContainer } from 'app/shared/layout/side-container/side-container-slice';
import AddressValidation from 'app/shared/validations/addressValidation';
import {
  defaultCurrencyOptions,
  defaultPercentageOptions,
  ValidatedCurrencyField,
} from 'app/shared/components/validated/validatedCurrencyField';
import { currencyPlaceholder, percentagePlaceholder } from 'app/shared/util/statics';
import DateFieldEmptyValidation from 'app/shared/validations/dateFieldEmptyValidation';
import { SideContainerContent } from 'app/shared/layout/side-container/utils';
import ZipcodeCityValidation from 'app/shared/validations/ZipcodeCityValidation';

export enum tabType {
  lawyer,
  lawyerCompany,
  mrMrs,
  collectionAgency,
  consumerAssociation,
}

export const TabPanelLayout = (props: { activeTab: tabType }) => {
  const applicant = useSelector(getApplicant);
  const requestType = useAppSelector(state => state.requestStore.type);
  const consumersAssociationOptions = getDropdownConfig(DropdownConfigKeys.consumerAssociations);
  const descriptionOptions = getDropdownConfig(DropdownConfigKeys.descriptions);
  const legalForms = getDropdownConfig(DropdownConfigKeys.legalForms);
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const warningAcknowledged = useAppSelector(state => state.sideContainer?.warningAcknowledged) === true;
  const getStage = useSelector(getCurrentStage);
  const currentStage = getStage ? getStage.replace('_LEGAL_REP', '') : 'DUNNING_APPLICANT';

  useEffect(() => {
    if (!descriptionOptions || descriptionOptions.length === 0) dispatch(fetchDropdownConfig(DropdownConfigKeys.descriptions));
    if (!consumersAssociationOptions || consumersAssociationOptions.length === 0)
      dispatch(fetchDropdownConfig(DropdownConfigKeys.consumerAssociations));
    if (!legalForms || legalForms.length === 0) dispatch(fetchDropdownConfig(DropdownConfigKeys.legalForms));
  }, []);

  const attorneyOfRecordSessionData = useSelector(getAttorneyOfRecord);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [attorneyOfRecord, setAttorneyOfRecord] = useState({
    initialRender: true,
    type: props.activeTab,
    function: attorneyOfRecordSessionData.function,
    commissionDate: attorneyOfRecordSessionData.commissionDate,
    expenses: attorneyOfRecordSessionData.expenses,
    isApplicantEntitledToDeductInputText: attorneyOfRecordSessionData.isApplicantEntitledToDeductInputText,
    isLegalRepresentativeExemptFromPaymentSalesTax: attorneyOfRecordSessionData.isLegalRepresentativeExemptFromPaymentSalesTax,
    readerServiceCode: attorneyOfRecordSessionData.readerServiceCode,
    descriptionOrLegalOption: attorneyOfRecordSessionData.descriptionOrLegalOption,
    name: attorneyOfRecordSessionData.name[0],
    streetAndHouseNumber: attorneyOfRecordSessionData.streetAndHouseNumber,
    postCode: attorneyOfRecordSessionData.postCode,
    city: attorneyOfRecordSessionData.city,
    lkz: attorneyOfRecordSessionData.lkz,
    foreignCountry: attorneyOfRecordSessionData.foreignCountry,
    renumerationRvg: attorneyOfRecordSessionData.renumerationRvg,
    legalRepresentative: attorneyOfRecordSessionData.legalRepresentative,
    createdDunningRequest: false,
  });

  const registeredValidations = {} as RegisteredValidationMap;
  async function handleNextClick() {
    const errors = [];
    if (
      attorneyOfRecord.expenses !== '' &&
      attorneyOfRecord.isApplicantEntitledToDeductInputText === false &&
      attorneyOfRecord.isLegalRepresentativeExemptFromPaymentSalesTax === false &&
      (attorneyOfRecord.readerServiceCode === '' || attorneyOfRecord.readerServiceCode === undefined) &&
      requestType === RequestTypes.Dunning
    ) {
      errors.push(
        translate(
          'error.dunning.provide-information-on-expenses-fees-vat-or-input-tax-deduction-eligibility-you-must-specify-either-process-representative-data-or-a-process-representative-identifier',
        ),
      );
    }
    if (applicant === 'newApplicantRep' || requestType === RequestTypes.RedeliveryEnforcement) {
      const [newErrors, LKZ] = await ZipcodeCityValidation.validate(attorneyOfRecord.postCode, attorneyOfRecord.city);
      if (newErrors.length > 0) {
        errors.push(translate('warning.combination-plz-ort-wrong'));
      }
    }
    if(attorneyOfRecord.readerServiceCode !== '' && attorneyOfRecord.readerServiceCode !== undefined){
      validateServiceCodeAgainstPVKennziffer(errors, attorneyOfRecord.readerServiceCode);
    }
    if (errors.length > 0 && warningAcknowledged === false) {
      const sideContainerContent: SideContainerContent = {
        messages: errors,
      };
      dispatch(showSideContainer({ content: sideContainerContent }));
      return;
    }
    dispatch(hideSideContainer({ removeContent: true }));
    dispatch(
      setAttorneyOfRecordToStore({
        ...attorneyOfRecord,
        name: [attorneyOfRecord.name],
      }),
    );
    if(applicant === 'own-lawyer' && currentStage === StageNames.DUNNING_APPLICANT){
      dispatch(
        addPersonForStage({
          person: {
            ...initialPersonDetails,
          },
          stage: StageNames.DUNNING_APPLICANT,
        }),
      );
    }
    if (requestType === RequestTypes.Dunning) {
      navigateDunning();
    } else if (requestType === RequestTypes.RedeliveryDunning) {
      navigate(urlPrefix + '/bankverbindung');
    } else if (requestType === RequestTypes.Enforcement) {
      navigateEnforcement();
    } else if (requestType === RequestTypes.RedeliveryEnforcement) {
      navigateRedeliveryEnforcement();
    } else if (requestType === RequestTypes.Objection) {
      navigateObjection();
    }
    dispatch(setFunctionToAttorneyOfRecord(attorneyOfRecord.type));
  }

  function handleOnNextClickWrapper() {
    handleNextClick().catch(error => {
      console.error('An error occurred:', error);
    });
  }

  function navigateEnforcement() {
    if (
      !attorneyOfRecord.legalRepresentative &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/bankverbindung');
    }
  }

  function navigateDunning() {
    if (
      !attorneyOfRecord.legalRepresentative &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/antragsteller');
    }
  }

  function navigateRedeliveryEnforcement() {
    if ((attorneyOfRecord.commissionDate !== '' && attorneyOfRecord.readerServiceCode !== '') || props.activeTab === tabType.lawyer) {
      navigate(urlPrefix + '/bankverbindung');
    } else if (
      !attorneyOfRecord.legalRepresentative &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/bankverbindung');
    }
  }

  function navigateObjection() {
    if (
      !attorneyOfRecord.legalRepresentative &&
      requestType !== RequestTypes.Objection &&
      [tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab)
    ) {
      navigate(urlPrefix + '/rechtsbeistand_vertretung');
    } else {
      navigate(urlPrefix + '/antragsgegneranschrift');
    }
  }

  function deleteAttorneyOfRecord() {
    dispatch(resetAttorneyOfRecord());
    setAttorneyOfRecord({ ...initialAttorneyOfRecordState, initialRender: true });
  }

  function deleteAttorneyLegalRep() {
    setAttorneyOfRecord({ ...attorneyOfRecord, legalRepresentative: null, initialRender: false });
  }

  const [renumerationRvgErrors, setRenumerationRvgErrors] = useState([]);
  const validateRenumerationRVG = async () => {
    const rvgErrors = [];
    await Promise.resolve(checkMehrwertsteuersatz(rvgErrors, attorneyOfRecord));
    setRenumerationRvgErrors(rvgErrors);
    return rvgErrors;
  };

  const handleChange = e => {
    const { id, value, checked, type } = e.target;
    let newValue = type === 'checkbox' ? checked : value;
    if (id === 'name') {
      newValue = [value];
    }
    setAttorneyOfRecord({ ...attorneyOfRecord, [id]: newValue, initialRender: false });
  };

  const handleChangeDropDown = event => {
    if (event.value !== '') setBothEmptyError([]);

    setAttorneyOfRecord({ ...attorneyOfRecord, [event.id]: event.value, initialRender: false });
  };

  const [bothEmptyError, setBothEmptyError] = useState([]);
  const areOtherDataFilled = [
    attorneyOfRecord.descriptionOrLegalOption,
    attorneyOfRecord.city,
    attorneyOfRecord.postCode,
    attorneyOfRecord.streetAndHouseNumber,
  ].some(field => field !== '' && field !== undefined);

  const attorneyOfRecordRef = useRef(attorneyOfRecord);
  useEffect(() => {
    attorneyOfRecordRef.current = attorneyOfRecord;
  }, [attorneyOfRecord]);

  const onBlurValidation = (): Promise<string[]> => {
    return new Promise(resolve => {
      const errors = [];

      if (attorneyOfRecord.initialRender) {
        resolve([]);
      }

      const { readerServiceCode, descriptionOrLegalOption, postCode, streetAndHouseNumber } = attorneyOfRecordRef.current;
      const isReaderServiceCodeFilled = readerServiceCode !== '' && readerServiceCode !== undefined;
      const areOtherDataFilledFromAttorneyRef = [descriptionOrLegalOption, postCode, streetAndHouseNumber].some(
        field => field !== '' && field !== undefined,
      );

      if (isReaderServiceCodeFilled && areOtherDataFilledFromAttorneyRef) {
        errors.push(
          translate('error.fill-id-or-other', {
            idField: translate('dunning.attorneyOfRecord.reader-service-code.name'),
          }),
        );
      }

      if (errors.length === bothEmptyError.length) resolve(errors);

      setBothEmptyError(errors);
      resolve(errors);
    });
  };

  const [readerServiceCodeErrors, setReaderServiceCodeErrors] = useState([]);
  const states = useAppSelector(state => state.requestStore.states);
  const currentStateShort = states && states.length > 0 ? states[0] : null;
  const onReaderServiceCodeValidation = async () => {
    const errors = [];
    if (bothEmptyError.length > 0) {
      return errors;
    }
    await Promise.resolve(checkKennzifferForProzessVertreter(errors, attorneyOfRecord, currentStateShort));
    setReaderServiceCodeErrors(errors);
    return errors;
  };

  const [commisionDateErrors, setCommisionDateErrors] = useState([]);

  const onCommisionDateValidation = async () => {
    const errors = [];
    await Promise.resolve(checkAuftragsDatum(errors, attorneyOfRecord));
    setCommisionDateErrors(errors);
    return errors;
  };

  const [expensesErrors, setExpensesErrors] = useState([]);
  const onExpensesValidation = async () => {
    const errorsExpenses = [];
    await Promise.resolve(checkBetrag(errorsExpenses, attorneyOfRecord));
    setExpensesErrors(errorsExpenses);
    return errorsExpenses;
  };

  useEffect(() => {
    if (!attorneyOfRecord.initialRender) {
      onExpensesValidation();
    }
  }, [attorneyOfRecord.descriptionOrLegalOption, attorneyOfRecord.expenses]);

  const [nameErrors, setNameErrors] = useState([]);
  const onNameValidation = async () => {
    const errors = [];
    await Promise.resolve(checkNameProzessVertreter(errors, attorneyOfRecord));
    setNameErrors(errors);
    return errors;
  };

  const numericInputChange = (val, e) => {
    if (!e.event) return;
    setAttorneyOfRecord({ ...attorneyOfRecord, [e.event.target.id]: val.floatValue });
  };

  useEffect(() => {
    if (!attorneyOfRecord.initialRender) onBlurValidation();
  }, [attorneyOfRecord.descriptionOrLegalOption, attorneyOfRecord.readerServiceCode]);

  useEffect(() => {
    if (requestType === RequestTypes.RedeliveryEnforcement) {
      dispatch(setApplicant('newApplicantRep'));
    }
  }, [requestType]);

  return (
    <div>
      <Form className="form-layout">
        {applicant !== 'own-lawyer' && requestType !== RequestTypes.Objection && (
          <InputWithFormText
            id="commissionDate"
            type="date"
            name="kommissionDatum"
            labelContentKey="dunning.attorneyOfRecord.commisioning-date.name"
            labelDescription="dunning.attorneyOfRecord.commisioning-date.description"
            value={attorneyOfRecord.commissionDate}
            handleChange={handleChange}
            validations={[DateFieldEmptyValidation]}
            externalErrors={commisionDateErrors}
            key={'cd' + commisionDateErrors.length}
            customValidations={[onCommisionDateValidation]}
            registeredValidations={registeredValidations}
          />
        )}

        {requestType !== RequestTypes.Objection &&
          requestType !== RequestTypes.RedeliveryEnforcement &&
          [tabType.lawyer, tabType.lawyerCompany].includes(props.activeTab) && (
            <Row>
              <Col className="text-center col-12 col-xs-3 offset-xs-1">
                <Label className="expenses-label" for="expenses">
                  <Translate contentKey="dunning.attorneyOfRecord.expenses.name" />
                </Label>
              </Col>
              <Col className="col-12 col-xs-4">
                <ValidatedCurrencyField
                  id="expenses"
                  name={'expenses'}
                  onValueChange={numericInputChange}
                  placeholder={currencyPlaceholder}
                  maskOptions={defaultCurrencyOptions}
                  validations={[]}
                  externalErrors={expensesErrors}
                  key={'exp' + expensesErrors.length}
                  value={attorneyOfRecord.expenses}
                  registeredValidations={registeredValidations}
                />
              </Col>
              <Col className="col-12 col-xs-4" style={{ display: 'inline-flex' }}>
                <FormText style={{ fontSize: '11px' }}>
                  <Translate contentKey="dunning.attorneyOfRecord.expenses.description" />
                </FormText>
              </Col>
            </Row>
          )}
        {requestType !== RequestTypes.Objection && requestType !== RequestTypes.RedeliveryEnforcement && (
          <InputField
            id="isApplicantEntitledToDeductInputText"
            labelContentKey="dunning.attorneyOfRecord.applicant-not-entitled-to-deduct-input-text"
            name="Vorsteuerabzugsberechtigung"
            onChange={handleChange}
            type="checkbox"
            value={attorneyOfRecord.isApplicantEntitledToDeductInputText}
            checked={attorneyOfRecord.isApplicantEntitledToDeductInputText}
            validations={[]}
            registeredValidations={registeredValidations}
          />
        )}
        {[tabType.collectionAgency, tabType.lawyer, tabType.lawyerCompany, tabType.consumerAssociation].includes(props.activeTab) &&
          requestType !== RequestTypes.Objection &&
          requestType !== RequestTypes.RedeliveryEnforcement && (
            <InputField
              id="isLegalRepresentativeExemptFromPaymentSalesTax"
              labelContentKey="dunning.attorneyOfRecord.legal-representative-exempt-from-payment-sales-tax"
              name="Umsatzsteuerabzugsbefreiung"
              onChange={handleChange}
              type="checkbox"
              value={attorneyOfRecord.isLegalRepresentativeExemptFromPaymentSalesTax}
              checked={attorneyOfRecord.isLegalRepresentativeExemptFromPaymentSalesTax}
              validations={[]}
              registeredValidations={registeredValidations}
            />
          )}
        <p className="court-assigned-guardian-ad-item-id">
          <Translate contentKey="dunning.attorneyOfRecord.court-assigned-guardian-ad-item-id"></Translate>
        </p>
        <InputWithFormText
          id="readerServiceCode"
          name="readerServiceCode"
          labelContentKey="dunning.attorneyOfRecord.reader-service-code.name"
          labelDescription="dunning.attorneyOfRecord.reader-service-code.description"
          handleChange={handleChange}
          value={attorneyOfRecord.readerServiceCode}
          validations={[GenericInputValidation]}
          isOptional={areOtherDataFilled}
          externalErrors={bothEmptyError}
          key={'sc' + bothEmptyError.length}
          maxLength={8}
          customValidations={[onBlurValidation, onReaderServiceCodeValidation]}
          registeredValidations={registeredValidations}
        />
        <p className="enter-data">
          <Translate contentKey="dunning.attorneyOfRecord.enter-data"></Translate>
        </p>
        {props.activeTab === tabType.lawyer && (
          <div>
            <LabelWithComponent contentKey="dunning.attorneyOfRecord.description" labelId="label-descriptionOrLegalOption">
              <ValidatedDropdown
                labelledby="label-descriptionOrLegalOption"
                id="descriptionOrLegalOption"
                name="Bezeichnung"
                options={descriptionOptions}
                onChange={handleChangeDropDown}
                isOptional={attorneyOfRecord.readerServiceCode !== ''}
                value={attorneyOfRecord.descriptionOrLegalOption}
                style={dunningDropdownStyles}
                externalErrors={bothEmptyError}
                key={'desc' + bothEmptyError.length}
                validations={[DropdownEmptyValidation]}
                // customValidations={[onBlurValidation]} custom validation done by useEffect hook
                registeredValidations={registeredValidations}
              />
            </LabelWithComponent>
            <InputField
              id="name"
              name={'name'}
              onChange={handleChange}
              className="first-and-last-name"
              labelContentKey="dunning.attorneyOfRecord.first-and-last-name"
              type="text"
              value={attorneyOfRecord.name}
              validations={[GenericInputValidation]}
              isOptional={attorneyOfRecord.readerServiceCode !== ''}
              externalErrors={bothEmptyError.length === 0 ? nameErrors : bothEmptyError}
              key={'name' + bothEmptyError.length}
              validationKey={'name' + attorneyOfRecord.name}
              customValidations={[onBlurValidation, onNameValidation]}
              registeredValidations={registeredValidations}
            />
          </div>
        )}
        {props.activeTab === tabType.mrMrs && (
          <InputField
            id="name"
            onChange={handleChange}
            className="first-and-last-name"
            labelContentKey="dunning.attorneyOfRecord.first-and-last-name"
            type="text"
            value={attorneyOfRecord.name}
            validations={[GenericInputValidation]}
            isOptional={true}
            externalErrors={bothEmptyError.length === 0 ? nameErrors : bothEmptyError}
            key={'fn' + bothEmptyError.length}
            validationKey={'name' + attorneyOfRecord.name}
            customValidations={[onBlurValidation, onNameValidation]}
            registeredValidations={registeredValidations}
          />
        )}
        {[tabType.lawyerCompany, tabType.collectionAgency, tabType.consumerAssociation].includes(props.activeTab) && (
          <div>
            <LabelWithComponent contentKey="dunning.attorneyOfRecord.legal-form" labelId="label-descriptionOrLegalOption">
              <ValidatedDropdown
                labelledby="label-descriptionOrLegalOption"
                id="descriptionOrLegalOption"
                onChange={handleChangeDropDown}
                name="Rechtsform"
                options={props.activeTab === tabType.consumerAssociation ? consumersAssociationOptions : legalForms}
                value={attorneyOfRecord.descriptionOrLegalOption}
                validations={[DropdownEmptyValidation]}
                registeredValidations={registeredValidations}
                isOptional={true}
                externalErrors={bothEmptyError}
                key={'leg' + bothEmptyError.length}
                style={{
                  control: {
                    border: '1px solid #669966',
                    activeBorder: '1px solid #669966',
                    borderHover: '1px solid #99cc98',
                  },
                  dropdownIndicator: {
                    fill: '#669966',
                  },
                  indicatorSeparator: {
                    backgroundColor: '#669966',
                  },
                }}
              />
            </LabelWithComponent>
            <InputField
              id="name"
              onChange={handleChange}
              className="first-and-last-name"
              labelContentKey="dunning.attorneyOfRecord.full-description"
              type="textarea"
              value={attorneyOfRecord.name}
              validations={[GenericInputValidation]}
              customValidations={[onBlurValidation, onNameValidation]}
              isOptional={attorneyOfRecord.readerServiceCode !== ''}
              externalErrors={bothEmptyError.length === 0 ? nameErrors : bothEmptyError}
              key={'loc' + bothEmptyError.length}
              validationKey={'loc' + attorneyOfRecord.name}
              registeredValidations={registeredValidations}
            />
          </div>
        )}
        <InputField
          id="streetAndHouseNumber"
          name={'streetAndHouseNumber'}
          className="street-house-number"
          onChange={handleChange}
          labelContentKey="dunning.attorneyOfRecord.street-house-number"
          type="text"
          maxLength={35}
          value={attorneyOfRecord.streetAndHouseNumber}
          validations={[GenericInputValidation, AddressValidation]}
          customValidations={[onBlurValidation]}
          isOptional={attorneyOfRecord.readerServiceCode !== ''}
          externalErrors={bothEmptyError}
          key={'sthn' + bothEmptyError.length}
          validationKey={'sthn' + attorneyOfRecord.streetAndHouseNumber}
          registeredValidations={registeredValidations}
        />
        <LocationDetailsField
          onChangePostCode={handleChange}
          onChangeCity={handleChange}
          onChangeCountry={handleChange}
          postCodeValue={attorneyOfRecord.postCode}
          cityValue={attorneyOfRecord.city}
          foreignCountryValue={attorneyOfRecord.foreignCountry}
          isOptional={attorneyOfRecord.readerServiceCode !== ''}
          externalErrors={bothEmptyError}
          customValidation={[onBlurValidation]}
          registeredValidations={registeredValidations}
          validationKeyCity={'city' + attorneyOfRecord.city}
          validationKeyPostCode={'postcode' + attorneyOfRecord.postCode}
          validationKeyForeignCountry={'foreignCountry' + attorneyOfRecord.foreignCountry}
          setLKZ={(lkz: string) => setAttorneyOfRecord({ ...attorneyOfRecord, lkz })}
          applicant={applicant}
        ></LocationDetailsField>
        <Row className="mb-5"></Row>
        {(props.activeTab === tabType.lawyer || props.activeTab === tabType.lawyerCompany) && requestType !== RequestTypes.Objection && (
          <div>
            <Row className="mb-2">
              <Col className="label">
                <Label id={'label-' + translate('dunning.attorneyOfRecord.renumeration-rvg.name')}>
                  <Translate contentKey="dunning.attorneyOfRecord.renumeration-rvg.name">Label before input.</Translate>
                </Label>
              </Col>
              <Col className="contentCol">
                <ValidatedCurrencyField
                  id="renumerationRvg"
                  onValueChange={numericInputChange}
                  name={translate('dunning.attorneyOfRecord.renumeration-rvg.name')}
                  placeholder={percentagePlaceholder}
                  maskOptions={defaultPercentageOptions}
                  value={attorneyOfRecord.renumerationRvg}
                  validations={[]}
                  customValidations={[validateRenumerationRVG]}
                  externalErrors={renumerationRvgErrors}
                  key={'renumRvg' + renumerationRvgErrors.length}
                  registeredValidations={registeredValidations}
                />
              </Col>

              <Col className="col-12 col-xs-3">
                <FormText className="description-label">
                  <Translate contentKey="dunning.attorneyOfRecord.renumeration-rvg.description">
                    Label description after the input box.
                  </Translate>
                </FormText>
              </Col>
            </Row>
          </div>
        )}
        <Row className="mb-5"></Row>
        {attorneyOfRecord?.legalRepresentative && (
          <>
            <Row>
              <Col md="3" style={{ display: 'flex', justifyContent: 'right' }}>
                <Label className="legal-representative-label" for="legal-representative">
                  <Translate contentKey="generic.legalRepRepresentation.represented-by" />
                </Label>
              </Col>
              <Col md="6" className="delete-attorny-of-record-layout">
                <Link
                  className="delete-attorny-of-record"
                  to="/mahnbescheid/rechtsbeistand_vertretung"
                  id={'Prozessbevollmächtigten bearbeiten'}
                >
                  <span>{attorneyOfRecord.legalRepresentative.function + ' ' + attorneyOfRecord.legalRepresentative.name}</span>
                </Link>
              </Col>
              <Col md="3" style={{ textAlign: 'right' }}>
                <Button name="rechtsbeistand löschen" onClick={deleteAttorneyLegalRep}>
                  <Translate contentKey="entity.action.delete"></Translate>
                </Button>
              </Col>
            </Row>
            <Row className="mb-5"></Row>
          </>
        )}
        <Row>
          <Col md="3"></Col>
          <Col md="6" className="delete-attorny-of-record-layout" lg={8}>
            <Link
              className="delete-attorny-of-record"
              to="/mahnbescheid/rechtsbeistand"
              onClick={deleteAttorneyOfRecord}
              id={'Prozessbevollmächtigten löschen'}
            >
              <Translate contentKey="dunning.attorneyOfRecord.delete-attorny-of-record"></Translate>
            </Link>
          </Col>
          <Col md="3"></Col>
        </Row>
      </Form>
      <Row>
        <DunningNavigation
          handleOnNextClick={handleOnNextClickWrapper}
          nameSuffix="tab-panel-layout"
          registeredValidations={registeredValidations}
        />
      </Row>
    </div>
  );
};

export default TabPanelLayout;
