import './generic-help.css';
import React from 'react';

const VersionsInfo: React.FC = () => {
  return (
    <div className="help" style={{ backgroundColor: '#99cc99', padding: '20px', fontFamily: 'Arial, sans-serif', color: 'black' }}>
      <form>
        <table border={0} width={380}>
          <tbody>
            <tr>
              <td colSpan={2} style={{ verticalAlign: 'bottom' }}>
                <h1>Online-Mahnantrag</h1>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>
                  Version {VERSION} ({RELEASE_DATE})
                </b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={2}>Unterstützte Datensatzversion:</td>
            </tr>
            <tr>
              <td colSpan={2}>4.0.00 (seit 01.12.2008)</td>
            </tr>
            <tr>
              <td colSpan={2}>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </form>
      <button
        type="button"
        onClick={() => window.close()}
        style={{ position: 'absolute', right: '40px', bottom: '30px', backgroundColor: '#efefef,', border: 'none' }}
      >
        Schließen
      </button>
    </div>
  );
};

export default VersionsInfo;
