import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row, Label, FormText } from 'reactstrap';
import ValidatedInput, { ValidatedInputProps } from 'app/shared/components/validated/validatedInput';

type InputWithFormTextProps = ValidatedInputProps & {
  labelContentKey: string;
  labelDescription: string;
};

const InputWithFormText = (props: InputWithFormTextProps) => {
  const { name, handleChange, value, id, onBlur, labelContentKey, labelDescription, validations, customValidations, externalErrors } =
    props;
  return (
    <Row className="mb-2">
      <Col className="text-center col-12 col-xs-3 offset-xs-1">
        <Label className="expenses-label" for="expenses">
          <Translate contentKey={labelContentKey} />
        </Label>
      </Col>
      <Col className="col-12 col-xs-4">
        <ValidatedInput
          name={name}
          type={props.type || 'text'}
          id={id}
          onChange={handleChange}
          onBlur={onBlur}
          labelcontentkey={labelContentKey}
          value={value}
          validations={validations}
          customValidations={customValidations}
          externalErrors={externalErrors}
          key={id + externalErrors?.length}
          registeredValidations={props.registeredValidations}
          isOptional={props.isOptional}
          maxLength={props.maxLength}
        />
      </Col>
      <Col className="col-12 col-xs-4" style={{ display: 'inline-flex' }}>
        <FormText style={{ fontSize: '11px' }}>
          <Translate contentKey={labelDescription} />
        </FormText>
      </Col>
    </Row>
  );
};

export default InputWithFormText;
