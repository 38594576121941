import React from 'react';
import './programminfo.css';
import copyrightData from '../../../../copyright/copyright.json';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CopyrightData {
  name: string;
  license: string;
  version: string;
  copyright?: string;
  url: string;
}

const ProgrammInfo = () => {
  let data: CopyrightData[] = Object.values(copyrightData);
  data = data.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className="programminfo">
      <ol>
        {data.map((currentCopyrightData, index) => (
          <li key={index}>
            <h4>
              {currentCopyrightData.name} {currentCopyrightData.version}
            </h4>
            {currentCopyrightData.license !== 'UNLICENSED' && <div>{currentCopyrightData.license}</div>}
            {currentCopyrightData.copyright && <div>{currentCopyrightData.copyright}</div>}
            <div>
              <a className="link" href={currentCopyrightData.url} target="_blank" rel="noreferrer">
                Quelle aufrufen <FontAwesomeIcon className="arrow-up-icon" icon={faArrowUpRightFromSquare} />
              </a>
            </div>
            <hr className="line"></hr>
          </li>
        ))}
      </ol>
    </div>
  );
};
export default ProgrammInfo;
