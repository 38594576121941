import './header.scss';

import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Brand } from './header-components';
import {
  AdminMenu,
  EntitiesMenu,
  DunningRequestMenu,
  DunningHelpMenu,
  LegalNoticeMenu,
  PrivacyPolicyMenu,
  PrintAndSignMenu,
  ObjectionHelpMenu,
  ObjectionRequestMenu,
  RedeliveryDunningRequestMenu,
  RedeliveryDunningHelpMenu,
  EnforcementRequestMenu,
  EnforcementHelpMenu,
  RedeliveryEnforcementRequestMenu,
  RedeliveryEnforcementHelpMenu,
} from '../menus';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from "app/shared/reducers/requeststore.interface";
import { ProgramInfoMenu } from '../menus/programInfo';
import hotlineData from '../../../../hotline/hotline.json';
import { DropdownConfigKeys, getDropdownConfig } from 'app/shared/reducers/configuration';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

interface IHotlineData {
  state: string;
  hotline: string;
}
const listOfNonDisplayableHotlines =["Amtsgericht München - Mahngericht -", "Amtsgericht Nürnberg - Mahngericht -"];
const Header = (props: IHeaderProps) => {
  const hData: IHotlineData[] = hotlineData;
  const currentStatesShort = useAppSelector(state => state.requestStore.states);
  const dunningCourt = useAppSelector(state => state.requestStore.dunningCourt);
  const states = getDropdownConfig(DropdownConfigKeys.states);
  let currentState = undefined;
  let currentHotlineData = undefined;
  if (currentStatesShort !== null && currentStatesShort.length > 0 && currentStatesShort.length === 1) {
    currentState = states.find(s => s.short === currentStatesShort[0])?.long;
    currentHotlineData = hData.find(data => data.state === currentState);
  }
  let currentHotline = currentHotlineData ? currentHotlineData.hotline : dunningCourt.hotlineNumber;
  if(listOfNonDisplayableHotlines.includes(dunningCourt.name)){
    currentHotline = dunningCourt.name;
  }
  const [menuOpen, setMenuOpen] = useState(false);
  const [desktopMenuOpen, setDesktopMenuOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(getCurrentDimension);
  const location = useLocation();
  const requestType = useAppSelector(state => state.requestStore.type);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    if (windowSize.width > 1100) {
      setDesktopMenuOpen(true);
    } else {
      setDesktopMenuOpen(false);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);
  /* Disabled for now, will be needed later, when we activate the locale switch for development
  const dispatch = useAppDispatch();


  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };
*/

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const renderMenu = () => {
    switch (requestType) {
      case RequestTypes.Dunning:
        return (
          <>
            <DunningRequestMenu />
            <PrintAndSignMenu />
            <DunningHelpMenu />
          </>
        );
      case RequestTypes.RedeliveryDunning:
        return (
          <>
            <RedeliveryDunningRequestMenu />
            <PrintAndSignMenu />
            <RedeliveryDunningHelpMenu />
          </>
        );
      case RequestTypes.Objection:
        return (
          <>
            <ObjectionRequestMenu />
            <PrintAndSignMenu />
            <ObjectionHelpMenu />
          </>
        );
      case RequestTypes.Enforcement:
        return (
          <>
            <EnforcementRequestMenu />
            <PrintAndSignMenu />
            <EnforcementHelpMenu />
          </>
        );
      case RequestTypes.RedeliveryEnforcement:
        return (
          <>
            <RedeliveryEnforcementRequestMenu />
            <PrintAndSignMenu />
            <RedeliveryEnforcementHelpMenu />
          </>
        );
      default:
        return null;
    }
  };
  function showHotlineHeader() {
    if (location.pathname !== '/' && !['geschaeftsnummer', 'bundeslandauswahl', 'intention', 'mahngericht'].some(path => location.pathname.includes(path))) {
      return (
        <div className="hotline-header">
          {!listOfNonDisplayableHotlines.includes(dunningCourt.name) && (
            <>
              {currentState === undefined ? dunningCourt.name : currentState}
              {<p className="static-text-hotline">Hotline für nicht technische Fragen: </p>}
            </>
          )}
          {currentHotline}
        </div>
      );
    } else {
      return null;
    }
  }

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand={desktopMenuOpen} fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs">
            {location.pathname !== '/' && (
              <>
                {renderMenu()}
                <LegalNoticeMenu />
                <PrivacyPolicyMenu />
                <ProgramInfoMenu />
              </>
            )}
            {props.isAuthenticated && <EntitiesMenu />}
            {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
            {/* false && <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} /> */}
            {/* false && <AccountMenu isAuthenticated={props.isAuthenticated} /> */}
            {showHotlineHeader()}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
