import { UrlPrefix } from 'app/config/constants';

export interface IAttorneyOfRecord {
  type: number;
  function: string;
  commissionDate: string;
  expenses: number;
  isApplicantEntitledToDeductInputText: boolean;
  isLegalRepresentativeExemptFromPaymentSalesTax: boolean;
  readerServiceCode: string;
  descriptionOrLegalOption: string;
  name: string[];
  streetAndHouseNumber: string;
  postCode: string;
  city: string;
  lkz: string;
  foreignCountry: string;
  renumerationRvg: number;
  legalRepresentative: IPersonDetails;
  createdDunningRequest: boolean;
}
export interface IPersonDetails {
  id: string;
  type: string;
  salutation: string;
  function?: string;
  name: string[];
  streetOrHouseNumber: string;
  postCode: string;
  city: string;
  lkz?: string;
  foreignCountry: string;
  companyOtherLegalForm?: string;
  companyStreetOrHouseNumber: string;
  companyLegalOption: string;
  applicantCode: string;
  assets: string;
  isLawyerOnOwnBehalf: boolean;
  naturalPersonDesignationOption: string;
  naturalPersonDesignation: string;
  naturalPersonStreetOrHouseNumber: string;
  position: string;
  otherPosition?: string;
  legalPersonDesignation: string;
  legalRepresentatives: { [index: string]: ILegalRepresentative };
  tab: string;
  innerTab: string;
  defendantTrialCourtData?: ICourtData;
  manualTrialCourtData?: IManualProcessCourtData;
  redeliveryData?: {
    newLegalRepDeliveryData?: IRedeliveryData;
    serviceNotPossibleAtDefendantAddress?: IRedeliveryData;
    telephone: number;
    information: number;
    otherCosts: number;
    otherCostsRef: string;
    whenOther: string;
    amount: number;
    amount2: number;
    cause: string;
    cause2: string;
    interestBeCharged: boolean;
  };
  myBusinessReference?: string;
  isNatoTroopStatus: boolean;
  natoDetails?: INatoDetails;
  bankDetails?: {
    iban: string;
    bic: string;
    owner: string;
  };
  paymentsDone: boolean;
  payments: IPaymentDetails[];
}

export interface IRedeliveryData {
  position: string;
  otherPosition: string;
  name: string[];
  streetOrHouseNumber: string;
  postCode: string;
  city: string;
  foreignCountry: string;
  lkz?: string;
}

export interface IPaymentDetails {
  id: string;
  date: string;
  isoDate: string;
  rawDate: number;
  amount: number;
}

export interface INatoDetails {
  personalId: string;
  connection: string;
  address: string;
  postCode: string;
  city: string;
}

export interface IDunningProcedureType {
  key: string;
  label: string;
  value: string;
}

export interface ICatalogClaimDetailsOfAssignment {
  formerCreditor: string;
  postCode: string;
  city: string;
  foreignCountry: string;
  at: string;
}

export interface ICatalogClaim {
  id?: string;
  catNr: string;
  catOption: string;
  claimInformation: string;
  whenOther: string;
  invoiceNumber: string;
  from: string;
  to: string;
  amount: number;
  terms: boolean;
  catNumberTwentyEight?: string;
  damageCatalogType?: string;
  type: string;
  additionalDetails?: {
    postCode: string;
    city: string;
    foreignCountry?: string;
    streetAndHouseNumber?: string;
    lkz?: string;
  };
  additionalInformation?: {
    contractDate?: string;
    effectiveAnnualInterestRate?: number;
    detailsOfAssignment?: ICatalogClaimDetailsOfAssignment;
  };
  runningInterest: { [key: string]: IRunningInterest };
}

export interface IOtherClaim {
  id?: string;
  claimInformation?: string;
  from: string;
  to: string;
  amount: number;
  terms: boolean;
  type: string;
  additionalInformation?: {
    contractDate?: string;
    effectiveAnnualInterestRate?: number;
    detailsOfAssignment?: ICatalogClaimDetailsOfAssignment;
  };
  runningInterest: { [key: string]: IRunningInterest };
}

export interface ICalculatedInterest {
  id?: string;
  claimInformation?: string;
  from: string;
  to: string;
  amount: number;
  annualInterestRate: number;
  terms: false;
  additionalInformation?: {
    contractDate?: string;
    effectiveAnnualInterestRate?: number;
    detailsOfAssignment?: ICatalogClaimDetailsOfAssignment;
  };
}

export type CombinedClaimType = ICatalogClaim | IOtherClaim | IDocumentDunning;

export interface IRunningInterest {
  id: string;
  interestRate: number;
  pointsAbovePrimeRate: boolean;
  interestOption: string;
  amountSubjectToInterest: number;
  fromBy: string;
  to: string;
}

export interface IShippingTypeQuestions {
  email: boolean;
  secureTransport: boolean;
  eID: boolean;
  nothing: boolean;
}
export interface IPrintAndSign {
  type: number;
  readInstructions: boolean;
  readNotesForBarcodeAndPrint: boolean;
  readNotesForBrowser: boolean;
}

export interface IInitialState {
  type: RequestTypes;
  urlPrefix: UrlPrefix;
  states: string[];
  currentStage: StageNames | 'DUNNING_DEFENDANT' | undefined;
  shippingType: string | null;
  shippingTypeQuestions: IShippingTypeQuestions;
  applicant: string | null;
  content: string | null;
  processNumber?: {
    number: string;
    isAlternative: boolean;
  };
  attorneyOfRecord: IAttorneyOfRecord;
  persons: IPersonTypeMap;
  currentPersons: IPersonIdMap;
  currentPersonsHistory: IPersonTypeHistoryMap;
  dunningProcedureType: IDunningProcedureType | null;
  currentClaim?: CombinedClaimType;
  totalPrincipalReceivables: number;
  disputeValueUntil: number;
  claimList?: ICombinedClaimMap;
  courtFee: number;
  jointDebtors: boolean;
  expencesAncillaryClaims: IExpencesAncillaryClaims;
  ancillaryClaims: Array<IAncillaryClaim>;
  currentAncillaryClaim: IAncillaryClaim;
  applicationGeneralInformation?: IApplicationGeneralInformation;
  bankDetails: IBankDetails;
  dunningCourtData?: ICourtData;
  compensation: ICompensation;
  partialObjectionData: IPartialObjectionData;
  printAndSign: IPrintAndSign;
  identificationDocument?: IIdentificationDocument;
  calculatedInterest?: ICalculatedInterest;
  currentRunningInterest?: string;
  dunningCourt: IDunningCourt;
  attorneyOfRecordRenumeration?: IAttorneyOfRecordRenumeration;
  foreignDunningPossible?: boolean;
}

export interface IPartialObjectionData {
  mainClaim: number;
  interest: boolean;
  runningInterestBaseInterest: string;
  runningInterestValue: number;
  processCosts: boolean;
  ancillaryClaims: number;
}

export interface ICompensation {
  option: string;
  value: number | undefined;
}

export interface Expence {
  value: number;
  plusInterest: number;
  pointsAbovePrimeRate: boolean;
  from: string;
  to: string;
}

export interface CategorizedExpences {
  dunningCost?: Expence;
  informationCost?: Expence;
  bankCost?: Expence;
  debtCollectionCost?: Expence;
  lawyerCost?: Expence;
}

export interface IExpencesAncillaryClaims {
  formPostage: number;
  otherExpences: number;
  otherExpencesOption: string;
  whenOthers: string;
  expences: CategorizedExpences;
  preTrialActivity: number;
  preJudicialActivity: boolean;
  proceduralFee: number;
}

export interface IAncillaryClaim {
  id?: string;
  incidentalReceivablesAmount: number;
  incidentalReceivablesOption: string;
  whenOthers: string;
  plusInterest: number;
  pointsAbovePrimeRate: boolean;
  from: string;
  to: string;
}
export interface IApplicationGeneralInformation {
  reference: string;
  considerationDone?: boolean;
  considerationNotRelated?: boolean;
  legalProcessOnOpposition?: boolean;
  legalAid?: string;
  numberOne?: string;
  numberTwo?: string;
  numberThree?: string;
  numberFour?: string;
  redeliverByCourt?: boolean;
  redeliverSelf?: boolean;
  natoException?: boolean;
  freeFromCosts?: string;
}

export interface IIdentificationDocument {
  creator?: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  addressType?: string;
  issuingState: string;
  streetAddress?: string;
  residentInGermany: boolean;
  postalCode?: string;
  city?: string;
  country?: string;
}

export interface ILegalRepresentative {
  id: string;
  function: string;
  name: string[];
  streetOrHouseNumber: string;
  postCode: string;
  city: string;
  lkz: string;
  foreignCountry: string;
}

export interface IDocumentDunning {
  id: string;
  claimInformation: string;
  from: string;
  to: undefined;
  amount: number;
  isApplicantCreditor: boolean;
  additionalInformation: undefined;
  type: 'IDocumentDunning';
  terms: false;
  runningInterest: { [key: string]: IRunningInterest };
}

export interface IPersonMap {
  [index: string]: IPersonDetails;
}
export interface IPersonIdMap {
  [index: string]: string;
}
export interface IPersonTypeMap {
  [index: string]: IPersonMap;
}
export interface ICombinedClaimMap {
  [index: string]: CombinedClaimType;
}
export interface IPersonTypeHistoryMap {
  [index: string]: string[];
}

export interface ICourtIndexMap {
  [index: number]: ICourtData;
}

export interface IBankDetails {
  iban: string;
  bic: string;
  owner: string;
}

export interface IManualProcessCourtData {
  option: string;
  postCode: string;
  city: string;
  dontChangeOnAddressChange: boolean;
}

export interface ICourtData {
  XJUSTIZID: string;
  URL1: string;
  AG: string;
  PLZ_ZUSTELLBEZIRK: string;
  PLZ_GROSSEMPFAENGER?: string;
  ERV_ZIVIL?: string;
  OLG: string;
  TYP_INFO: string;
  PLZ_POSTFACH?: string;
  POSTFACH?: string;
  ERV_FAMILIE?: string;
  AUT_MAHN_VERF_MERKMAL_INFO: string;
  EMAIL1: string;
  ORT: string;
  STR_HNR: string;
  XML_SUPPORT: string;
  KAMMER_FUER_HANDELSSACH: string;
  BEZEICHNUNG: string;
  LKZ: string;
  TEL: string;
  LG: string;
  FAX: string;
  ERV_FG?: string;
  ORTK: string;
  ERV_STRAF?: string;
  ERV_GRUNDBUCH?: string;
  ERV_MAHN?: string;
}

export interface IDunningCourt {
  name: string;
  hotlineNumber: string;
}

export interface IAttorneyOfRecordRenumeration {
  compensation: string;
  compensationValue: number;
}

export const GetApplicantOfType = (type: RequestTypes) => {
  switch (type) {
    case RequestTypes.Dunning:
      return StageNames.DUNNING_APPLICANT;
    case RequestTypes.RedeliveryEnforcement:
    case RequestTypes.RedeliveryDunning:
      return StageNames.REDELIVERY_APPLICANT;
    default:
      return undefined;
  }
};

export const GetDefendantOfType = (type: RequestTypes) => {
  switch (type) {
    case RequestTypes.Dunning:
      return StageNames.DUNNING_DEFENDANT;
    case RequestTypes.Enforcement:
      return StageNames.ENFORCEMENT_DEFENDANT;
    case RequestTypes.Objection:
      return StageNames.OBJECTION_DEFENDANT;
    case RequestTypes.RedeliveryDunning:
    case RequestTypes.RedeliveryEnforcement:
      return StageNames.REDELIVERY_DEFENDANT;
    default:
      return undefined;
  }
};

export enum RequestTypes {
  Dunning = 'dunning', // Mahnantrag
  Enforcement = 'enforcement', // Vollstreckungsbescheid
  Opposition = 'opposition',
  Objection = 'objection', // Widerspruch
  RedeliveryDunning = 'redeliveryDunning', // Neuzustellung Mahnantrag
  RedeliveryEnforcement = 'redeliveryEnforcement', // Neuzustellung Vollstreckungsbescheid
}

export enum StageNames {
  DUNNING_GENERAL = 'DUNNING_GENERAL',
  DUNNING_APPLICANT = 'DUNNING_APPLICANT',
  DUNNING_DEFENDANT = 'DUNNING_DEFENDANT',
  DUNNING_APPLICANT_LEGAL_REP = 'DUNNING_APPLICANT_LEGAL_REP',
  DUNNING_DEFENDANT_LEGAL_REP = 'DUNNING_DEFENDANT_LEGAL_REP',
  REDELIVERY_APPLICANT = 'REDELIVERY_APPLICANT',
  REDELIVERY_DEFENDANT = 'REDELIVERY_DEFENDANT',
  ENFORCEMENT_DEFENDANT = 'ENFORCEMENT_DEFENDANT',
  OBJECTION_DEFENDANT = 'OBJECTION_DEFENDANT',
}
