import { useAppDispatch, useAppSelector } from 'app/config/store';
import { contentSelector, setWarningsAcknowledged, typeSelector } from './side-container-slice';
import React, { useEffect, useRef } from 'react';
import { ERROR_TEMPLATES, filterMessagesByType, HINT_TEMPLATES, INFO_TEMPLATES, WARNING_TEMPLATES } from './utils';
import { hide as hideSideContainer, isShowingSelector, removeContent } from 'app/shared/layout/side-container/side-container-slice';
import basicSideContainer from './basic-side-container';
import SideContainerMessage from './side-container-mesage';
import { useLocation } from 'react-router';
import { Card, Col } from 'reactstrap';

export const SideContainer = () => {
  const isVisible = useAppSelector(isShowingSelector);
  const content = useAppSelector(contentSelector);
  const type = useAppSelector(typeSelector);
  const requestType = useAppSelector(state => state.requestStore.type);

  const messagesByType = {
    ERROR: filterMessagesByType(content?.messages, ERROR_TEMPLATES),
    WARNING: filterMessagesByType(content?.messages, WARNING_TEMPLATES),
    INFO: filterMessagesByType(content?.messages, INFO_TEMPLATES),
    HINT: filterMessagesByType(content?.messages, HINT_TEMPLATES),
    ALL: content?.messages,
  };

  const warningMessages = messagesByType.WARNING || [];
  const errorMessage = messagesByType.ERROR || [];
  const infoMessages = messagesByType.INFO || [];
  const hintMessages = messagesByType.HINT || [];
  const allMessagesTypes = messagesByType.ALL || [];

  const allMessages =
    warningMessages.length === 0 && errorMessage.length === 0 && infoMessages.length === 0 && hintMessages.length === 0
      ? content?.messages || []
      : [];

  const dispatch = useAppDispatch();
  const location = useLocation();
  // Hide sidecontainer on navigation
  const prevLocation = useRef(location.pathname);

  useEffect(() => {
    // eslint-disable-next-line no-constant-condition
    if (prevLocation.current !== location.pathname && isVisible) {
      dispatch(hideSideContainer({ removeContent: true }));
    }
    prevLocation.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      setWarningsAcknowledged(
        (warningMessages.length > 0 || infoMessages.length > 0 || hintMessages.length > 0 || allMessagesTypes.length > 0) &&
          errorMessage.length === 0,
      ),
    );
  }, [dispatch, warningMessages, errorMessage, infoMessages, hintMessages]);

  if (!isVisible) return null;

  return (
    <Col className={`col-12 col-xxs-2 col-xs-3 col-sm-2 col-md-2 theme-${requestType}`}>
      <Card className={`jh-card base`} id={'sidecontainer_card'}>
        {Object.entries(messagesByType)
          .filter(([key]) => key !== 'ALL')
          .map(([key, messages]) =>
            messages.length > 0 ? <SideContainerMessage key={key} messages={messages} type={key as keyof typeof messagesByType} /> : null,
          )}
        {allMessages.length > 0 &&
          allMessages.map((msg, index) => <SideContainerMessage key={`all-${index}`} messages={[msg]} type="ALL" />)}
        {type === 'BASIC' && basicSideContainer()}
      </Card>
    </Col>
  );
};

export default SideContainer;
