import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from '../menu-components';
import { Translate, translate } from 'react-jhipster';
import { useAppSelector } from 'app/config/store';
import { setCurrentStage } from 'app/shared/reducers/requeststore';
import { useDispatch } from 'react-redux';
import { StageNames } from 'app/shared/reducers/requeststore.interface';

export const DunningRequestMenu = () => {
  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);
  const dispatch = useDispatch();
  const menuEntries = new Map<string, string>();
  const mainLabelForTranslateKey = 'menu.request';
  const requestTypeFoTranslateKey = 'dunning';
  const labelKey = `${mainLabelForTranslateKey}.${requestTypeFoTranslateKey}`;
  menuEntries.set(`${labelKey}.federal-state-selection`, '/bundeslandauswahl');
  menuEntries.set(`${labelKey}.attorney-of-record`, '/rechtsbeistand');
  menuEntries.set(`${labelKey}.applicant`, '/antragsteller');
  menuEntries.set(`${labelKey}.defendant`, '/antragsgegner');
  menuEntries.set(`${labelKey}.principal-claim-interest`, '/mahnverfahren');
  menuEntries.set(`${labelKey}.expenses-ancillary-claims`, '/auslagen_nebenforderungen');
  menuEntries.set(`${labelKey}.general-information`, '/allgemeine_angaben');

  function setRelevanRequestStoreData(value: string) {
    if (value.includes('antragsteller')) {
      dispatch(setCurrentStage(StageNames.DUNNING_APPLICANT));
    } else if (value.includes('antragsgegner')) {
      dispatch(setCurrentStage(StageNames.DUNNING_DEFENDANT));
    }
  }

  return (
    <NavDropdown name={translate('menu.request.label')} id="request-dropdown">
      {[...menuEntries.entries()].map(([key, value]) => (
        <DropdownItem key={key} href={`${urlPrefix}${value}`} onClick={() => setRelevanRequestStoreData(value)}>
          <Translate contentKey={key} />
        </DropdownItem>
      ))}
    </NavDropdown>
  );
};
