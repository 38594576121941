import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import {
  generateBlur,
  getDomProps,
  getErrorsForTooltip,
  registerValidationComponent,
} from 'app/shared/components/validated/validatedComponent';
import { ValidatedCurrencyProps } from 'app/shared/components/validated/validatedInput';
import { Tooltip } from 'react-tooltip';

export const defaultCurrencyOptions = {
  prefix: '',
  suffix: ' EUR',
  thousandSeparator: '.',
  allowDecimal: true,
  decimalSeparator: ',',
  decimalScale: 2, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false,
  isAllowed: value => value.floatValue < Math.pow(10, 7), // limit length of integer numbers
};

export const defaultPercentageOptions = {
  prefix: '',
  suffix: ' %',
  thousandSeparator: '.',
  allowDecimal: true,
  decimalSeparator: ',',
  decimalScale: 3, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false,
  isAllowed: value => value.floatValue < Math.pow(10, 3), // limit length of integer numbers
};

export const ValidatedCurrencyField = (inputProps: ValidatedCurrencyProps) => {
  const [errors, setErrors] = useState([...(inputProps.externalErrors || [])]);

  registerValidationComponent(inputProps, errors, setErrors);

  const className = 'form-control currencyfield';
  const css = errors.length !== 0 ? 'validation-failed ' + className : className;
  const DOMprops = getDomProps(inputProps);

  return (
    <div>
      <NumericFormat
        {...inputProps.maskOptions}
        {...DOMprops}
        className={css}
        isAllowed={({ floatValue }) => floatValue === undefined || floatValue <= 9999999}
        key={inputProps.id + errors.length}
        onBlur={e => {
          generateBlur<HTMLInputElement>(inputProps, errors, setErrors)(e);
        }}
      />
      {errors.length !== 0 && (
        <Tooltip anchorSelect={`#${inputProps.id || inputProps.name}`} place="top">
          {getErrorsForTooltip(errors)}
        </Tooltip>
      )}
    </div>
  );
};

ValidatedCurrencyField.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
};

ValidatedCurrencyField.propTypes = {
  inputMode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.number,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};
