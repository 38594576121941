export class ValidationUtils {

  public static isDateInFuture(date: string): boolean {
    const currentDate = new Date(date);
    const nowDate = new Date();
    return currentDate > nowDate;
  }

  public static isYearInRange(date: string, startYear: number, endYear: number): boolean {
    const currentYear = new Date(date).getFullYear();
    return currentYear >= startYear && currentYear <= endYear;
  }

  public static isDateValid(date:string) : boolean {
    if(date !== undefined && date !== ''){
      return true;
    }
    return false;
  }
}
