import React, { PropsWithChildren } from 'react';
import { Translate } from 'react-jhipster';
import { Col, Label, Row } from 'reactstrap';

type LabelWithComponentProps = {
  contentKey: string;
  labelId: string;
};

const LabelWithComponent = (props: PropsWithChildren<LabelWithComponentProps>) => {
  return (
    <Row>
      <Col className="label">
        <Label id={props.labelId}>
         {props.contentKey === "" ? null : <Translate contentKey={props.contentKey} /> }
        </Label>
      </Col>
      <Col className="contentCol">{props.children}</Col>
    </Row>
  );
};

export default LabelWithComponent;
