import { IRootState } from 'app/config/store';
import { useSelector } from 'react-redux';
import {
  GetApplicantOfType,
  GetDefendantOfType,
  ICatalogClaim,
  IInitialState,
  IPersonDetails,
  RequestTypes,
} from 'app/shared/reducers/requeststore.interface';
import axios from 'axios';

export const currencyFormatter = Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'code',
  minimumFractionDigits: 2,
});

export const percentageFormatter = Intl.NumberFormat('de-DE', {
  style: 'percent',
  maximumFractionDigits: 3,
});

export const currencySuffix = 'EUR';
export const currencyPlaceholder = `0 ${currencySuffix}`;

export const percentageSuffix = '%';
export const percentagePlaceholder = `0 ${percentageSuffix}`;

export const useBaseUrl = () => {
  return useSelector((state: IRootState) => state.requestStore.urlPrefix);
};

export const defaultTextLength = 35;
export const splitText = (input, maxLength) => {
  const splitString = input.split('\n');
  for (let i = 0; i < splitString.length; i++) {
    if (splitString[i].length > maxLength) {
      const overflow = splitString.substring(maxLength);
      splitString[i] = splitString[i].substring(0, maxLength);
      insertIntoArray(splitString, i + 1, overflow);
    }
  }
};

export const joinText = (items: string[], separator: string): string => {
  return items.join(separator);
};

export const insertIntoArray = (array: any[], index: number, ...items: any[]): any[] => {
  return [...array.slice(0, index), items, ...array.slice(index)];
};

export const downloadFile = (content, name) => {
  const href = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const processAndDownloadEda = (requestStoreData: IInitialState) => {
  processAndDownloadFile(requestStoreData, '/eda/process_' + requestStoreData.type + '?debug=true');
};

export const processAndDownloadBarcode = (requestStoreData: IInitialState) => {
  processAndDownloadFile(requestStoreData, '/barcode/process_' + requestStoreData.type + '?debug=true');
};

const processAndDownloadFile = (requestStoreData: IInitialState, processUrl: string) => {
  axios
    .request({
      url: processUrl,
      data: requestStoreData,
      method: 'POST',
      responseType: 'blob',
    })
    .then(response => {
      const filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0].replace('"', '');
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0].replace('"', '');

      downloadFile(response.data, filename + '.' + extension);
    })
    .catch(exception => {
      downloadFile(exception.response.data, 'exception.zip');
    });
};

export const getDunningCourtForClaim = (claim: ICatalogClaim) => {
  return axios.request({
    method: 'get',
    url: `/configuration/getDunningCourt?zipCode=${claim.additionalDetails.postCode}&city=${claim.additionalDetails.city}`,
  });
};

export const getDunningCourt = (data: IInitialState, person: IPersonDetails) => {
  const hasForeignApplicant = Object.values(data.persons[GetApplicantOfType(data.type)]).some(v => v.foreignCountry);
  if (hasForeignApplicant) {
    return axios.request({
      method: 'get',
      url: `/configuration/getForeignDunningCourt`,
    });
  }

  const catNr90: ICatalogClaim = Object.values(data.claimList).find(c => 'catNr' in c && c.catNr === '90') as ICatalogClaim;
  if (catNr90) {
    return axios.request({
      method: 'get',
      url: `/configuration/getDunningCourt?zipCode=${catNr90.additionalDetails?.postCode}&city=${catNr90.additionalDetails?.city}`,
    });
  }

  const foreignDefendant =
    data.persons[GetDefendantOfType(data.type)] !== undefined
      ? Object.values(data.persons[GetDefendantOfType(data.type)]).find(v => v.foreignCountry)
      : undefined;
  if (foreignDefendant) {
    return axios.request({
      method: 'get',
      url: `/configuration/getDunningCourtLKZ?lkz=${foreignDefendant.defendantTrialCourtData.LKZ}`,
    });
  }

  return axios.request({
    method: 'get',
    url: `/configuration/getDunningCourt?zipCode=${person.postCode}&city=${person.city}`,
  });
};

export const getTrialCourt = (data: IInitialState, person: IPersonDetails) => {
  if (
    person.manualTrialCourtData !== undefined &&
    person.manualTrialCourtData.city !== '' &&
    person.manualTrialCourtData.postCode !== '' &&
    person.manualTrialCourtData.option !== '' &&
    person.manualTrialCourtData.option !== undefined
  ) {
    return axios.request({
      method: 'get',
      url: `/configuration/validateTrialCourt?zipCode=${person.manualTrialCourtData.postCode}&city=${person.manualTrialCourtData.city}&type=${person.manualTrialCourtData.option}`,
    });
  }

  if (data.type === RequestTypes.RedeliveryDunning && parseInt(data.applicationGeneralInformation.numberOne, 10) < 92) {
    const sumOfClaims = Object.values(data.claimList)
      .map(c => c.amount)
      .reduce((a, b) => a + b, 0);
    if (sumOfClaims > 2556.46) {
      // 5000DM
      return axios.request({
        method: 'get',
        url: `/configuration/getTrialCourt?zipCode=${person.postCode}&city=${person.city}&type=Landgericht`,
      });
    }
  }

  const rentClaim = Object.values(data.claimList).find(c => 'catNr' in c && (c.catNr === '19' || c.catNr === '20')) as ICatalogClaim;
  if (rentClaim) {
    return axios.request({
      method: 'get',
      url: `/configuration/getTrialCourt?zipCode=${rentClaim.additionalDetails.postCode}&city=${rentClaim.additionalDetails.city}&type=Amtsgericht`,
    });
  }

  const catNr90 = Object.values(data.claimList).find(c => 'catNr' in c && c.catNr === '90') as ICatalogClaim;
  if (catNr90) {
    return axios.request({
      method: 'get',
      url: `/configuration/getTrialCourt?zipCode=${catNr90.additionalDetails.postCode}&city=${catNr90.additionalDetails.city}&type=Amtsgericht`,
    });
  }

  const catNr38 = Object.values(data.claimList).find(c => 'catNr' in c && c.catNr === '38') as ICatalogClaim;
  if (catNr38) {
    return axios.request({
      method: 'get',
      url: `/configuration/getTrialCourt?zipCode=${Object.values(data.persons[GetApplicantOfType(data.type)])[0].postCode}&city=${Object.values(data.persons[GetApplicantOfType(data.type)])[0].city}&type=Amtsgericht`,
    });
  }

  const catNr95 = Object.values(data.claimList).find(c => 'catNr' in c && c.catNr === '95') as ICatalogClaim;
  if (catNr95) {
    return axios.request({
      method: 'get',
      url: `/configuration/getTrialCourt?zipCode=${Object.values(data.persons[GetApplicantOfType(data.type)])[0].postCode}&city=${Object.values(data.persons[GetApplicantOfType(data.type)])[0].city}&type=Sozialgericht`,
    });
  }

  return axios.request({
    method: 'get',
    url: `/configuration/getTrialCourt?zipCode=${person.postCode}&city=${person.city}&type=Amtsgericht`,
  });
};

export interface IStateHotline {
  states: string[];
  hotlineNumber: string;
}

export const lkzToStates = new Map<string, IStateHotline>([
  ['01', { states: ['sh'], hotlineNumber: '04621 815-0 Sprechzeit: Mo.-Fr. 9:00-12:00 Uhr' }],
  ['02', { states: ['hh', 'mv'], hotlineNumber: '040 42811-1462, -1580, -1658 u. -3568. Sprechzeit: Mo.-Fr. 9:00-' }],
  ['03', { states: ['ni'], hotlineNumber: '0581 8851-0' }],
  ['04', { states: ['hb'], hotlineNumber: '0421 361-6115 (09.00 - 12.30 Uhr)' }],
  ['05', { states: ['nw'], hotlineNumber: '0211 837-1940' }],
  ['06', { states: ['he'], hotlineNumber: '06652 600-01' }],
  ['07', { states: ['rp', 'sl'], hotlineNumber: '02651 403-0' }],
  ['08', { states: ['bw'], hotlineNumber: '0711 921-3567' }],
  ['09', { states: ['by'], hotlineNumber: '09561 878-5' }],
  ['11', { states: ['be', 'bb'], hotlineNumber: '030 90156-0' }],
  ['23', { states: ['st', 'sn', 'th'], hotlineNumber: '03925 876-0' }],
]);

export const stateToLKZ = new Map<string, string>([
  ['sh', '01'],
  ['hh', '02'],
  ['mv', '02'],
  ['ni', '03'],
  ['hb', '04'],
  ['nw', '05'],
  ['he', '06'],
  ['rp', '07'],
  ['sl', '07'],
  ['bw', '08'],
  ['by', '09'],
  ['be', '11'],
  ['bb', '11'],
  ['st', '23'],
  ['sn', '23'],
  ['th', '23'],
]);

export const lkzToStateMap = new Map<string, string>([
  ['01', 'sh'],
  ['02', 'hh'],
  ['03', 'ni'],
  ['04', 'hb'],
  ['05', 'nw'],
  ['06', 'he'],
  ['07', 'rp'],
  ['08', 'bw'],
  ['09', 'by'],
  ['10', 'sl'],
  ['11', 'be'],
  ['20', 'bb'],
  ['21', 'mv'],
  ['22', 'sn'],
  ['23', 'st'],
  ['24', 'th'],
]);

export const getDunningCourtDescriptionData = () => {
  const url = '/configuration/getDunningCourtDescriptionData';
  return axios.request({
    method: 'get',
    url,
  });
};

export const C_OptiMahn = {
  C_Bayern: 'BAY',
  C_Berlin: 'BER',
  C_Brandenburg: 'BRB',
  C_Baden_Wuerttemberg: 'BW',
  C_Bremen: 'HB',
  C_Hessen: 'HES',
  C_Hamburg: 'HH',
  C_Mecklenburg_Vorpommern: 'MBV',
  C_Niedersachsen: 'NDS',
  C_Nordrhein_Westfalen: 'NRW',
  C_Nordrhein_Westfalen_2: 'NRW_2',
  C_Rheinland_Pfalz: 'RPF',
  C_Sachsen: 'SAC',
  C_Sachsen_Anhalt: 'SAH',
  C_Schleswig_Holstein: 'SH',
  C_Saarland: 'SLD',
  C_Thueringen: 'THU',
};

export const stringsZN = [
  'FILIALE',
  'FIL',
  'NIEDERLASSUNG',
  'NIEDERL',
  'ZWEIGNIEDERLASSUNG',
  'ZWEIGNIEDERL',
  'ZWEIGSTELLE',
  'ZWEIGST',
  'ZWGST',
];
export const akadGradeStrings = ['Prof. Dr.', 'Dr.', 'Prof.', 'Mag.', 'Dipl.-Ing.', 'Dipl.-Vw.', 'Dipl.-Inf.'];
export const gueltigeZeichen = " 0123456789enristdhaulcgmobzwfkvüäpöjyqxßENRISTDHAULCGMOBZWFKVÜÄPÖJYQX.,&-+/:=';*()§@€!\u1E9E";
export const ungueltigeZeichenVorname = '!%&$/()+=?*#_~@<>,;:^|{}[]';
export const ungueltigeStringsVorname = [' und ', ' u.', '-und', '-u.', 'eheleut', 'familie', ' oder '];
export const alleVokale = ['a', 'e', 'i', 'o', 'u', 'y', 'ä', 'ö', 'ü'];
export const ungueltigeZeichenNachname = '!%&$/()+=?*#_~@<>;:^|{}[]';
export const ungueltigeStringsNachname = [' und ', ' u.', '-und', '-u.', 'eheleut', 'familie', ' oder '];
export const mussStringsEinzelfirma = [
  'eingetragener Kaufm',
  'eingetragene Kauff',
  ' eK',
  'eK ',
  'e.K',
  'e.Kfm',
  'e.Kffr',
  'e.Kfr',
  'e. K',
  'e. Kfm',
  'e. Kffr',
  'e. Kfr',
];
export const optionsArray = ['Rechtsanwalt', 'Rechtsanwälte', 'Rechtsbeistand', 'Rechtsanwältin', 'Rechtsanwältinnen'];
export const ungueltigeZeichenNamePV = '%$=*#~^';
export const ungueltigeStringsNamePV = ['unbekannt', 'unbek.', 'nicht bekannt'];
export const ungueltigeZeichenNameGV = '%§$=*#~^°';
export const ungueltigeStringsNameGV = [' und ', ' u.', '-und', '-u.', 'ßß', 'unbekannt', 'unbek.', 'nicht bekannt', ' oder '];
export const tabEURKostRAEG2021 = [
  [2000, 500, 500, 39, 49],
  [10000, 2000, 1000, 56, 166],
  [25000, 10000, 3000, 52, 614],
  [50000, 25000, 5000, 81, 874],
  [200000, 50000, 15000, 94, 1279],
  [500000, 200000, 30000, 132, 2219],
  [0, 500000, 50000, 165, 3539],
];

export const tabEUR = [
  [1500, 300, 300, 20, 25],
  [5000, 1500, 500, 28, 105],
  [10000, 5000, 1000, 37, 301],
  [25000, 10000, 3000, 40, 486],
  [50000, 25000, 5000, 72, 686],
  [200000, 50000, 15000, 77, 1046],
  [500000, 200000, 30000, 118, 1816],
  [0, 500000, 50000, 150, 2996],
];

export const tabEUR2KostRMoG = [
  [2000, 500, 500, 35, 45],
  [10000, 2000, 1000, 51, 150],
  [25000, 10000, 3000, 46, 558],
  [50000, 25000, 5000, 75, 788],
  [200000, 50000, 15000, 85, 1163],
  [500000, 200000, 30000, 120, 2013],
  [0, 500000, 50000, 150, 3213],
];

export const bisWert = 0;
export const vonWert = 1;
export const erhWert = 2;
export const erhGebuehr = 3;
export const kumGebuehr = 4;
export const mindestEURKostRAEG = 15.0;
export const mindestEUR = 10.0;
export const mindestEUR2KostRMoG = 15.0;

export const basisZinsData: { [key: string]: number } = {
  '01.07.2024': 3.37,
  '01.01.2024': 3.62,
  '01.07.2023': 3.12,
  '01.01.2023': 1.62,
  '01.07.2022': -0.88,
  '01.01.2022': -0.88,
  '01.07.2021': -0.88,
  '01.01.2021': -0.88,
  '01.07.2020': -0.88,
  '01.01.2020': -0.88,
  '01.07.2019': -0.88,
  '01.01.2019': -0.88,
  '01.07.2018': -0.88,
  '01.01.2018': -0.88,
  '01.07.2017': -0.88,
  '01.01.2017': -0.88,
  '01.07.2016': -0.88,
  '01.01.2016': -0.83,
  '01.07.2015': -0.83,
  '01.01.2015': -0.83,
  '01.07.2014': -0.73,
  '01.01.2014': -0.63,
  '01.07.2013': -0.38,
  '01.01.2013': -0.13,
  '01.07.2012': 0.12,
  '01.01.2012': 0.12,
  '01.07.2011': 0.37,
  '01.01.2011': 0.12,
  '01.07.2010': 0.12,
  '01.01.2010': 0.12,
  '01.07.2009': 0.12,
  '01.01.2009': 1.62,
  '01.07.2008': 3.19,
  '01.01.2008': 3.32,
  '01.07.2007': 3.19,
  '01.01.2007': 2.7,
  '01.07.2006': 1.95,
  '01.01.2006': 1.37,
  '01.07.2005': 1.17,
  '01.01.2005': 1.21,
  '01.07.2004': 1.13,
  '01.01.2004': 1.14,
  '01.07.2003': 1.22,
  '01.01.2003': 1.97,
  '01.07.2002': 2.47,
  '01.01.2002': 2.57,
  '01.09.2001': 3.62,
  '01.09.2000': 4.26,
  '01.05.2000': 3.42,
  '01.01.2000': 2.68,
  '01.05.1999': 1.95,
  '01.01.1999': 2.5,
  '19.04.1996': 2.5,
  '15.12.1995': 3.0,
  '25.08.1995': 3.5,
  '31.03.1995': 4.0,
  '13.05.1994': 4.5,
  '15.04.1994': 5.0,
  '18.02.1994': 5.25,
  '22.10.1993': 5.75,
  '10.09.1993': 6.25,
  '02.07.1993': 6.75,
  '23.04.1993': 7.25,
  '19.03.1993': 7.5,
  '05.02.1993': 8.0,
  '15.09.1992': 8.25,
  '17.07.1992': 8.75,
  '20.12.1991': 8.0,
  '16.08.1991': 7.5,
  '01.02.1991': 6.5,
  '06.10.1989': 6.0,
  '30.06.1989': 5.0,
  '21.04.1989': 4.5,
  '20.01.1989': 4.0,
  '26.08.1988': 3.5,
  '01.07.1988': 3.0,
  '04.12.1987': 2.5,
  '23.01.1987': 3.0,
  '07.03.1986': 3.5,
  '16.08.1985': 4.0,
  '29.06.1984': 4.5,
  '18.03.1983': 4.0,
  '03.12.1982': 5.0,
  '22.10.1982': 6.0,
  '27.08.1982': 7.0,
  '02.05.1980': 7.5,
  '29.02.1980': 7.0,
  '01.11.1979': 6.0,
  '13.07.1979': 5.0,
  '30.03.1979': 4.0,
  '16.12.1977': 3.0,
  '12.09.1975': 3.5,
  '15.08.1975': 4.0,
  '23.05.1975': 4.5,
  '07.03.1975': 5.0,
  '07.02.1975': 5.5,
  '20.12.1974': 6.0,
  '25.10.1974': 6.5,
  '01.06.1973': 7.0,
  '04.05.1973': 6.0,
  '12.01.1973': 5.0,
  '01.12.1972': 4.5,
  '03.11.1972': 4.0,
  '09.10.1972': 3.5,
  '25.02.1972': 3.0,
  '23.12.1971': 4.0,
  '14.10.1971': 4.5,
  '01.04.1971': 5.0,
  '03.12.1970': 6.0,
  '18.11.1970': 6.5,
  '16.07.1970': 7.0,
  '09.03.1970': 7.5,
  '11.09.1969': 6.0,
  '20.06.1969': 5.0,
  '18.04.1969': 4.0,
  '12.05.1967': 3.0,
  '14.04.1967': 3.5,
  '17.02.1967': 4.0,
  '06.01.1967': 4.5,
  '27.05.1966': 5.0,
  '13.08.1965': 4.0,
  '22.01.1965': 3.5,
  '05.05.1961': 3.0,
  '20.01.1961': 3.5,
  '11.11.1960': 4.0,
  '03.06.1960': 5.0,
  '23.10.1959': 4.0,
  '04.09.1959': 3.0,
  '10.01.1959': 2.75,
  '27.06.1958': 3.0,
  '17.01.1958': 3.5,
  '19.09.1957': 4.0,
  '11.01.1957': 4.5,
  '06.09.1956': 5.0,
  '19.05.1956': 5.5,
  '08.03.1956': 4.5,
  '04.08.1955': 3.5,
  '20.05.1954': 3.0,
  '11.06.1953': 3.5,
  '08.01.1953': 4.0,
  '21.08.1952': 4.5,
  '29.05.1952': 5.0,
  '27.10.1950': 6.0,
  '14.07.1949': 4.0,
  '27.05.1949': 4.5,
  '01.07.1948': 5.0,
};
