import React from 'react';

const Anwaelte2018 = () => {
  const scrollToFootnotes = () => {
    const footnoteElement = document.getElementById("note1");
    if (footnoteElement) {
      footnoteElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <html>
      <head>
        <title>Anwaelte2018</title>
      </head>
      <body lang="DE">
        <div style={{ margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', fontSize: '12pt', lineHeight: '1.5' }}>
          <div>
            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                fontWeight: 'bold',
                letterSpacing: '0.6pt',
              }}
            >
              Ministerium der Justiz und für Europa Baden-Württemberg
            </p>

            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                fontWeight: 'bold',
                letterSpacing: '0.6pt',
              }}
            >
              - Koordinierungsstelle für das automatisierte Mahnverfahren -
            </p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>Stuttgart, 19. Oktober 2016</p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                fontWeight: 'bold',
                letterSpacing: '0.6pt',
              }}
            >
              Rechtsanwälte können auch nach dem 1.1.2018 Barcode-Anträge auf Erlass eines Mahnbescheids stellen und das Portal
              www.online-mahnantrag.de dauerhaft nutzen.
            </p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                textAlign: 'justify',
                letterSpacing: '0.6pt',
              }}
            >
              In der Septemberausgabe des Berliner Anwaltsblatts, Heft Nr. 9/2016, wird im Artikel „BEA auf der Zielgeraden - Alles, was man
              wissen sollte“ (vgl. S. 317) mitgeteilt, dass Mahnanträge ab 1.1.2018 ausschließlich elektronisch eingereicht werden könnten
              und die Variante des Barcode-Mahnverfahrens mit Versand auf dem Postweg ab dann nicht mehr möglich sei.
            </p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                textAlign: 'justify',
                fontWeight: 'bold',
                letterSpacing: '0.6pt',
              }}
            >
              Diese Aussage ist so nicht richtig. Die Auswirkungen des Gesetzes zur Förderung des elektronischen Rechtsverkehrs mit den
              Gerichten sollen nachstehend erläutert werden.
            </p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                textAlign: 'justify',
                letterSpacing: '0.6pt',
              }}
            >
              Anwälte dürfen im Mahnverfahren erst dann kein Papier mehr versenden, wenn das Bundesland, in dem das angerufene Mahngericht
              seinen Sitz hat, den verpflichtenden elektronischen Rechtsverkehr für Rechtsanwälte eingeführt hat
              <a
                onClick={scrollToFootnotes}
                style={{
                  fontSize: '8pt'
                }}
              >
                <sup style={{
                  fontSize: '8pt',
                }}>[1]</sup>.
              </a>
            
            </p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p
              style={{
                marginBottom: '0cm',
                lineHeight: '150%',
                textAlign: 'justify',
                letterSpacing: '0.6pt',
              }}
            >
              Rechtsanwälte und registrierte Inkassodienstleiser sind zwar bereits seit dem 1.12.2008 verpflichtet, Anträge auf Erlass von
              Mahnbescheiden in maschinell lesbarer Form einzureichen, § 690 Abs. 3 S. 2 ZPO, seit 1.1.2018: § 702 Abs. 2 ZPO (so genannte
              „Nutzungsverpflichtung“).
              <b style={{ fontWeight: 'bold' }}>
                Ob ein Antrag maschinell lesbar ist, hat indes mit der im genannten Artikel im Wesentlichen behandelten Frage des
                Übermittlungswegs nichts zu tun.
              </b>
            </p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>&nbsp;</p>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>
              Es müssen daher zwei Fragen getrennt voneinander betrachtet werden:
            </p>

            <ol
              style={{
                margin: '0',
                paddingLeft: '20px',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              <li style={{ marginBottom: '10px', letterSpacing: '0.6pt' }}>In welchem Format muss der Antrag erstellt werden?</li>
              <li style={{ marginBottom: '10px', letterSpacing: '0.6pt' }}>Wie wird der Antrag bei Gericht angebracht?</li>
            </ol>

            <p style={{ marginBottom: '0cm', lineHeight: '150%', letterSpacing: '0.6pt' }}>
              Zu 1: Es gibt zwei Möglichkeiten, einen Antrag in maschinell lesbarer Form zu erstellen:
            </p>

            <ol
              type="a"
              style={{
                margin: '0',
                paddingLeft: '20px',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              <li style={{ marginBottom: '10px', letterSpacing: '0.6pt' }}>
                Es muss ein Datensatz erstellt werden, der den EDA-Konditionen der Mahngerichte entspricht.
              </li>
              <li style={{ marginBottom: '10px', letterSpacing: '0.6pt' }}>Es muss ein Barcode-Antrag erstellt werden.</li>
            </ol>
          </div>
          <div>
      <p
        style={{
          marginBottom: "0cm",
          textAlign: "justify",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        Dazu kann eine geeignete Kanzleisoftware verwendet werden. Aber auch das
        Portal www.online-mahnantrag.de
        stellt beide Möglichkeiten zur Verfügung. Insbesondere ist dort schon
        jetzt eine Variante verfügbar, die es dem Nutzer erlaubt, einen
        Datensatz (oben <span style={{ fontStyle: "italic" }}>lit.a</span>) zu
        erzeugen, den der Nutzer herunterladen kann. Das Portal
        www.online-mahnantrag ist mit dieser Variante für den Anwalt dauerhaft
        nutzbar, auch über den 1.1.2020 bzw. den 1.1.2022 hinaus.
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        &nbsp;
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        <strong>Zu 2:</strong>
      </p>

      <p
        style={{
          marginBottom: "0cm",
          textAlign: "justify",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        Der Datensatz, gleich ob mit Kanzleisoftware erstellt oder unter{" "}
        www.online-mahnantrag.de
        erstellt und heruntergeladen, muss - bereits jetzt - elektronisch an das
        Mahngericht übermittelt werden. Dazu steht derzeit das elektronische
        Gerichts- und Verwaltungspostfach EGVP zur Verfügung. Die Anbringung
        einer qualifizierten elektronischen Signatur ist für eine wirksame
        Einreichung erforderlich.
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        &nbsp;
      </p>

      <p
        style={{
          marginBottom: "0cm",
          textAlign: "justify",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        Künftig sind neben dem „klassischen“ EGVP weitere Übermittlungswege
        eröffnet, wie das besondere elektronische Anwaltspostfach, DE-Mail usw.
        Zu beachten ist dabei lediglich, dass diese weiteren Übermittlungswege
        erst ab dem 1.1.2018 als sog. sichere Übermittlungswege gelten und damit
        erst ab dem 1.1.2018 signaturfrei genutzt werden können.
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        &nbsp;
      </p>

      <p
        style={{
          marginBottom: "0cm",
          textAlign: "justify",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        Der Barcode-Antrag muss auf Papier übermittelt werden. Er darf nicht
        heruntergeladen und elektronisch übermittelt werden. Er ist für Anwälte
        daher längstens bis zu Beginn des verpflichtenden elektronischen
        Rechtsverkehrs nutzbar.
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        &nbsp;
      </p>

      <p
        style={{
          marginBottom: "0cm",
          textAlign: "justify",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        <strong>Fazit:</strong>
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        &nbsp;
      </p>

      <p
        style={{
          marginBottom: "0cm",
          textAlign: "justify",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        Anwälte können <strong>Barcode-Anträge über den 1.1.2018 hinaus</strong>{" "}
        so lange auf Papier drucken und beim Mahngericht einreichen, bis bei dem
        angerufenen Mahngericht der verpflichtende elektronische Rechtsverkehr
        für Anwälte startet (1.1.2020 oder 1.1.2022).
      </p>

      <p style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "150%" }}>
        &nbsp;
      </p>

      <p
        style={{
          marginBottom: "0cm",
          lineHeight: "150%",
          letterSpacing: ".6pt",
        }}
      >
        <strong>
          Das Portal www.online-mahnantrag.de
          können Anwälte dauerhaft nutzen
        </strong>
        . Ist beim angerufenen Mahngericht der verpflichtende elektronische
        Rechtsverkehr für Anwälte eingeführt, ist jedoch die Variante
        „Download“ zu wählen. Den Übermittlungsweg kann der Anwalt danach frei
        und passend zu seiner Kanzleiorganisation wählen.
      </p>

      <hr />

      <p
      id="note1"
        style={{
          fontSize: "11pt",
          lineHeight: "115%",
          fontFamily: "Arial, sans-serif",
          letterSpacing: ".6pt",
        }}
      >
      <sup style={{
                  fontSize: '8pt',
                }}>[1]</sup>
        Dies ist nach dem Gesetz zur Förderung des elektronischen
        Rechtsverkehrs mit den Gerichten vom 10.10.2013 frühestens ab dem
        1.1.2020 bzw. spätestens ab dem 1.1.2022, keineswegs aber ab dem
        1.1.2018 der Fall.
      </p>
    </div>
        </div>
      </body>
    </html>
  );
};

export default Anwaelte2018;
