import React, { SetStateAction, useState } from 'react';
import { LocationDetailsLabel } from '../label/locationDetailsLabel';
import { Col, Row } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated/validatedInput';
import GenericInputValidation from 'app/shared/validations/genericInputValidation';
import ZipcodeCityValidation from 'app/shared/validations/ZipcodeCityValidation';
import { RegisteredValidationMap, ValidationProps } from 'app/shared/components/validated/validatedComponent';
import SpecialCharactersValidation from 'app/shared/validations/specialCharactersValidation';

interface LocationDetailsFieldProps {
  onChangePostCode?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCity?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCountry?: React.ChangeEventHandler<HTMLInputElement>;
  onBlurPostCode?: React.FocusEventHandler<HTMLInputElement>;
  onBlurCity?: React.FocusEventHandler<HTMLInputElement>;
  onBlurCountry?: React.FocusEventHandler<HTMLInputElement>;
  postCodeValue?: string;
  cityValue?: string;
  foreignCountryValue?: string;
  isOptional?: boolean;
  externalErrors?: string[];
  registeredValidations: RegisteredValidationMap;
  customValidation?: ((props: ValidationProps) => Promise<string[]>)[];
  setLKZ: (LKZ: string) => void;
  validationKeyPostCode?: string;
  validationKeyCity?: string;
  validationKeyForeignCountry?: string;
  applicant?: string;
  isForeignCountryShown?: boolean;
}

export const LocationDetailsField = ({
  isForeignCountryShown = true,
  ...prop
}: LocationDetailsFieldProps) => {
  const [zipcodeCityErrors, setZipcodeCityErrors] = useState([...(prop.externalErrors || [])]);

  const executeValidateZipcodeCity = async () => {
    return await validateZipcodeCity(undefined);
  };

  const validateZipcodeCity = async e => {
    // If address is in foreign country or it isn't fully entered yet
    if ((prop.foreignCountryValue && prop.foreignCountryValue !== '') || prop.cityValue === '' || prop.postCodeValue === '') {
      setZipcodeCityErrors([...(prop.externalErrors || [])]);
      return [...(prop.externalErrors || [])];
    }

    const errors = [...(prop.externalErrors || [])];
    if (prop.applicant === 'newApplicantRep') {
      // Ignore the validation because we need to show sidecontainer with descriptive messages
      return [];
    }

    const [newErrors, LKZ] = await ZipcodeCityValidation.validate(prop.postCodeValue, prop.cityValue);
    errors.push(...newErrors);
    setZipcodeCityErrors(errors);
    prop.setLKZ(LKZ);
    return errors;
  };
  const internalBlurCity = e => {
    prop.onBlurCity?.apply(this, [e]);
    validateZipcodeCity.apply(this, [e]);
  };

  const internalBlurZipcode = e => {
    prop.onBlurPostCode?.apply(this, [e]);
    validateZipcodeCity.apply(this, [e]);
  };

  const internalBlurCountry = e => {
    prop.onBlurCountry?.apply(this, e);
    validateZipcodeCity.apply(this, e);
  };

  return (
    <Row>
      <Col className="label">
        <LocationDetailsLabel isForeignCountryShown ={isForeignCountryShown} ></LocationDetailsLabel>
      </Col>
      <Col className="col-12 col-xs-2" style={{ padding: 0, paddingLeft: '0.75rem' }}>
        <ValidatedInput
          onChange={e => {
            const newValue = e.target.value.replace(/\D/g, '');
            if (prop.onChangePostCode) {
              e.target.value = newValue;
              prop.onChangePostCode(e);
            }
          }}
          id="postCode"
          className="post-code"
          name="postCode"
          type="text"
          maxLength={5}
          aria-labelledby="label-attornyOfRecord.postcode-city"
          value={prop.postCodeValue}
          onBlur={internalBlurZipcode}
          validations={[GenericInputValidation]}
          customValidations={
            prop.externalErrors !== undefined && prop.externalErrors.length > 0 ? prop.customValidation : [executeValidateZipcodeCity]
          }
          isOptional={prop.isOptional}
          externalErrors={zipcodeCityErrors.length > 0 ? zipcodeCityErrors : prop.externalErrors}
          key={'pc' + zipcodeCityErrors.length}
          validationKey={
            prop.validationKeyPostCode !== undefined ? prop.validationKeyPostCode : prop.cityValue + '_' + prop.foreignCountryValue
          }
          registeredValidations={prop.registeredValidations}
        />
      </Col>
      <Col className="col-12 col-xs-3 p-0">
        <ValidatedInput
          onChange={prop.onChangeCity}
          onBlur={internalBlurCity}
          id="city"
          className="city"
          name="city"
          type="text"
          aria-labelledby="label-attornyOfRecord.postcode-city"
          value={prop.cityValue}
          validations={[GenericInputValidation]}
          customValidations={
            prop.externalErrors !== undefined && prop.externalErrors.length > 0 ? prop.customValidation : [executeValidateZipcodeCity]
          }
          isOptional={prop.isOptional}
          externalErrors={zipcodeCityErrors.length > 0 ? zipcodeCityErrors : prop.externalErrors}
          key={'city' + zipcodeCityErrors.length}
          validationKey={
            prop.validationKeyCity !== undefined ? prop.validationKeyCity : prop.postCodeValue + '_' + prop.foreignCountryValue
          }
          registeredValidations={prop.registeredValidations}
        />
      </Col>
      {isForeignCountryShown === true && (
        <Col className="col-12 col-xs-1" style={{ padding: 0, paddingRight: '0.75rem' }}>
          <ValidatedInput
            onChange={prop.onChangeCountry}
            onBlur={internalBlurCountry}
            id="foreignCountry"
            className="foreign-country"
            name="ausl-kz"
            type="text"
            aria-labelledby="label-attornyOfRecord.foreign-country"
            value={prop.foreignCountryValue}
            validationKey={
              prop.validationKeyForeignCountry !== undefined ? prop.validationKeyForeignCountry : prop.postCodeValue + '_' + prop.cityValue
            }
            validations={[SpecialCharactersValidation]}
            registeredValidations={prop.registeredValidations}
          />
        </Col>
      )}
    </Row>
  );
};

export default LocationDetailsField;
