import React from 'react';
import { SideContainerContent } from './utils';

const MESSAGE_STYLES = {
  ERROR: { color: 'red', prefix: 'FEHLER' },
  WARNING: { color: '#996300', prefix: 'WARNUNG' },
  HINT: { color: '#996300', prefix: 'HINWEIS' },
  INFO: { color: '#996300', prefix: 'INFO' },
  ALL: { color: '#996300', prefix: 'WARNUNG' },
};

interface MessageProps extends SideContainerContent {
  type: keyof typeof MESSAGE_STYLES;
}

const SideContainerMessage: React.FC<MessageProps> = ({ messages, type }) => {
  const { color, prefix } = MESSAGE_STYLES[type];

  const displayMessage = (message: string) => {
    if (type === 'ALL') {
      return (
        <>
          {prefix}: {message}
        </>
      );
    } else {
      return `${prefix}: ${message}`;
    }
  };

  return (
    <div id={`${type.toLowerCase()}-side-container`} role={type.toLowerCase()}>
      <p>
        {messages.map((message, index) => (
          <span key={index} style={{ color }}>
            {displayMessage(message)}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
};

export default SideContainerMessage;
