import { ValidationProps } from 'app/shared/components/validated/validatedComponent';
import { ValidatedInputProps } from 'app/shared/components/validated/validatedInput';
import { DropDownBoxProps } from 'app/shared/components/validated/drop-down-box/validatedDropdown';

export abstract class Validation {
  // eslint-disable-next-line @typescript-eslint/require-await
  static async validate(props: ValidationProps): Promise<string[]> {
    return [];
  }
}

export abstract class DropdownValidation {
  // eslint-disable-next-line @typescript-eslint/require-await
  static async validate(props: ValidationProps): Promise<string[]> {
    return [];
  }
}

export function registerValidations(props: ValidatedInputProps | DropDownBoxProps, errors: string[], setErrors: React.Dispatch<string[]>) {
  const name = props.name || props.id;
  if (
    Object.keys(props.registeredValidations).includes(name) &&
    props.registeredValidations[name].value === props.value &&
    props.registeredValidations[name].isOptional === props.isOptional &&
    props.registeredValidations[name].validationKey === props.validationKey
  ) {
    return;
  }

  const maxLength = 'maxLength' in props ? props.maxLength : undefined;

  props.registeredValidations[name] = {
    name,
    externalErrors: props.externalErrors,
    maxLength,
    value: props.value,
    validations: props.validations,
    customValidations: props.customValidations,
    isOptional: props.isOptional,
    validationKey: props.validationKey,
    errors,
    setErrors,
  };
}
