// import './inputField.scss';

import React, { SetStateAction } from 'react';
import { Translate, translate } from 'react-jhipster';
import { Col, Row, Label, FormText, InputProps } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated/validatedInput';
import { Validation } from 'app/shared/validations/validation';
import { RegisteredValidation, RegisteredValidationMap } from 'app/shared/components/validated/validatedComponent';

interface InputFieldProps {
  labelContentKey?: string;
  type: InputProps['type'];
  descriptionLabelContentKey?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | ReadonlyArray<string> | number;
  name?: string;
  id?: string;
  checked?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  isSpacingBeforeCheckBoxInputRequired?: boolean;
  validations: (typeof Validation)[];
  isOptional?: boolean;
  readOnly?: boolean;
  externalErrors?: string[];
  applyClassNameStylesForInputField?: boolean;
  registeredValidations: RegisteredValidationMap;
  maxLength?: number;
  customValidations?: (() => Promise<string[]>)[];
  validationKey?: string;
}

export const InputField: React.FC<InputFieldProps> = (prop: InputFieldProps) => {
  function getColumnLayout() {
    if (prop.type === 'checkbox' || prop.type === 'radio') {
      return (
        <div>
          <Row>
            {prop.isSpacingBeforeCheckBoxInputRequired === true || prop.isSpacingBeforeCheckBoxInputRequired === undefined ? (
              <Col md="3" lg={1} xl={3}></Col>
            ) : null}

            <Col
              className={
                prop.applyClassNameStylesForInputField === true || prop.applyClassNameStylesForInputField === undefined
                  ? 'col-12 col-xs-10 offset-xs-2'
                  : 'col-12'
              }
              style={{ display: 'inline-flex' }}
            >
              <ValidatedInput
                onChange={prop.onChange}
                onBlur={prop.onBlur}
                value={prop.value}
                id={prop.id}
                validationKey={prop.validationKey}
                name={prop.name || translate(prop.labelContentKey)}
                checked={prop.checked}
                type={prop.type}
                aria-labelledby={'label-' + prop.labelContentKey}
                validations={prop.validations}
                isOptional={prop.isOptional}
                externalErrors={prop.externalErrors}
                key={prop.id + prop.externalErrors?.length}
                readOnly={prop.readOnly || false}
                registeredValidations={prop.registeredValidations}
                customValidations={prop.customValidations}
              />
              <Label htmlFor={prop.id} id={'label-' + prop.labelContentKey}>
                {translate(prop.labelContentKey)}
              </Label>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <Row className="mb-2">
          <Col className="label">
            <Label id={'label-' + prop.labelContentKey}>
              <Translate contentKey={prop.labelContentKey}>Label before input.</Translate>
            </Label>
          </Col>
          <Col className="contentCol">
            <ValidatedInput
              value={prop.value}
              id={prop.id}
              validationKey={prop.validationKey}
              // className={prop.className}
              onBlur={prop.onBlur}
              maxLength={prop.maxLength}
              onChange={prop.onChange}
              name={prop.name || translate(prop.labelContentKey)}
              type={prop.type}
              aria-labelledby={'label-' + prop.labelContentKey}
              validations={prop.validations}
              isOptional={prop.isOptional}
              externalErrors={prop.externalErrors}
              key={prop.id + prop.externalErrors?.length}
              customValidations={prop.customValidations}
              registeredValidations={prop.registeredValidations}
            />
          </Col>
          {prop.descriptionLabelContentKey != null && (
            <Col className="col-12 col-xs-3">
              <FormText className="description-label">
                <Translate contentKey={prop.descriptionLabelContentKey}>Label description after the input box.</Translate>
              </FormText>
            </Col>
          )}
        </Row>
      );
    }
  }

  return getColumnLayout();
};

export default InputField;
