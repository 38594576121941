import '../generic-help.css';
import React from 'react';
import HelpNavigationMenu from '../helpNavigationMenu/helpNavigationMenu';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from "app/shared/reducers/requeststore.interface";

const Verbraucherverband = () => {
  const type = useAppSelector(state => state.requestStore.type);
  return (
    <html>
      <head>
        <title>Erfassen eines Prozeßbevollmächtigten</title>
      </head>
      <body className="generic-help verbraucherverband">
        <HelpNavigationMenu />

        <h2>Erfassen eines Prozessbevollmächtigten (Verbraucherverband)</h2>

        <p className="Note">
          <u>Hinweis:</u> Hier dürfen Sie nur Eingaben machen, wenn es sich bei Ihrem Unternehmen um eine Verbraucherzentrale oder einen
          anderen mit öffentlichen Mitteln geförderten Verbraucherverband handelt und es sich in diesem Fall um die Einziehung von
          Forderungen von Verbrauchern im Rahmen Ihres Aufgabenbereichs handelt (§ 79 Abs. 2 Nr. 3 der Zivilprozessordnung).
        </p>

        {type !== RequestTypes.RedeliveryEnforcement && type !== RequestTypes.Objection && (
          <p>
            Falls der Antragsteller <strong>nicht</strong> zum Vorsteuerabzug berechtigt ist, markieren Sie bitte das zugehörige Kästchen.
          </p>
        )}

        <p>
          In das Feld <span className="quotable">Kennziffer</span> geben Sie, falls vorhanden, die Ihnen vom Gericht zugewiesene
          Prozessvertreterkennziffer ein (8-stellig). Eine Kennziffer ist eine vom zuständigen Mahngericht auf Antrag vergebene Nummer,
          unter der die Daten des Prozessbevollmächtigten bei Gericht hinterlegt sind. Die Nutzung einer Kennziffer wird bei häufiger
          Antragstellung empfohlen. Das Online-Mahnverfahren kann aber auch ohne eine Kennziffer durchgeführt werden.<p></p>
          Die Kennziffer ist grundsätzlich bundesweit bei den Mahngerichten verwendbar.<div></div>
          Lediglich das Mahngericht des Landes Niedersachsen (Amtsgericht Uelzen) akzeptiert keine Kennziffern anderer Bundesländer.
        </p>

        <p>Sofern Ihnen keine Kennziffer zugewiesen wurde, geben Sie in den folgenden Feldern Ihre Daten ein:</p>

        <p>
          Im Feld <span className="quotable">Rechtsform</span> wählen Sie die zutreffende Rechtsform aus.{' '}
        </p>

        <p>
          Im Feld <span className="quotable">vollst. Bezeichnung</span> geben Sie Ihre komplette Bezeichnung ein.{' '}
        </p>

        <p>
          Im Feld <span className="quotable">Straße, Hausnummer</span> geben Sie Ihre Adresse ein.
        </p>

        <p>
          Geben Sie die Postleitzahl und den Ort ein. Das Auslandskennzeichen ist nur anzugeben, wenn der Sitz nicht in Deutschland ist.
          Befindet sich der Wohnsitz in Deutschland, so soll kein Auslandskennzeichen angegeben werden.
        </p>

        <p>
          Haben Sie alle Eingaben getätigt, so bestätigen Sie diese Angaben mit Klick auf die{' '}
          <span className="nobr">
            <input className={`${type} button-thumbnail`} value=">" type="submit" />
            -Schaltfläche
          </span>
          .
        </p>

        <HelpNavigationMenu />
      </body>
    </html>
  );
};
export default Verbraucherverband;
