import React, { SetStateAction, useState } from 'react';
import Select, { components, PropsValue } from 'react-select';
import { IDunningDropDown } from './dunningDropdownStyle';
import {
  generateDropdownChange,
  getErrorsForTooltip,
  RegisteredValidationMap,
  registerValidationComponent,
  SelectActionMeta,
  SelectNewValue,
  ValidatedDropdownBaseProps,
} from 'app/shared/components/validated/validatedComponent';
import { Tooltip } from 'react-tooltip';
import { translate, Translate } from 'react-jhipster';
import { DropdownValidation } from 'app/shared/validations/validation';

export type DropDownBoxProps = ValidatedDropdownBaseProps & {
  labelledby: string;
  options: string[];
  defaultValue?: PropsValue<{ label: string; value: string }>;
  onChange?: (newValue: SelectNewValue, actionMeta: SelectActionMeta) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: string;
  id?: string;
  name?: string;
  isMapOptionsProvided?: boolean;
  style?: IDunningDropDown;
  validations: (typeof DropdownValidation)[];
  registeredValidations: RegisteredValidationMap;
};

const ValidatedDropdown: React.FC<DropDownBoxProps> = (prop: DropDownBoxProps) => {
  let options = [];
  if (prop.isMapOptionsProvided === true) {
    options = prop.options;
  } else {
    prop.options.forEach(function (element) {
      options.push({ id: prop.id, label: element, value: element, name: prop.name + '_' + element });
    });
  }

  function getBorderStyle(state): string {
    if (state.isFocused) {
      return 'dropdownControlFocused';
    } else if (errors.length > 0) {
      return 'error'; // validation-failed style
    } else {
      return 'dropdownControl';
    }
  }

  const [errors, setErrors] = useState([...(prop.externalErrors || [])]);

  registerValidationComponent(prop, errors, setErrors);

  const name = prop.name && prop !== null && prop.name.length > 0 ? prop.name : prop.labelledby;

  return (
    <div>
      <Select
        placeholder={<Translate contentKey="generic.dropdown-placeholder" />}
        classNames={{
          menu: state => 'dropdownMenu',
          control: state => getBorderStyle(state),
          dropdownIndicator: state => 'dropdownIndicator',
          indicatorSeparator: state => 'dropdownIndicatorSeparator',
          option: state => 'dropdownOption',
        }}
        className="drop-down-box"
        defaultValue={prop.defaultValue}
        value={prop.value ? { label: prop.value, value: prop.value } : null}
        id={prop.id}
        components={{ Input: props => <components.Input {...props} name={(prop.name || '') + '_input'} /> }}
        name={name}
        aria-labelledby={prop.labelledby}
        onChange={generateDropdownChange(prop, prop.onChange, errors, setErrors)}
        onBlur={prop.onBlur}
        options={options}
        defaultInputValue=""
        key={prop.id + errors.length}
        isDisabled={prop.disabled}
      />
      {errors.length !== 0 && (
        <Tooltip anchorSelect={`#${prop.id}`} place="top">
          {getErrorsForTooltip(errors)}
        </Tooltip>
      )}
    </div>
  );
};

export default ValidatedDropdown;
