import { ValidationProps } from 'app/shared/components/validated/validatedComponent';
import { Validation } from './validation';
import { validateStr } from './dunningDataValidation';

export class AddressValidation extends Validation {
  // eslint-disable-next-line @typescript-eslint/require-await
  static async validate(props: ValidationProps): Promise<string[]> {
    const errors = [];

    if (props.newValue !== '' && !props.isOptional) {
      validateStr(errors, props.newValue);
    }

    return errors;
  }
}
export default AddressValidation;
