import React, { useEffect, useState } from 'react';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-duotone-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';
import { Button, Col, Row, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { RegisteredValidationMap, runValidations, ValidationProps } from 'app/shared/components/validated/validatedComponent';
import {
  ERROR_TEMPLATES,
  filterMessagesByType,
  HINT_TEMPLATES,
  INFO_TEMPLATES,
  WARNING_TEMPLATES,
} from 'app/shared/layout/side-container/utils';
import { setWarningsAcknowledged } from 'app/shared/layout/side-container/side-container-slice';
import { useDispatch } from 'react-redux';

export interface IDunningNavigation {
  handleOnBackClick?: () => void;
  handleOnNextClick?: () => void;
  nameSuffix?: string;
  hideBack?: boolean;
  hideNext?: boolean;
  registeredValidations: RegisteredValidationMap;
}

const DunningNavigation = (props: IDunningNavigation) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultBack = () => {
    navigate(-1);
  };
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);
  const handleBackClick = () => {
    dispatch(setWarningsAcknowledged(false));
    setWarningAcknowledged(false);
    if (props.handleOnBackClick) {
      props.handleOnBackClick();
    } else {
      defaultBack();
    }
  };

  useEffect(() => {
    setWarningAcknowledged(false);
  }, [JSON.stringify(props.registeredValidations)]);

  const nextFunction = async () => {
    const foundErrors = [];
    for (const registered of Object.values(props.registeredValidations)) {
      const { value, validations, customValidations, errors, setErrors } = registered;
      const validationProps: ValidationProps = {
        name: registered.name,
        newValue: value,
        validations,
        customValidations,
        errors,
        setErrors,
        isOptional: registered.isOptional,
        maxLength: registered.maxLength,
      };

      foundErrors.push(...(await runValidations(validationProps)));
    }

    const errorMessages = filterMessagesByType(foundErrors, ERROR_TEMPLATES);
    const warningMessagesFiltered = filterMessagesByType(foundErrors, WARNING_TEMPLATES);
    const infoMessages = filterMessagesByType(foundErrors, INFO_TEMPLATES);
    const hintMessages = filterMessagesByType(foundErrors, HINT_TEMPLATES);
    const warningMessagesAndInfo = warningMessagesFiltered.concat(infoMessages);
    const warningMessages = warningMessagesAndInfo.concat(hintMessages);

    if (errorMessages.length > 0) {
      return;
    } else if (warningMessages.length > 0 && !warningAcknowledged) {
      setWarningAcknowledged(true);
      return;
    }

    props.handleOnNextClick();
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col xs={6}>
          {!props.hideBack && (
            <Button name={'zurück' + (props.nameSuffix || '')} id={'back-button' + (props.nameSuffix || '')} onClick={handleBackClick}>
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon> <Translate contentKey="generic.back" />
            </Button>
          )}
        </Col>

        <Col xs={6} className="next-button-col">
          {!props.hideNext && (
            <Button
              name={'weiter' + (props.nameSuffix || '')}
              id={'next-button' + (props.nameSuffix || '')}
              onClick={() => {
                nextFunction();
              }}
            >
              <Translate contentKey="generic.next" /> <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DunningNavigation;
