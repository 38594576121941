import './validated.scss';
import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import {
  generateBlur,
  getDomProps,
  getErrorsForTooltip,
  registerValidationComponent,
  ValidatedInputBaseProps,
} from 'app/shared/components/validated/validatedComponent';
import { NumericFormatProps } from 'react-number-format/types/types';
import { ERROR_TEMPLATES, filterMessagesByType, WARNING_TEMPLATES } from 'app/shared/layout/side-container/utils';
export type ValidatedInputProps = Input['props'] & ValidatedInputBaseProps<HTMLInputElement>;
export type ValidatedCurrencyProps = NumericFormatProps & ValidatedInputBaseProps<HTMLInputElement> & { maskOptions: object };

export const ValidatedInput = (props: ValidatedInputProps) => {
  const [errors, setErrors] = useState([...(props.externalErrors || [])]);
  const errorMessages = filterMessagesByType(errors, ERROR_TEMPLATES);
  const warningMessages = filterMessagesByType(errors, WARNING_TEMPLATES);

  let css = '';
  if (errorMessages.length !== 0) {
    css = 'validation-failed';
  } else if (warningMessages.length !== 0) {
    css = 'validation-warning';
  } else if (errors.length > 0) {
    css = 'validation-failed';
  }

  const DOMprops = getDomProps(props);
  registerValidationComponent(props, errorMessages.length > 0 ? errorMessages : warningMessages, setErrors);

  return (
    <div>
      <Input
        {...DOMprops}
        onBlur={e => {
          generateBlur<HTMLInputElement>(props, errors, setErrors)(e);
        }}
        id={props.id || props.name}
        className={css}
        key={props.id + errors.length}
        readOnly={props.readOnly}
        type={props.type}
        maxLength={props.maxLength}
        {...(props.type === 'date' ? { max: '9999-12-31' } : {})}
      />
      {errors.length !== 0 && (
        <Tooltip anchorSelect={`#${props.id || props.name}`} place="top">
          {getErrorsForTooltip(errors)}
        </Tooltip>
      )}
    </div>
  );
};

export default ValidatedInput;
//
