import React, { useEffect } from 'react';
import { translate, Translate } from 'react-jhipster';

import { NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { stateSelector } from 'app/shared/reducers/requeststore';
import { DropdownConfigKeys, fetchDropdownConfig, getDropdownConfig } from 'app/shared/reducers/configuration';

import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';

export const StateIcon = props => {
  const selectedStates = useAppSelector(stateSelector);
  const states = getDropdownConfig(DropdownConfigKeys.states);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!states || states.length === 0) dispatch(fetchDropdownConfig(DropdownConfigKeys.states));
  }, []);

  return (
    <>
      {selectedStates !== null &&
        selectedStates.map(state => (
          <div className="state-icon" key={state} style={{ marginRight: '2px' }}>
            <img
              src={`content/images/state/logo_${state}.png`}
              alt="Logo"
              title={translate('global.menu.iconTitle', { state: states.find(s => s.short === state)?.long })}
            />
          </div>
        ))}
    </>
  );
};

export const Brand = () => (
  <header>
    <NavbarBrand tag={Link} to="/" className="state-logo">
      <StateIcon />
      <span className="brand-title">
        <Translate contentKey="global.title">Online Mahnantrag</Translate>
      </span>
      <span className="navbar-version">{VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`}</span>
    </NavbarBrand>
  </header>
);

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon={faHome} />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);
