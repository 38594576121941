import React from 'react';
import './helpNavigationMenu.css';
import { useAppSelector } from 'app/config/store';
import { RequestTypes } from "app/shared/reducers/requeststore.interface";
import { back, closeWindow, home, insertBeforeItem, next, removeItemByName } from '../navigationMenuUtils';

interface IHelpNavigationMenuProps {
  hideBack?: boolean;
  hideNext?: boolean;
}

const HelpNavigationMenu = ({ hideBack, hideNext }: IHelpNavigationMenuProps) => {
  const type = useAppSelector(state => state.requestStore.type);
  let pagesArray = [
    'inhaltsverzeichnis',
    'geschaeftsnummer',
    'antragstellerart',
    'mahnantrag_gestellt',
    'rechtsbeistand',
    'rechtsbeistand/rechtsanwalt_rechtsbeistand',
    'rechtsbeistand/rechtsanwaltsgesellschaft',
    'rechtsbeistand/herr_frau',
    'rechtsbeistand/inkasso_kreditdienstleister',
    'rechtsbeistand/verbraucherverband',
    'gesetzlichevertreter',
    'bankverbindung',
    'antragsgegneranschrift',
    'antragsgegnerbezeichnung',
    'prozessgericht',
    'auslagen',
    'zustellung_vertreter',
    'neuer_vertreter',
    'allgemeine_angaben',
    'uebersicht',
    'barcode',
    'eda',
    'eid',
  ];

  if (type === RequestTypes.Enforcement) {
    pagesArray = removeItemByName(pagesArray, 'prozessgericht');
    pagesArray = insertBeforeItem(pagesArray, 'zahlugen', 'antragsgegneranschrift');
  } else if (type === RequestTypes.RedeliveryEnforcement) {
    pagesArray = removeItemByName(pagesArray, 'neuer_vertreter');
    pagesArray = removeItemByName(pagesArray, 'auslagen');
    pagesArray = removeItemByName(pagesArray, 'prozessgericht');
    pagesArray = removeItemByName(pagesArray, 'antragsgegnerbezeichnung');
  } else if (type === RequestTypes.Objection) {
    pagesArray = removeItemByName(pagesArray, 'mahnantrag_gestellt');
    pagesArray = removeItemByName(pagesArray, 'neuer_vertreter');
    pagesArray = removeItemByName(pagesArray, 'bankverbindung');
    pagesArray = removeItemByName(pagesArray, 'prozessgericht');
    pagesArray = removeItemByName(pagesArray, 'antragsgegnerbezeichnung');
    pagesArray = removeItemByName(pagesArray, 'auslagen');
    pagesArray = insertBeforeItem(pagesArray, 'umfang', 'allgemeine_angaben');
  }

  const urlPrefix = useAppSelector(state => state.requestStore.urlPrefix);

  return (
    <div className={`help-navigation-menu ${type}`}>
      {!hideBack && (
        <a className="button-menu-a" accessKey="r" onClick={() => back(pagesArray, urlPrefix)}>
          &lt;
        </a>
      )}
      &nbsp;
      <a className="button-menu-a" accessKey="i" onClick={() => home(pagesArray, urlPrefix)}>
        i
      </a>
      &nbsp;
      <a className="button-menu-a" accessKey="x" onClick={() => closeWindow()}>
        x
      </a>
      &nbsp;
      {!hideNext && (
        <a className="button-menu-a" accessKey="v" onClick={() => next(pagesArray, urlPrefix)}>
          &gt;
        </a>
      )}
      &nbsp;
    </div>
  );
};
export default HelpNavigationMenu;
