import { IPersonDetails } from 'app/shared/reducers/requeststore.interface';

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export enum UrlPrefix {
  Dunning = '/mahnbescheid',
  Redelivery_dunning = '/neuzustellung_mahnbescheid',
  Objection = '/widerspruch',
  Enforcement = '/vollstreckungsbescheid',
  Redelivery_enforcement = '/neuzustellung_vollstreckungsbescheid',
  None = '/',
}

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export enum applicantOrDefendantType {
  mrMs = 'mrMs',
  company = 'company',
  readerServiceCode = 'readerServiceCode',
  other = 'other',
}

export enum othersType {
  pka = 'pka',
  ownLawyer = 'own-lawyer',
  weg = 'weg',
  more = 'more',
  naturalPersonOrCompany = 'naturalPersonOrCompany',
  legalEntity = 'legalEntity',
  condominiumOwners = 'condominiumOwners',
  administratorSubmitsHisOwnName = 'administratorSubmitsHisOwnName',
}

export const applicantOrDefendantTabMapping = {
  [applicantOrDefendantType.mrMs]: 0,
  [applicantOrDefendantType.company]: 1,
  [applicantOrDefendantType.readerServiceCode]: 2,
  [applicantOrDefendantType.other]: 3,
};

export const applicantOrDefendantReverseTabMapping = {
  0: applicantOrDefendantType.mrMs,
  1: applicantOrDefendantType.company,
  2: applicantOrDefendantType.readerServiceCode,
  3: applicantOrDefendantType.other,
};

export const INITIAL_PERSON_DETAILS: IPersonDetails = {
  id: '',
  type: '',
  salutation: '',
  name: ['', '', '', ''],
  streetOrHouseNumber: '',
  postCode: '',
  city: '',
  foreignCountry: '',
  companyStreetOrHouseNumber: '',
  companyLegalOption: '',
  applicantCode: '',
  assets: '',
  isLawyerOnOwnBehalf: false,
  naturalPersonDesignationOption: '',
  naturalPersonDesignation: '',
  naturalPersonStreetOrHouseNumber: '',
  position: '',
  legalPersonDesignation: '',
  legalRepresentatives: {},
  tab: applicantOrDefendantType.mrMs,
  innerTab: othersType.pka,
  isNatoTroopStatus: false,
  paymentsDone: false,
  payments: [],
};
