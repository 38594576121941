import { createSlice } from '@reduxjs/toolkit';

export const sideContainerSlice = createSlice({
  name: 'Sidecontainer',
  initialState: {
    type: null,
    show: false,
    content: null,
    warningAcknowledged: false,
  },
  reducers: {
    show(state, action: { payload: { type?: string; content: any } }) {
      state.show = true;
      if (action.payload?.content) {
        state.type = action.payload.type;
        state.content = action.payload.content;
      }
    },
    hide(state, action: { payload: { removeContent: boolean } }) {
      state.show = false;

      if (action.payload?.removeContent) {
        state.content = null;
        state.type = null;
      }
    },
    setContent(state, action: { payload: { type?: string; content: any } }) {
      state.type = action.payload.type;
      state.content = action.payload.content;
    },
    removeContent(state) {
      state.content = null;
    },
    setWarningsAcknowledged(state, action: { payload: boolean }) {
      state.warningAcknowledged = action.payload;
    },
  },
});

export const contentSelector = state => state.sideContainer.content;
export const isShowingSelector = state => state.sideContainer.show;
export const typeSelector = state => state.sideContainer.type;
export const warningAcknowledged = state => state.sideContainer?.warningAcknowledged;

export const { show, hide, setContent, removeContent, setWarningsAcknowledged } = sideContainerSlice.actions;
export default sideContainerSlice.reducer;
