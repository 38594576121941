import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'app/config/store';
import { printAndSignType } from 'app/modules/generic/printAndSign/printAndSign';
import { v4 as uuidv4 } from 'uuid';

import { tabType } from 'app/modules/dunning/attorney/tabPanelLayout';
import { translate } from 'react-jhipster';
import { buildIdentificationDocument, IIAm, IIdentityCard } from './eIDIdentityCard';
import {
  CombinedClaimType,
  IAncillaryClaim,
  IApplicationGeneralInformation,
  IAttorneyOfRecord,
  IAttorneyOfRecordRenumeration,
  IBankDetails,
  ICalculatedInterest,
  ICatalogClaimDetailsOfAssignment,
  ICompensation,
  ICourtData,
  IDocumentDunning,
  IDunningCourt,
  IDunningProcedureType,
  IExpencesAncillaryClaims,
  IInitialState,
  ILegalRepresentative,
  IManualProcessCourtData,
  INatoDetails,
  IPartialObjectionData,
  IPersonDetails,
  IPersonIdMap,
  IPersonTypeMap,
  IPrintAndSign,
  IRunningInterest,
  IShippingTypeQuestions, RequestTypes, StageNames
} from "./requeststore.interface";
import { UrlPrefix } from 'app/config/constants';
import { applicantOrDefendantType, othersType } from 'app/config/constants';

export const initialExpencesAncillaryClaims: IExpencesAncillaryClaims = {
  formPostage: null,
  otherExpences: null,
  otherExpencesOption: '',
  whenOthers: '',
  expences: null,
  preTrialActivity: null,
  preJudicialActivity: false,
  proceduralFee: null,
};

export const initialDocumentDunningState: IDocumentDunning = {
  id: uuidv4(),
  type: 'IDocumentDunning',
  claimInformation: '',
  from: '',
  amount: null,
  isApplicantCreditor: false,
  to: undefined,
  terms: false,
  additionalInformation: undefined,
  runningInterest: undefined,
};

export const initialAttorneyOfRecordState: IAttorneyOfRecord = {
  type: undefined,
  function: '',
  commissionDate: '',
  expenses: undefined,
  isApplicantEntitledToDeductInputText: false,
  isLegalRepresentativeExemptFromPaymentSalesTax: false,
  readerServiceCode: '',
  descriptionOrLegalOption: '',
  name: [''],
  streetAndHouseNumber: '',
  postCode: '',
  city: '',
  lkz: '',
  foreignCountry: '',
  renumerationRvg: undefined,
  legalRepresentative: null,
  createdDunningRequest: false,
};

export const initialPersonDetails: IPersonDetails = {
  id: '',
  type: '',
  salutation: '',
  name: ['', ''],
  streetOrHouseNumber: '',
  postCode: '',
  city: '',
  foreignCountry: '',
  companyStreetOrHouseNumber: '',
  companyLegalOption: '',
  applicantCode: '',
  assets: '',
  isLawyerOnOwnBehalf: false,
  naturalPersonDesignationOption: '',
  naturalPersonDesignation: '',
  naturalPersonStreetOrHouseNumber: '',
  position: '',
  legalPersonDesignation: '',
  legalRepresentatives: {},
  tab: applicantOrDefendantType.mrMs,
  innerTab: othersType.pka,
  isNatoTroopStatus: false,
  paymentsDone: false,
  payments: [],
};

export const initialLegalRepresentative: ILegalRepresentative = {
  id: '',
  function: '',
  name: [''],
  streetOrHouseNumber: '',
  postCode: '',
  city: '',
  lkz: '',
  foreignCountry: '',
};

export const initialPrintAndSignState: IPrintAndSign = {
  type: 0,
  readInstructions: false,
  readNotesForBarcodeAndPrint: false,
  readNotesForBrowser: false,
};

export const initialApplicationGeneralInformation: IApplicationGeneralInformation = {
  reference: '',
  considerationDone: false,
  considerationNotRelated: false,
  legalProcessOnOpposition: false,
  legalAid: '',
  numberOne: '',
  numberTwo: '',
  numberThree: '',
  numberFour: '',
  redeliverByCourt: false,
  redeliverSelf: false,
  natoException: false,
  freeFromCosts: '',
};

export const initialDunningCourt: IDunningCourt = {
  name: '',
  hotlineNumber: '',
};

export const initialAttorneyOfRecordRenumeration: IAttorneyOfRecordRenumeration = {
  compensation: undefined,
  compensationValue: undefined
}

const initialState: IInitialState = {
  type: RequestTypes.Dunning,
  urlPrefix: UrlPrefix.None,
  states: null,
  currentStage: null,
  shippingType: null,
  shippingTypeQuestions: null,
  applicant: null,
  content: null,
  attorneyOfRecord: initialAttorneyOfRecordState,
  persons: {},
  currentPersons: {},
  currentPersonsHistory: {},
  dunningProcedureType: null,
  currentClaim: undefined,
  totalPrincipalReceivables: 0,
  disputeValueUntil: 0,
  claimList: {},
  courtFee: 0,
  jointDebtors: false,
  expencesAncillaryClaims: initialExpencesAncillaryClaims,
  ancillaryClaims: [],
  currentAncillaryClaim: null,
  dunningCourtData: null,
  processNumber: null,
  bankDetails: {
    iban: '',
    bic: '',
    owner: '',
  },
  compensation: null,
  partialObjectionData: null,
  printAndSign: initialPrintAndSignState,
  applicationGeneralInformation: initialApplicationGeneralInformation,
  identificationDocument: null,
  dunningCourt: initialDunningCourt,
  attorneyOfRecordRenumeration: initialAttorneyOfRecordRenumeration,
  foreignDunningPossible: false
};

export const NestedIdSeparator = '.';

export const requestStoreSlice = createSlice({
  name: 'RequestStore',
  initialState,
  reducers: {
    setType(state, action: { payload: RequestTypes }) {
      state.type = action.payload;
      switch (state.type) {
        case RequestTypes.Dunning:
          state.urlPrefix = UrlPrefix.Dunning;
          break;
        case RequestTypes.Enforcement:
          state.urlPrefix = UrlPrefix.Enforcement;
          break;
        case RequestTypes.Objection:
          state.urlPrefix = UrlPrefix.Objection;
          break;
        case RequestTypes.RedeliveryEnforcement:
          state.urlPrefix = UrlPrefix.Redelivery_enforcement;
          break;
        case RequestTypes.RedeliveryDunning:
          state.urlPrefix = UrlPrefix.Redelivery_dunning;
          break;
        default:
          state.urlPrefix = UrlPrefix.None;
          break;
      }
    },
    resetRequestStoreDataToCopiedValues(_, action) {
      return { ...initialState, ...action.payload };
    },
    setCurrentStage(state, action: { payload: StageNames }) {
      state.currentStage = action.payload;
    },
    setStates(state, action: { payload: string[] }) {
      state.states = action.payload;
    },
    setShippingType(state, action: { payload: string }) {
      state.shippingType = action.payload;
    },
    setShippingTypeQuestions(state, action: { payload: IShippingTypeQuestions }) {
      state.shippingTypeQuestions = action.payload;
    },
    setApplicant(state, action: { payload: string }) {
      state.applicant = action.payload;
    },
    setAttornyOfRecord(state, action: { payload: IAttorneyOfRecord }) {
      state.attorneyOfRecord = action.payload;
    },
    resetAttorneyOfRecord(state) {
      state.attorneyOfRecord = initialAttorneyOfRecordState;
    },
    setCurrentPersonId(state, action: { payload: { type: StageNames; id: string } }) {
      const { type, id } = action.payload;
      state.currentPersons[type] = id;

      if (!state.currentPersonsHistory[type]) {
        state.currentPersonsHistory[type] = [id]; // Initialize history with the current ID
      } else if (state.currentPersonsHistory[type][state.currentPersonsHistory[type].length - 1] !== id) {
        state.currentPersonsHistory[type].push(id); // if the latest id isn't the same as this one add ID to History
      }
    },
    navigatePersonHistory(state, action: { payload: { type: StageNames } }) {
      const { type } = action.payload;
      const history = state.currentPersonsHistory[type];
      if (history && history.length > 1) {
        history.pop(); // Remove and return the latest person ID
        state.currentPersons[type] = history[history.length - 1];
        state.currentPersonsHistory[type] = history;
      }
    },
    resetCurrentPersonOfType(state, action: { payload: string }) {
      state.currentPersons[action.payload] = undefined;
    },
    resetCurrentPerson(state) {
      // eslint-disable-next-line no-console
      state.currentPersons[state.currentStage] = undefined;
    },
    resetAllCurrentPersons(state) {
      state.currentPersons = {};
    },
    setDunningProcedureType(state, action: { payload: IDunningProcedureType }) {
      state.dunningProcedureType = action.payload;
    },
    deletePersonOfType(state, action: { payload: { type: StageNames; id: string } }) {
      if (
        action.payload.type === StageNames.DUNNING_APPLICANT_LEGAL_REP ||
        action.payload.type === StageNames.DUNNING_DEFENDANT_LEGAL_REP
      ) {
        const splitId = action.payload.id.split(NestedIdSeparator);
        delete state.persons[splitId[0]][splitId[1]].legalRepresentatives[splitId[2]];
      } else {
        delete state.persons[action.payload.type][action.payload.id];
      }
    },
    addPersonForStage(state, action: { payload: { person: IPersonDetails | ILegalRepresentative; stage: StageNames } }) {
      let refPerson = null;
      if (!state.persons[action.payload.stage]) {
        state.persons[action.payload.stage] = {};
      }

      if (!('legalRepresentatives' in action.payload.person)) {
        refPerson = state.persons[action.payload.stage][state.currentPersons[action.payload.stage]];

        refPerson.legalRepresentatives[action.payload.person.id] = action.payload.person;
        state.persons[action.payload.stage][state.currentPersons[action.payload.stage]] = refPerson as IPersonDetails;
      } else {
        state.persons[action.payload.stage][action.payload.person.id] = { ...action.payload.person, type: action.payload.stage } as any;
      }
    },
    setJointDebtors(state, action: { payload: boolean }) {
      state.jointDebtors = action.payload;
    },
    setExpencesAndAncillaryClaims(state, action: { payload: IExpencesAncillaryClaims }) {
      state.expencesAncillaryClaims = action.payload;
    },
    setCurrentClaim(state, action: { payload: CombinedClaimType }) {
      state.currentClaim = action.payload;
    },
    setTotalPrincipalReceivables(state, action) {
      state.totalPrincipalReceivables = action.payload;
    },
    setDisputeValueUntil(state, action) {
      state.disputeValueUntil = action.payload;
    },
    addCatalogClaimIntoTheList(state, action: { payload: CombinedClaimType }) {
      state.claimList[action.payload.id] = action.payload;
    },
    addOrEditAdditionalClaimInformation(
      state,
      action: { payload: { id: string; contractDate: string; effectiveAnnualInterestRate: number } },
    ) {
      state.claimList[action.payload.id].additionalInformation = {
        effectiveAnnualInterestRate: action.payload.effectiveAnnualInterestRate,
        contractDate: action.payload.contractDate,
      };
    },
    addOrEditDetailsOfAssignment(state, action: { payload: ICatalogClaimDetailsOfAssignment & { id: string } }) {
      const { id, ...paylaodData } = action.payload;
      if (!state.claimList[id].additionalInformation) {
        state.claimList[id].additionalInformation = {};
      }

      state.claimList[id].additionalInformation.detailsOfAssignment = paylaodData;
    },
    deleteCatalogClaimInformation(state, action: { payload: { id: string; removePropertiesFor: string } }) {
      if (action.payload.removePropertiesFor === 'formerCreditor') {
        delete state.claimList[action.payload.id].additionalInformation.detailsOfAssignment;
      } else if (action.payload.removePropertiesFor === 'consumerCredit') {
        delete state.claimList[action.payload.id].additionalInformation.contractDate;
        delete state.claimList[action.payload.id].additionalInformation.effectiveAnnualInterestRate;
      }
    },
    deleteClaimFromTheList(state, action: { payload: { id: string } }) {
      delete state.claimList[action.payload.id];
    },
    addRunningInterest(state, action: { payload: IRunningInterest }) {
      state.currentClaim = {
        ...state.currentClaim,
        runningInterest: {
          ...state.currentClaim.runningInterest,
          [action.payload.id]: action.payload,
        },
      };
      state.claimList[state.currentClaim.id] = state.currentClaim;
    },
    deleteRunningInterest(state, action: { payload: { claimId: string; interestId: string } }) {
      const claim = state.claimList[action.payload.claimId];
      if (!claim) return;

      delete claim.runningInterest[action.payload.interestId];

      state.claimList[claim.id] = claim;

      if (claim.id === state.currentClaim.id) state.currentClaim = claim;
    },
    setAncillaryClaims(state, action: { payload: IAncillaryClaim[] }) {
      state.ancillaryClaims = action.payload;
    },
    addAncillaryClaimIntoTheList(state, action: { payload: IAncillaryClaim }) {
      const ancillaryClaimExists = state.ancillaryClaims.find(ancillaryClaim => ancillaryClaim.id === action.payload.id);
      if (!ancillaryClaimExists) state.ancillaryClaims.push(action.payload);
    },
    editAncillaryClaim(state, action: { payload: { id: string; objectToEdit: IAncillaryClaim } }) {
      const ancillaryClaimExists = state.ancillaryClaims.find(ancillaryClaim => ancillaryClaim.id === action.payload.id);
      if (ancillaryClaimExists) {
        state.ancillaryClaims = state.ancillaryClaims.map(ancillaryClaim =>
          ancillaryClaim.id === action.payload.id ? { ...ancillaryClaim, ...action.payload.objectToEdit } : ancillaryClaim,
        );
      }
    },
    setCurrentAncillaryClaim(state, action: { payload: { id: string } }) {
      state.currentAncillaryClaim = state.ancillaryClaims.find(currentAncillaryClaim => currentAncillaryClaim.id === action.payload.id);
    },
    deleteAncillaryClaim(state, action: { payload: { id: string } }) {
      state.ancillaryClaims = state.ancillaryClaims.filter(item => item?.id !== action.payload.id);
    },
    setApplicationGeneralInformation(state, action: { payload: IApplicationGeneralInformation }) {
      state.applicationGeneralInformation = action.payload;
    },
    setProcessNumber(state, action: { payload: { number: string; isAlternative: boolean } }) {
      state.processNumber = action.payload;
    },
    setBankDetails(state, action: { payload: IBankDetails }) {
      state.bankDetails = action.payload;
    },
    setManualProcessCourt(
      state,
      action: { payload: { personId: string; personType: string; manualProcessCourtData: IManualProcessCourtData } },
    ) {
      if (action.payload === undefined) {
        return;
      }
      state.persons[action.payload.personType][action.payload.personId].manualTrialCourtData = action.payload.manualProcessCourtData;
    },
    setProcessCourtData(state, action: { payload: { personId: string; personType: string; courtData: ICourtData | undefined } }) {
      state.persons[action.payload.personType][action.payload.personId].defendantTrialCourtData = action.payload.courtData;
    },
    setDunningCourtData(state, action: { payload: ICourtData | undefined }) {
      state.dunningCourtData = action.payload;
    },
    setNatoDetails(state, action: { payload: { id: string; details: INatoDetails | null } }) {
      if (!state.persons[state.currentStage] || !state.persons[state.currentStage][action.payload.id]) return;
      state.persons[state.currentStage][action.payload.id].natoDetails = action.payload.details;
    },
    setCreatedDunningRequest(state, action: { payload: boolean }) {
      state.attorneyOfRecord.createdDunningRequest = action.payload;
    },
    setCompensation(state, action: { payload: ICompensation }) {
      state.compensation = action.payload;
    },
    setPartialObjectionData(state, action: { payload: IPartialObjectionData }) {
      state.partialObjectionData = action.payload;
    },
    setPrintAndSign(state, action: { payload: IPrintAndSign }) {
      state.printAndSign = action.payload;
    },
    setPrintAndSignType(state, action: { payload: printAndSignType }) {
      state.printAndSign.type = action.payload;
    },
    setIdentificationDocument(state, action: { payload: { identityCard: IIdentityCard; iAm: IIAm } }) {
      const { identityCard, iAm } = action.payload;
      state.identificationDocument = buildIdentificationDocument(identityCard, iAm, null);
    },
    setCalculatedInterest(state, action: { payload: ICalculatedInterest | undefined }) {
      state.calculatedInterest = action.payload;
    },
    setCourtCosts(state, action: { payload: number }) {
      state.courtFee = action.payload;
    },
    setCurrentRunningInterest(state, action: { payload: string }) {
      state.currentRunningInterest = action.payload;
    },
    overrideRequestStore(state, action: { payload: string }) {
      Object.keys(state).forEach(key => {
        delete state[key];
      });
      Object.assign(state, JSON.parse(action.payload));
    },
    setFunctionToAttorneyOfRecord(state, action: { payload: tabType }) {
      let functionToSave = undefined;
      switch (action.payload) {
        case tabType.lawyer:
          functionToSave = 'attorney';
          break;
        case tabType.lawyerCompany:
          functionToSave = 'attorney-company';
          break;
        case tabType.mrMrs:
          functionToSave = 'mr-mrs';
          break;
        case tabType.collectionAgency:
          functionToSave = 'collection-agency';
          break;
        case tabType.consumerAssociation:
          functionToSave = 'consumer-association';
          break;
        default:
          functionToSave = '';
          break;
      }
      state.attorneyOfRecord.function = functionToSave;
    },
    setDunningCourt(state, action: { payload: IDunningCourt }) {
      state.dunningCourt = action.payload;
    },
    setAttorneyOfRecordRenumeration(state, action:{payload: IAttorneyOfRecordRenumeration}){
     state.attorneyOfRecordRenumeration = action.payload;
    },
    clear(state) {
      Object.keys(state).forEach(key => {
        delete state[key];
      });
      Object.assign(state, initialState);
    },
    setForeignDunningPossible(state, action: {payload: boolean}){
      state.foreignDunningPossible = action.payload;
    }
  },
});

// Method to retrieve the applicants array
export const getPersonsMap = (state: IRootState): IPersonTypeMap => state.requestStore.persons;
export const getAttorneyOfRecord = state => state.requestStore.attorneyOfRecord;
export const getCurrentPersonIds = (state: IRootState): IPersonIdMap => state.requestStore.currentPersons;
export const getCurrentDunningProcedureType = (state: IRootState): IDunningProcedureType => state.requestStore.dunningProcedureType;
export const getExpencesAndAncillaryClaims = (state: IRootState): IExpencesAncillaryClaims => state.requestStore.expencesAncillaryClaims;
export const getTotalPrincipalReceivables = (state: IRootState): number => state.requestStore.totalPrincipalReceivables;
export const getDisputeValueUntil = (state: IRootState): number => state.requestStore.disputeValueUntil;
export const getClaimList = (state: IRootState): CombinedClaimType[] => Object.values(state.requestStore?.claimList);
export const getClaimMap = (state: IRootState): { [key: string]: CombinedClaimType } => state.requestStore.claimList;

export const typeSelector = state => state.requestStore.type;
export const stateSelector = state => state.requestStore.states;
export const getApplicant = state => state.requestStore.applicant;

export const getCurrentStage = state => state.requestStore.currentStage;
export const getDocumentDunning = (state: { requestStore: { documentDunning: IDocumentDunning } }) => state.requestStore.documentDunning;
export const getRunningInterest = (state: { requestStore: IInitialState }) => state.requestStore.currentClaim?.runningInterest;
export const getAncillaryClaims = (state: IRootState) => state.requestStore.ancillaryClaims;
export const getCurrentAncillaryClaim = (state: IRootState) => state.requestStore.currentAncillaryClaim;
export const getProcessNumber = (state: IRootState) => state.requestStore.processNumber;
export const getPrintAndSign = (state: IRootState) => state.requestStore.printAndSign;

export const getFunctionAttorneyOfRecord = (state: IRootState) => {
  // Check if attorneyOfRecord exists in the state
  if (state.requestStore.attorneyOfRecord.function === 'attorney') {
    return state.requestStore.attorneyOfRecord.descriptionOrLegalOption;
  } else {
    return translate('generic.function.' + state.requestStore.attorneyOfRecord.function);
  }
};
export const {
  setType,
  addPersonForStage,
  setStates,
  setCurrentStage,
  setShippingType,
  setShippingTypeQuestions,
  setApplicant,
  setAttornyOfRecord: setAttorneyOfRecord,
  resetAttorneyOfRecord,
  setCurrentPersonId,
  setDunningProcedureType,
  deletePersonOfType,
  resetCurrentPersonOfType,
  resetCurrentPerson,
  resetAllCurrentPersons,
  setCurrentClaim,
  addCatalogClaimIntoTheList,
  addOrEditAdditionalClaimInformation,
  deleteClaimFromTheList,
  navigatePersonHistory,
  setTotalPrincipalReceivables,
  setDisputeValueUntil,
  setExpencesAndAncillaryClaims,
  addRunningInterest,
  deleteRunningInterest,
  addOrEditDetailsOfAssignment,
  deleteCatalogClaimInformation,
  setAncillaryClaims,
  deleteAncillaryClaim,
  addAncillaryClaimIntoTheList,
  setJointDebtors,
  setCurrentAncillaryClaim,
  editAncillaryClaim,
  setApplicationGeneralInformation,
  setProcessNumber,
  setBankDetails,
  setManualProcessCourt,
  setProcessCourtData,
  setDunningCourtData,
  setNatoDetails,
  setCompensation,
  setPartialObjectionData,
  clear,
  setCreatedDunningRequest,
  setPrintAndSign,
  resetRequestStoreDataToCopiedValues,
  setPrintAndSignType,
  setIdentificationDocument,
  setCalculatedInterest,
  setFunctionToAttorneyOfRecord,
  setCourtCosts,
  setCurrentRunningInterest,
  overrideRequestStore,
  setDunningCourt,
  setAttorneyOfRecordRenumeration,
  setForeignDunningPossible
} = requestStoreSlice.actions;
export default requestStoreSlice.reducer;
